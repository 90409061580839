import React, { FC, useCallback, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Tabs, ITabsProps, Typography, useContextualHelpActions } from '@farmlink/farmik-ui';
import { useNavigate } from 'react-router';

import { useDataTestIdV2 } from '../../../../../shared/features/utils/hooks/locators';
import { useTasksBackButtonData } from '../../hooks/useTasksBackButtonData';
import { Fullscreen } from '../../../../../shared/containers';
import { useStore } from '../../../../../shared/utils/IoC';
import { useTasksParams, useTasksTaskOperationRoutes } from '../../hooks';
import { usePageContextualHelp, useWarningBeforeLeavingThePage } from '../../../../../shared/hooks';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';

import { TaskOperationCreateStore as Store } from './mobx/stores';
import { TaskOperationCreateController as Controller } from './mobx/controllers';
import { TaskOperationCreateControlButtons as ControlButtons } from './components';
import Styled from './TaskOperationCreate.styles';

const TaskOperationCreate: FC = () => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const params = useTasksParams();
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useTasksTaskOperationRoutes();

  const isCommonInfoTab = store.tabList?.[0]?.id === location.pathname;

  const backButtonData = useTasksBackButtonData();

  useWarningBeforeLeavingThePage(store.hasChanges, controller.pathListWithoutModalWarning);

  usePageContextualHelp(EContextualParentPath.TasksTaskOperationCreate);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.TasksTaskOperationCreate,
    ContextualPaths.TasksTaskOperationCreateHeaderTitle
  );

  useEffect(() => {
    if (!isCommonInfoTab) routes.goToCommonInfo();

    controller.initiatePage(params);

    return () => {
      controller.clearStore();
    };
  }, []);

  /**
   * Отвечает за блокировку/разблокировку табов.
   */
  useEffect(() => {
    if (!store.tabList.length) return;
    if (!store.taskCreate) return;

    controller.toggleTabsIsDisableState();
  }, [
    store.tabList.length,
    store.config,
    store.taskCreate?.cultureId,
    store.taskCreate?.operationId,
    store.taskCreate?.taskItems?.length,
    store.taskCreate?.operationValues?.length,
  ]);

  const handleTabChange: ITabsProps['onChangeTab'] = (selectedId): void => {
    if (isCommonInfoTab) controller.storeDefaultDate();

    navigate(selectedId);
  };

  const getDataTestId = useDataTestIdV2('tasks__task-operation-create');

  return (
    <Fullscreen
      backButtonText={
        <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
          <Typography dataTestId={getDataTestId('title')['data-test-id']} variant={'h4'}>
            Регистрация работы
          </Typography>

          {ContextualHelpIcon}
        </Styled.TitleWrapper>
      }
      backButtonHandler={backButtonData.handler}
      arrowText={backButtonData.arrowText}
    >
      <Tabs
        dataTestId={getDataTestId('tabs')['data-test-id']}
        stylePreset="secondary"
        content={store.tabList}
        selectedTabId={location.pathname}
        onChangeTab={handleTabChange}
        size="small"
      />

      <Outlet />

      <ControlButtons dataTestId={getDataTestId('control-buttons')['data-test-id']} />
    </Fullscreen>
  );
};

TaskOperationCreate.displayName = 'TaskOperationCreate';

export default observer(TaskOperationCreate);
