import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { WarningBeforeLeavingModalTitle as Title } from './components';

const config: TModalConfig = {
  id: 'warningBeforeLeavingThePage',
  name: 'warningBeforeLeavingThePage',
  title: Title(),
  type: EModalType.Warning_Before_Living,
  dataTestId: 'warning-before-leaving-the-page',
  styledProps: {
    $size: EModalSize.Medium,
    $height: '370px',
    $modalPaddings: '45px 60px 55px',
  },
  denyButton: {
    title: 'Остаться',
    dataTestId: 'warning-before-leaving-the-page-deny-button',
  },
  successButton: {
    title: 'Покинуть экран',
    dataTestId: 'warning-before-leaving-the-page-success-button',
  },
};

const createWarningBeforeLeavingThePage = (
  successHandler: () => void,
  denyHandler?: () => void
): { key: string; name: string; configList: TModalConfig[] } => {
  return {
    key: config.name,
    name: config.name,
    configList: [
      {
        ...config,
        denyButton: { ...config.denyButton, handler: denyHandler && denyHandler },
        successButton: { ...config.successButton, handler: successHandler, color: 'secondary' },
      },
    ],
  };
};

export default createWarningBeforeLeavingThePage;
