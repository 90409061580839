import { FC } from 'react';

import { TablePlugBuilder } from '../../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';
import { TasksFiltersActionButton } from '../../../../TasksFilters/containers/header';

const TaskListNoCreatedTasksPlug: FC = () => {
  const getDataTestId = useDataTestIdV2('tasks__no-created-tasks-plug');

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'file' },
        content: {
          title: 'Создайте новую задачу',
          description: 'Планируйте работы и следите за ходом их выполнения',
        },
        footer: {
          renderButton: () => <TasksFiltersActionButton width={'240px'} />,
        },
      }}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

TaskListNoCreatedTasksPlug.displayName = 'TaskListNoCreatedTasksPlug';

export default TaskListNoCreatedTasksPlug;
