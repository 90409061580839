import { FC, ReactElement, useMemo } from 'react';
import { AutoSizer, ListRowProps } from 'react-virtualized';
import { ButtonLink, VirtualizedList } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import arrSize from '../../../../../../../../../../../../../shared/utils/arraySize';
import { TCultureOption } from '../../../../../../../../../../utils';

import Styled from './OptionsList.styles';

interface IProps {
  list: Array<TCultureOption>;
  expanded: boolean;
  listItem: {
    render: (x: any) => ReactElement;
    height: number;
  };
  onToggleExpand: () => void;
  onSelect: (item: TCultureOption) => void;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const OptionsList: FC<IProps> = props => {
  const showExpandBtn = props.list.length > 6;

  const list = useMemo(() => {
    if (!props.expanded) {
      return props.list.slice(0, 6);
    }

    return props.list;
  }, [props.list, props.expanded]);

  const getListHeight = () => {
    const itemsCount = arrSize(props.list, props.expanded ? 12 : 6);
    return props.listItem.height * itemsCount;
  };

  const rowRender = ({ index, key, style }: ListRowProps) => {
    const option = list[index];

    return (
      <Styled.ListItem
        key={key}
        style={style}
        disabled={option.disabled}
        onClick={() => props.onSelect(option)}
        {...props.getDataTestId('list-item')}
      >
        {props.listItem.render(option)}
      </Styled.ListItem>
    );
  };

  return (
    <Styled.Wrapper>
      <div style={{ height: getListHeight() }}>
        <AutoSizer>
          {size => (
            <VirtualizedList
              width={size.width + 12} // + 12px чтобы визуально вынести скролл за пределы контейнера
              height={size.height}
              rowCount={list.length}
              rowHeight={props.listItem.height}
              rowRenderer={rowRender}
              overscanRowCount={3}
              withPadding
            />
          )}
        </AutoSizer>
      </div>

      {showExpandBtn && (
        <ButtonLink color={'secondary'} onClick={props.onToggleExpand}>
          {props.expanded ? 'Свернуть' : 'Показать всё'}
        </ButtonLink>
      )}
    </Styled.Wrapper>
  );
};

export default OptionsList;
