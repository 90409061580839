import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { TasksFilters } from '../TasksFilters';
import { TasksList } from '../TasksList';
import { EContextualParentPath } from '../../../../../shared/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../shared/hooks';
import { useTaskDataConsistency, useTasksParams } from '../../hooks';
import { useStore } from '../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { FieldsApiService } from '../../../../../shared/mobx/services/as-fields';
import { OperationsStore } from '../../../operations/stores/operations.store';
import { ListingPage } from '../../../../../shared/features/UI/ListingPage';
import { TasksListingPageSkeleton as PageSkeleton } from '../TasksListingPageSkeleton';

const Tasks: FC = () => {
  const operationsStore = useStore(OperationsStore);
  const seasonsStore = useStore(SeasonsStore);

  const fieldsService = useStore(FieldsApiService);

  const params = useTasksParams();

  useEffect(() => {
    if (!seasonsStore.selectedSeason) return;

    void fieldsService.fetchFieldsList();
    void operationsStore.fetchCulturesList();
    void operationsStore.fetchOperationTypeDictionaryByCultureId('', true);
  }, [seasonsStore.selectedSeason, params.orgId]);

  usePageContextualHelp(EContextualParentPath.Tasks);
  const { isTaskDataConsistency } = useTaskDataConsistency();

  if (!isTaskDataConsistency) {
    return <PageSkeleton />;
  }

  return <ListingPage id={'tasks'} filters={<TasksFilters />} list={<TasksList />}></ListingPage>;
};

Tasks.displayName = 'Tasks';

export default observer(Tasks);
