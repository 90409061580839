import { Ellipsis } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const TaskTitleWrapper = styled.div`
  & > span {
    display: flex;
    flex-direction: row;
    margin-right: 12px;
  }
  margin-right: 12px;
`;

const TaskTitlePaddingWrapper = styled.div`
  padding-left: 22px;
`;

const TaskTitleEllipsis = styled(Ellipsis)`
  width: 100%;
  max-width: 570px;
`;

const TaskHeaderPostfixContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
    height: 28px;
`;

const Styled = {
  TaskTitleWrapper,
  TaskTitlePaddingWrapper,
  TaskTitleEllipsis,
  TaskHeaderPostfixContent,
};

export default Styled;
