import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { AutoTooltip, useShowTooltip, useSidebar } from '@farmlink/farmik-ui';
import { useLocation } from 'react-router-dom';

import { TableElementDrawerBuilder } from '../../../../../shared/components/TableElementDrawerBuilder';
import {
  ETaskStatus,
  ETaskType,
  ITask,
} from '../../../../../../api/models/as-fields/task/task.model';
import { useStore } from '../../../../../shared/utils/IoC';
import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId as IInstance,
} from '../../../../../../api/models/checklist/instance/checklist.instance.model';
import { ChecklistsService } from '../../../../../shared/mobx/services/as-fields/ChecklistsService';
import { useTasksRouteActions } from '../../hooks';
import { TaskStatusPill } from '../../components/TaskStatusPill';
import { TaskController } from '../../mobx/controllers';
import { ITaskEvent } from '../../../../../../api/models/task/events/task.event.mode';
import { ETasksContextualReturnBackButtonText } from '../../constants';
import { TTasksContextualLinkConfig } from '../../types';
import { TableBuilderStore } from '../../../../../shared/features/TableBuilder/mobx/stores';
import { TasksStore as OldTasksStore } from '../../../operations/stores/tasks.store';
import { useDataTestIdV2 } from '../../../../../shared/features/utils/hooks/locators';
import TaskOperationViewStore from '../../modules/TaskOperationView/mobx/stores/TaskOperationViewStore/TaskOperationView.store';

import Styled from './TasksDrawer.styles';
import { ReactComponent as CloseSvg } from './assets/close.svg';
import {
  TasksDrawerTaskMonitoring as TaskMonitoring,
  TasksDrawerTaskOperation as TaskOperation,
} from './components';

interface IProps {
  fromModule: 'operations' | 'tasks';
  task: ITask;
  onClose: () => void;
  /**
   * Отключает подсветку просмотренной задачи после возвращения в раздел списка задач.
   */
  isDoNotNeedToSaveTaskHighlightingAfterReturn?: boolean;
}

const TasksDrawer: FC<IProps> = ({
  fromModule,
  task,
  onClose,
  isDoNotNeedToSaveTaskHighlightingAfterReturn,
}) => {
  const checklistsService = useStore(ChecklistsService);

  const tableBuilderStore = useStore(TableBuilderStore);
  const oldTasksStore = useStore(OldTasksStore);
  const taskOperationViewStore = useStore(TaskOperationViewStore);

  const taskController = useStore(TaskController);

  const getDataTestId = useDataTestIdV2(`${fromModule}__tasks-drawer`);

  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>(
    task.operationInfo.operationTypeInfo.name
  );

  const [instanceList, setInstanceList] = useState<IInstance[]>([]);
  const [eventList, setEventList] = useState<ITaskEvent[]>([]);

  const sidebarActions = useSidebar();
  const {
    getTasksPath,
    goToTask,
    goToMap,
    goToChecklist,
    goToTaskOperation,
  } = useTasksRouteActions();

  const location = useLocation();

  useEffect(() => {
    (async () => {
      sidebarActions.setInBackground(true);

      const events = await taskController.fetchTaskEventList(task.id);

      setEventList(events);

      const response = await checklistsService.getChecklistInstanceList({
        taskId: task.id,
        size: 2000,
        types: [
          ECheckListInstanceType.ActualPoint,
          ECheckListInstanceType.PlannedPoint,
          ECheckListInstanceType.Machinery,
          ECheckListInstanceType.Field,
        ],
      });

      if (response?.content) {
        setInstanceList(response.content);
      }
    })();

    return () => {
      sidebarActions.setInBackground(false);
    };
  }, []);

  const handleDetailsClick = () => {
    const {
      id,
      status,
      checklistsAvailable,
      checklistsMachineryAvailable,
      checklistsFieldAvailable,
      type,
    } = task;

    const contextualReturn: TTasksContextualLinkConfig['from'] = {
      backButtonLabel:
        fromModule === 'tasks'
          ? ETasksContextualReturnBackButtonText.TasksListing
          : ETasksContextualReturnBackButtonText.OperationsListing,
      url: fromModule === 'tasks' ? getTasksPath() : `${location.pathname}${location.search}`,
    };

    if (fromModule === 'tasks' && !isDoNotNeedToSaveTaskHighlightingAfterReturn) {
      const currentPagination = tableBuilderStore.getCurrentPage('tasks');

      contextualReturn.state = {
        taskId: id,
        page: currentPagination,
      };
    }

    if (fromModule === 'operations') {
      contextualReturn.state = {
        taskId: id,
        page: oldTasksStore.pageNumber,
      };
    }

    if (type === ETaskType.Operation) {
      goToTaskOperation(task.id, null, contextualReturn);
    } else if (status === ETaskStatus.InWork) {
      goToMap(task.id, contextualReturn);
    } else if (status === ETaskStatus.Completed) {
      if (!checklistsAvailable && !checklistsMachineryAvailable && !checklistsFieldAvailable) {
        goToTask(task.id, null, contextualReturn);
      }

      if (instanceList?.length) {
        goToChecklist(instanceList[0].id, task.id, contextualReturn);
      } else {
        goToMap(task.id, contextualReturn);
      }
    } else {
      goToTask(task.id, null, contextualReturn);
    }

    onClose();
  };

  return (
    <TableElementDrawerBuilder onClose={onClose}>
      <Styled.TasksDrawer {...getDataTestId()}>
        <Styled.FixedTitle {...getDataTestId('fixed-title')}>
          <Styled.Header {...getDataTestId('header')}>
            <Styled.OperationInfoContent {...getDataTestId('operation-info-content')}>
              <AutoTooltip
                disabled={!isShowTooltip}
                content={task.operationInfo.operationTypeInfo.name}
                position="bottom-right"
                $translate={{ x: '-203px' }}
                dataTestId={getDataTestId('tooltip')['data-test-id']}
              >
                <Styled.OperationTypeName
                  variant={'h4'}
                  ref={ref}
                  ellipsis={true}
                  noWrap={true}
                  dataTestId={getDataTestId('operation-type-name')['data-test-id']}
                >
                  {task.operationInfo.operationTypeInfo.name}
                </Styled.OperationTypeName>
              </AutoTooltip>

              <TaskStatusPill
                status={task?.status}
                eventList={eventList}
              />
            </Styled.OperationInfoContent>

            <Styled.SvgContent>
              <CloseSvg {...getDataTestId('close-task-view-form')} onClick={onClose} />
            </Styled.SvgContent>
          </Styled.Header>
        </Styled.FixedTitle>

        {task?.type === ETaskType.Operation ? (
          <TaskOperation
            dataTestId={getDataTestId()['data-test-id']}
            task={task}
            instanceList={instanceList}
          />
        ) : (
          <TaskMonitoring
            dataTestId={getDataTestId()['data-test-id']}
            task={task}
            instanceList={instanceList}
          />
        )}

        <Styled.FixedFooter {...getDataTestId('fixed-footer')}>
          <Styled.Footer {...getDataTestId('footer')}>
            <Styled.DetailsButton {...getDataTestId('details-button')} onClick={handleDetailsClick}>
              Подробнее
            </Styled.DetailsButton>
          </Styled.Footer>
        </Styled.FixedFooter>
      </Styled.TasksDrawer>
    </TableElementDrawerBuilder>
  );
};

TasksDrawer.displayName = 'TasksDrawer';

export default observer(TasksDrawer);
