import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';
import { IRequestStatusActions } from '../../../../../../api/models/common/request';
import { IUpdateStoDictionaryRequest } from '../../../../../../api/endpoints/as-fields/sto/updateStoDictionary';

@provide.singleton()
class StoService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getStoList = async (
    payload: TypeApiRequest<'getStoList'>,
    config?: {
      query?: Pick<TypeApiRequest<'getStoList'>, 'page' | 'size' | 'sort'>;
      actions?: IRequestStatusActions<TypeApiResponse<'getStoList'>>;
    }
  ): Promise<TypeApiResponse<'getStoList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoList(payload, {
        omit: ['size', 'page'],
        query: config?.query,
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getSto = async (
    payload: TypeApiRequest<'getSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getSto'>>;
    }
  ): Promise<TypeApiResponse<'getSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getSto(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw e;
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getTotalSTO = async (
    payload: TypeApiRequest<'getTotalSTO'>,
    config?: {
      query?: Record<string, any>;
      actions?: IRequestStatusActions<TypeApiResponse<'getTotalSTO'>>;
    }
  ): Promise<TypeApiResponse<'getTotalSTO'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getTotalSTO(payload, { query: config?.query });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw e;
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  createSto = async (
    payload: TypeApiRequest<'createSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'createSto'>>;
    }
  ): Promise<TypeApiResponse<'createSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.createSto(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  updateSto = async (
    payload: TypeApiRequest<'updateSto'>,
    config?: {
      query?: Pick<TypeApiRequest<'updateSto'>, 'id'>;
      actions?: IRequestStatusActions<TypeApiResponse<'updateSto'>>;
    }
  ): Promise<TypeApiResponse<'updateSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.updateSto(payload, {
        query: config?.query,
        omit: ['id'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw e;
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  fetchPotentialCultureZoneList = async (
    payload: TypeApiRequest<'getPotentialCZList'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getPotentialCZList'>>;
    }
  ): Promise<TypeApiResponse<'getPotentialCZList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getPotentialCZList(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getChecklistsByTechOperationType = async (
    payload: TypeApiRequest<'getChecklistsByTechOperationType'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getChecklistsByTechOperationType'>>;
    }
  ): Promise<TypeApiResponse<'getChecklistsByTechOperationType'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getChecklistsByTechOperationType(payload, {
        omit: ['id'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getStoChecklistsParamsTable = async (
    payload: TypeApiRequest<'getStoChecklistsParamsTable'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoChecklistsParamsTable'>>;
    }
  ): Promise<TypeApiResponse<'getStoChecklistsParamsTable'>> => {
    const { getStoChecklistsParamsTable } = this.axiosService.api;

    try {
      config?.actions?.handleLoading?.(true);

      const response = await getStoChecklistsParamsTable(payload, {
        omit: ['id', 'operationTypeId'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getStoAttribute = async (
    payload: TypeApiRequest<'getStoAttribute'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoAttribute'>>;
    }
  ): Promise<TypeApiResponse<'getStoAttribute'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoAttribute(payload, {
        omit: ['id', 'checklistId', 'techOperationTypeId', 'stageId', 'checklistAttributeId'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getDependencyAttributeList = async (
    payload: TypeApiRequest<'getStoDependencyAttributeList'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoDependencyAttributeList'>>;
    }
  ): Promise<TypeApiResponse<'getStoDependencyAttributeList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoDependencyAttributeList(payload, {
        omit: ['id', 'checklistId', 'techOperationTypeId', 'stageId', 'checklistAttributeId'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  saveStoChecklistsAttrsTables = async (
    payload: TypeApiRequest<'saveStoChecklistsAttrsTable'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'saveStoChecklistsAttrsTable'>>;
    }
  ): Promise<TypeApiResponse<'saveStoChecklistsAttrsTable'>> => {
    const { saveStoChecklistsAttrsTable } = this.axiosService.api;

    try {
      config?.actions?.handleLoading?.(true);

      const response = await saveStoChecklistsAttrsTable(payload, {
        omit: ['id', 'operationTypeId'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  getStoVersionHistory = async (
    payload: TypeApiRequest<'getStoVersionHistory'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoVersionHistory'>>;
    }
  ): Promise<TypeApiResponse<'getStoVersionHistory'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoVersionHistory(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  publishSto = async (
    payload: TypeApiRequest<'publishSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'publishSto'>>;
    }
  ): Promise<TypeApiResponse<'publishSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.publishSto(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  discardDraft = async (
    payload: TypeApiRequest<'stoDiscardDraft'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'stoDiscardDraft'>>;
    }
  ): Promise<TypeApiResponse<'stoDiscardDraft'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.stoDiscardDraft(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  activateSto = async (
    payload: TypeApiRequest<'activateSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'activateSto'>>;
    }
  ): Promise<TypeApiResponse<'activateSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.activateSto(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  deactivateSto = async (
    payload: TypeApiRequest<'deactivateSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'deactivateSto'>>;
    }
  ): Promise<TypeApiResponse<'deactivateSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.deactivateSto(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public getStoReport = async (
    payload: TypeApiRequest<'getStoReport'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoReport'>>;
    }
  ): Promise<TypeApiResponse<'getStoReport'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoReport(payload, {
        omit: ['id'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public getStoReportBySeason = async (
    payload: TypeApiRequest<'getStoReportBySeason'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoReportBySeason'>>;
    }
  ): Promise<TypeApiResponse<'getStoReportBySeason'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoReportBySeason(payload, {
        omit: ['seasonYear'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public addCultureZoneToSto = async (
    payload: TypeApiRequest<'addCultureZoneToSto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'addCultureZoneToSto'>>;
    }
  ): Promise<TypeApiResponse<'addCultureZoneToSto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.addCultureZoneToSto(payload, {
        omit: ['stoId', 'cultureZoneId'],
        responseType: 'blob',
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public getStoDictionaryList = async (
    payload: TypeApiRequest<'getStoDictionaryList'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoDictionaryList'>>;
    }
  ): Promise<TypeApiResponse<'getStoDictionaryList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getStoDictionaryList(payload, {
        omit: ['stoId', 'dictionaryName'],
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public updateStoDictionary = async (
    payload: IUpdateStoDictionaryRequest,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'updateStoDictionary'>>;
    }
  ): Promise<TypeApiResponse<'updateStoDictionary'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.updateStoDictionary(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public downloadStoDictionary = async (
    payload: TypeApiRequest<'downloadStoDictionary'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'downloadStoDictionary'>>;
    }
  ): Promise<TypeApiResponse<'downloadStoDictionary'>> => {
    const queryDictionaryName = payload?.dictionaryName?.join?.('&dictionaryName=');

    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.downloadStoDictionary(payload, {
        omit: ['stoId', 'dictionaryName'],
        query: {
          dictionaryName: queryDictionaryName,
        },
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public uploadDictionary = async (
    payload: TypeApiRequest<'uploadStoDictionary'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'uploadStoDictionary'>>;
    }
  ): Promise<TypeApiResponse<'uploadStoDictionary'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.uploadStoDictionary(payload, {
        omit: ['stoId', 'source'],
        formData: payload.source,
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  public getDictionaryEntityList = async (
    payload: TypeApiRequest<'getStoDictionaryEntityList'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getStoDictionaryEntityList'>>;
    }
  ): Promise<TypeApiResponse<'getStoDictionaryEntityList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const query: Pick<TypeApiRequest<'getStoDictionaryEntityList'>, 'sort' | 'size'> = {};

      if (payload.sort) query.sort = payload.sort;
      if (payload.size) query.size = payload.size;

      const response = await this.axiosService.api.getStoDictionaryEntityList(payload, {
        omit: ['sort', 'size'],
        query,
      });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };
}

export default StoService;
