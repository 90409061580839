/**
 * Возвращает размер массива до указанного макс. значения
 * @example
 *  arr = [1,2,3,4,5]
 *  arrSize(arr, 2) - "2"
 *  arrSize(arr, 100) - "5"
 */
const arrSize = <T>(arr: Array<T>, max: number) => (arr.length > max ? max : arr.length);

export default arrSize;
