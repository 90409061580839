import { useModal, VirtualizedList } from '@farmlink/farmik-ui';
import { AutoSizer, ListRowProps } from 'react-virtualized';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import LeftPanelHeader from '../../../FieldsView/components/LeftPanel/components/Header/Header';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import FieldsListSkeleton from '../../../../components/ListSkeleton/ListSkeleton';
import { EFieldsMultiselectModalName } from '../../modals/config';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { MultiselectStore as Store } from '../../mobx';
import { useFieldsRoutes } from '../../../../hooks';

import SelectAllCheckbox from './components/SelectAllCheckbox/SelectAllCheckbox';
import FieldCard from './components/FieldCard/FieldCard';
import Footer from './components/Footer/Footer';
import Styled from './LeftPanel.styles';

const LeftPanel: FC = () => {
  // Навигация
  const navigate = useNavigate();
  const fieldsRoutes = useFieldsRoutes();

  // Сторы
  const store = useStore(Store);

  const modal = useModal();
  const getDataTestId = useDataTestIdV2('fields-multiselect');

  const list = store.filterManager?.filteredList ?? [];

  const rowRender = ({ index, key, style }: ListRowProps) => {
    return <FieldCard key={key} field={list[index]} style={style} />;
  };

  const handleHeaderBackClick = () => {
    if (store.changedFields || store.deletedFields || store.selectedFieldsCount > 0) {
      const modalId = EFieldsMultiselectModalName.backWithoutSave;

      modal.openModalByModalId(modalId, null, () => {
        store.filterManager.filters.restoreSnapshot(true);
        navigate(fieldsRoutes.fieldsViewRoute);
      });

      return;
    }

    navigate(fieldsRoutes.fieldsViewRoute);
  };

  return (
    <Styled.Wrapper>
      <LeftPanelHeader search backButton onBackClick={handleHeaderBackClick} />

      <FieldsListSkeleton length={20} isLoading={store.isInitialize} getDataTestId={getDataTestId}>
        <SelectAllCheckbox />

        <Styled.ListContainer>
          <AutoSizer>
            {size => (
              <VirtualizedList
                {...size}
                rowCount={list.length}
                rowHeight={60}
                rowRenderer={rowRender}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </Styled.ListContainer>
      </FieldsListSkeleton>

      <Footer />
    </Styled.Wrapper>
  );
};

export default observer(LeftPanel);
