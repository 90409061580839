import * as turf from '@turf/turf';
import type { Geometry, Position, MultiPolygon } from '@turf/turf';
import L from 'leaflet';
import { Feature, Polygon } from 'geojson';

/**
 * Преобразовывает {@link Geometry геометрию} или {@link L.Polygon Leaflet полигон} в turf полигон.
 * Вернет пустой полигон в случае если передана невалидная геометрия
 */
function toTurfPolygon(polygonOrGeometry: L.Polygon | Geometry): Feature<Polygon | MultiPolygon> {
  const geometry = extractGeometryFromArg(polygonOrGeometry);

  try {
    if (!geometry) {
      return turf.polygon([[]]);
    }

    if (geometry.type === 'MultiPolygon') {
      return turf.multiPolygon(geometry.coordinates as Position[][][]);
    }

    return turf.polygon(geometry.coordinates as Position[][]);
  } catch {
    console.warn(`Получена невалидная геометрия полигона: ${geometry}`);

    return turf.polygon([[]]);
  }
}

function extractGeometryFromArg(arg: L.Polygon | Geometry) {
  if (arg instanceof L.Polygon) {
    return arg.toGeoJSON(false).geometry;
  }

  return arg;
}

export default toTurfPolygon;
