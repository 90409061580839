import moment from 'moment';
import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { CalendarComponent } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import {
  ETaskAction,
  ETaskStatus,
} from '../../../../../../../../../../api/models/as-fields/task/task.model';
import { useTaskActionControl } from '../../../../../../hooks';

const PlanStartDate: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const controls = useTaskActionControl();

  const isCreate = taskStore.isCreateOne || taskStore.isCreateMultiple;
  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditCommonInfoDates);
  const canEdit = isCreate || (taskStore.isEditMode && hasPermissionToEdit);

  const hasEmptyOperationId = isCreate ? !taskStore?.taskCreate?.operationId : false;
  const isBlocked =
    taskStore.isViewMode ||
    !canEdit ||
    hasEmptyOperationId ||
    taskStore?.selectedTask?.status === ETaskStatus.InWork;

  const todayDate = moment().format('DD.MM.YYYY');

  const handleChange = useCallback((planStartDate: string): void => {
    taskController.changePlanStartDate(planStartDate);
  }, []);

  return (
    <CalendarComponent
      label={'Плановое начало'}
      defaultValue={taskController.planStartDateDefaultValue || todayDate}
      datePickerOptions={{
        dateRange: taskController.dateRangeOptions,
      }}
      onChange={(value: Date) => handleChange(moment(value).format('YYYY-MM-DD'))}
      isBlocked={isBlocked}
      isCloseOnChange
      isRequired
      dataTestId={'task-plan-start-date'}
    />
  );
};

PlanStartDate.displayName = 'PlanStartDate';

export default observer(PlanStartDate);
