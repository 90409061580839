import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../../../../../shared/constans/sizes';

const Wrapper = styled('div')({
  padding: '20px 24px',
  backgroundColor: Colors.generalWhite,
  borderRadius: BorderRadius.default,
  marginRight: 0,
  width: '100%',
});

const Header = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${Colors.borderGray}`,
  height: 32,
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: ESpaceSize.L,
});

const GeoPointLabel = styled('span')({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  marginLeft: 4,
});

const Title = styled('h4')({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '20px',
  margin: 0,
  // marginBottom: ESpaceSize.M,
});

const Panel = styled.div`
  &.left_panel {
    padding-right: 18px;
  }
  &.right_panel {
    flex-shrink: 1;
    overflow: hidden;
    width: 100%;
    max-width: 350px;
  }
  .geopoint {
    display: flex;
    align-content: center;
    margin-bottom: 14px;
  }
  .sunset_sunrise {
    display: flex;
    margin-bottom: 26px;
    @media (max-width: 1024px) {
      margin-bottom: 26px;
    }
    & > div {
      ${FontSizes.defaultBold}
      display: flex;
      align-items: center;
      margin-right: 4px;
      img {
        margin-right: 4px;
      }
    }
  }
  .condition {
    display: flex;
    img {
      width: 64px;
      height: 64px;
    }
    .temperature {
      margin-left: 16px;
      @media (max-width: 1024px) {
        margin-top: 0;
      }

      &_current {
        font-size: 40px;
        font-weight: 800;
        line-height: 44px;
        color: ${Colors.black};
      }
      &_feelslike {
        ${FontSizes.medium}
        color: ${Colors.darkGray};
        white-space: nowrap;
      }
    }
  }
  /* right panel */
  .current_state {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0px;
    }
    .label {
      ${FontSizes.medium}
      display: flex;
      align-items: flex-start;
      color: ${Colors.darkGray};
      white-space: nowrap;
      flex-shrink: 1;
      overflow: hidden;
      img {
        margin-right: 8px;
      }
      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
      }
    }
    .value {
      ${FontSizes.medium}
      color: ${Colors.black};
      white-space: nowrap;
    }
  }
`;

const Styles = {
  Wrapper,
  Header,
  Content,
  GeoPointLabel,
  Panel,
  Title,
};

export default Styles;
