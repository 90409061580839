import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { FieldsApiService } from '../../../../../shared/mobx/services/as-fields';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { OperationsList } from '../../components/OperationsList';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { TasksStore } from '../../stores/tasks.store';
import {
  Footer,
  Header,
  Label,
  Wrapper,
  OperationsListWrapper,
  TasksListWrapper,
  LabelWrapper,
  LabelContent,
} from '../../style';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { CultureList } from '../../components/CultureList';
import { TasksController } from '../../controllers/tasks.controller';
import { TasksList } from '../../components/TasksList/TasksList';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { ModalContainer } from '../../../../../modals/containers';
import { tasksModalUniqueKey } from '../../modals/modalsConfig';
import { EModalSize } from '../../../../../modals/components/Modal/styles';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import { DashboardRoutes } from '../../../../dashboard.routes';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../shared/hooks';
import { useOperationsParams } from '../../hooks';
import { useOperationsParamsActions } from '../../hooks/useOperationsParamsActions';
import { useContextualLink } from '../../../../../shared/features/ContextualLink/hooks';
import { EContextualLinkId } from '../../../../constants/configs';
import { TTasksContextualLinkConfig } from '../../../tasks/types';

import { OperationsFiltersActionButton } from './components/OperationsFiltersActionButton';

type TLinkState = TTasksContextualLinkConfig['from']['state'];

export const OperationsListing = observer(() => {
  const store = useStore(OperationsStore);
  const seasonStore = useStore(SeasonsStore);
  const fieldsStore = useStore(FieldsStore);
  const organizationsStore = useStore(OrganizationsStore);
  const navigate = useNavigate();

  const tasksController = useStore(TasksController);
  const tasksStore = useStore(TasksStore);

  const fieldsApiService = useStore(FieldsApiService);

  const params = useOperationsParams();
  const paramsActions = useOperationsParamsActions();

  const {
    accessRulesReady,
    getAccessRuleValue,
    loading: accessRulesLoading,
    accessRules,
  } = useStore(CheckAccessStore);

  usePageContextualHelp(EContextualParentPath.Operations);
  const helpActions = useContextualHelpActions();
  const contextualLinkActions = useContextualLink();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.Operations,
    ContextualPaths.OperationsHeaderTitle
  );

  const isOrgMy = useMemo(() => organizationsStore.selectedOrganizationId === 'my', [
    organizationsStore.selectedOrganizationId,
  ]);

  useEffect(() => {
    if (isOrgMy || !accessRulesReady) return;

    const isAllowOperations = getAccessRuleValue(`${SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS}`);

    if (!isAllowOperations) {
      const url = generatePath(DashboardRoutes.Fields, {
        orgId: organizationsStore.selectedOrganizationId,
      });

      navigate(url);
    }
  }, [isOrgMy, accessRulesReady]);

  /**
   * TODO: при рефакторинге нужно избавиться от изменения стора напрямую.
   * Часть ниже написанной логики перенесена из предыдущего варианта.
   */
  useEffect(() => {
    (async (): Promise<void> => {
      if (!seasonStore.selectedSeason) return;

      store.clearOperationsStore();

      await store.fetchCulturesList();
      await fieldsApiService.fetchFieldsList();

      if (!store.OperationCulturesInFields.length && !store.fieldsWithoutCulturesCount) return;

      const availableCultureId = params?.cultureId
        ? params.cultureId
        : store.fieldsWithoutCulturesCount
        ? 'emptyCulture'
        : store.OperationCulturesInFields[0]?.culture?.id;

      if (!params?.cultureId) {
        paramsActions.selectCulture(availableCultureId);
      }

      store.resetPageNumberSettings();

      await store.getOperations(availableCultureId);
      tasksController.resetPageSettings();

      if (params.operationId) {
        await store.fetchOperationsFieldsList(params.operationId);
      }

      /**
       * Ниже написанная логика является костыльной.
       * Она закрывает баг, что связан с подгрузкой задач после контекстного возврата.
       * В рамках задачи по редизайну раздела операций, требуется переписать и эту логику тоже.
       * Работа велась в рамках задачи H15-5315.
       */

      const link = contextualLinkActions.getLink(EContextualLinkId.TasksTaskBackButton);

      if (!(link?.from?.state as TLinkState)?.taskId) {
        tasksStore.pageNumber = 0;
        tasksStore.totalPages = 0;
        tasksController.clear();

        await tasksController.fetchTasks({
          organizationId: organizationsStore.selectedOrganizationId,
          seassonCultureId: availableCultureId,
          operationId: params.operationId,
          fieldId: params.fieldId,
          noCulture: availableCultureId === 'emptyCulture' ? true : undefined,
        });

        return;
      }

      const linkState = link.from.state as TLinkState;
      const requestList = Array(linkState.page + 1).fill(null);

      tasksStore.setHighlightedTaskId(linkState.taskId);
      contextualLinkActions.removeLink(EContextualLinkId.TasksTaskBackButton);

      let count = 0;

      for await (const _r of requestList) {
        ++count;

        await tasksController.fetchTasks(
          {
            organizationId: organizationsStore.selectedOrganizationId,
            seassonCultureId: availableCultureId,
            operationId: params.operationId,
            fieldId: params.fieldId,
            noCulture: availableCultureId === 'emptyCulture' ? true : undefined,
          },
          true,
          true
        );

        if (count === requestList.length) return;

        tasksController.increaseCurrentPage();
      }
    })();
  }, [seasonStore.selectedSeason]);

  return isOrgMy || (accessRules.length && !accessRulesLoading) ? (
    <Wrapper data-test-id={'operations-container-wrapper'}>
      <Header data-test-id={'operations-container-header'}>
        <LabelWrapper>
          <LabelContent>
            <Label data-test-id={'operations-container-header-label'}>Операции по культурам</Label>
            {ContextualHelpIcon ? ContextualHelpIcon : null}
          </LabelContent>
          <OperationsFiltersActionButton width={'170px'} placement={'bottom'} />
        </LabelWrapper>

        <CultureList
          cultures={Array.from(store.OperationCulturesInFields)}
          fieldsWithoutCulturesCount={store.fieldsWithoutCulturesCount}
        />
        <div />
      </Header>

      <Footer data-test-id={'operations-container-footer'}>
        {seasonStore.selectedSeason && fieldsStore.hasFields ? (
          <OperationsListWrapper id="OperationsListWrapper">
            <OperationsList />
          </OperationsListWrapper>
        ) : null}

        <TasksListWrapper>
          <TasksList />
        </TasksListWrapper>
      </Footer>

      <ModalContainer uniqueKey={tasksModalUniqueKey} $size={EModalSize.Medium} />
    </Wrapper>
  ) : null;
});
