import { Colors } from '@farmlink/farmik-ui';
import { useEffect, useState } from 'react';

import { CultureModel } from '../../../../../../../../../../../../../api/models/culture.model';
import { Field } from '../../../../../../../../../../../../../api/models/field.model';
import { IComputedData, TCulturesCollection } from '../interfaces/CulturesBar.interfaces';

const emptyCultureModel: Partial<CultureModel> = {
  name: 'Нет культуры',
  attrs: {
    assistanceColorLegend: Colors.hoverBorder,
    useInAssistance: false,
  },
};

function useComputedData(filteredList: Field[]) {
  const initialStats: IComputedData = {
    generalStats: { count: 0, area: 0 },
    culturesStats: new Map(),
  };

  const [generalStats, setGeneralStats] = useState(initialStats.generalStats);
  const [culturesStats, setCulturesStats] = useState(initialStats.culturesStats);

  const extractData = (arr: Field[]) => {
    // Подсчитываем кол-во всех КЗ и каждой КЗ по отдельности
    return arr.reduce<IComputedData>((acc, curr) => {
      acc.generalStats.count += 1;

      // Инкрементируем "Нет культуры" в случае если у поля нет кз. В этом случае площадь КЗ = площадь поля
      if (!curr?.cultureZones?.length) {
        acc.generalStats.area += curr.area;
        setCulture(acc.culturesStats, emptyCultureModel, 1, curr.area);

        return acc;
      }

      const tempSet = new Set();

      /**
       * Проходимся по всем КЗ и инкрементируем каждую.
       * !В случае если у поля несколько одинаковых КЗ то суммируется только их площадь, кол-во будет равно 1.
       * Пример: у поля три КЗ с одной культурой "мак", на выходе получим культуру мак с суммированной площадью по трем КЗ
       */
      curr.cultureZones.forEach(zone => {
        acc.generalStats.area += zone.area;

        const increase = tempSet.has(zone.culture.name) ? 0 : 1;
        setCulture(acc.culturesStats, zone.culture, increase, zone.area);
        tempSet.add(zone.culture.name);
      });

      tempSet.clear();

      return acc;
    }, initialStats);
  };

  useEffect(() => {
    const data = extractData(filteredList ?? []);

    setGeneralStats(data.generalStats);
    setCulturesStats(data.culturesStats);
  }, [filteredList]);

  return {
    generalStats,
    culturesList: transformToArray(culturesStats),
  };
}

/**
 * Инкрементирует значение и суммирует площадь переданной культуры если она присутствует в коллекции
 * Создаёт элемент если его ещё нет в коллекции.
 */
function setCulture(
  collection: TCulturesCollection,
  culture: Partial<CultureModel>,
  increase: number,
  area: number
) {
  const current = collection.get(culture.name);

  if (!current) {
    const color = culture?.attrs?.assistanceColorLegend;
    collection.set(culture.name, { count: 1, color, area });

    return;
  }

  current.count += increase;
  current.area += area;
}

function transformToArray(collection: TCulturesCollection) {
  const array = Array.from(collection, ([name, value]) => ({ name, ...value }));

  return array.sort((a, b) => b.count - a.count);
}

export default useComputedData;
