import styled, { css, keyframes } from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

const fadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
`;

const Wrapper = styled.div<{ $disabled?: boolean }>`
  width: 100%;
  position: relative;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const CalendarSmallBlock = styled.div<{ $isDisabled?: boolean }>`
  width: 32px;
  height: 32px;
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${Colors.white};
  border: 0.5px solid ${Colors.grayBorder};
  transition: border-color 0.2s ease-in-out;
  animation: ${fadeIn} 0.2s;
  margin-bottom: 6px;
  svg {
    height: 20px;
    width: 20px;
    fill: ${Colors.black};
  }
  &:hover {
    border-color: ${Colors.darkGray};
  }
`;

const Styled = {
  CalendarSmallBlock,
  Wrapper,
};

export default Styled;
