import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { CulturesLegendMapControl } from '../../../../../../../shared/features/map/components/Controls';
import { CultureModel } from '../../../../../../../../api/models/culture.model';
import { ELeftPanelType } from '../../interfaces/LeftPanelType.enum';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../mobx';

/**
 * Интерактивный вариант легенды культур для режима просмотра полей (синхронизуется с фильтрами по культуре)
 */
const FieldViewCulturesLegend: FC = () => {
  // Сторы
  const store = useStore(Store);

  const cultureFilter = store.filterManager?.filters.getOne('culture');

  const selectedCultures = useMemo(() => {
    if (store.leftPanelType !== ELeftPanelType.FILTER) {
      return [];
    }

    return cultureFilter?.value ?? [];
  }, [cultureFilter?.value]);

  const disabledCultures = useMemo(() => {
    if (!cultureFilter?.optionList) {
      return [];
    }

    return cultureFilter.optionList.filter(option => option.disabled).map(option => option.value);
  }, [cultureFilter?.optionList]);

  const handleItemClick = (culture: CultureModel) => {
    store.openFilter();

    cultureFilter?.toggleValue(culture.name);
  };

  return (
    <CulturesLegendMapControl
      selectedCultures={selectedCultures}
      disabledCultures={disabledCultures}
      onClick={handleItemClick}
    />
  );
};

export default observer(FieldViewCulturesLegend);
