import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TableBuilderController } from '../../../../../../../../shared/features/TableBuilder/mobx/controllers';
import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { TasksListConfigsService as ConfigsService } from '../../services';
import { TasksService } from '../../../../../../../../shared/mobx/services/as-fields';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import { ITasksFilters } from '../../../../TasksFilters/models';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { TableFiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { TableBuilderStore } from '../../../../../../../../shared/features/TableBuilder/mobx/stores';
import { ETableFiltersBuilderId } from '../../../../../../../constants/configs/TableFiltersBuilderId';
import { ETasksTableBuilderId } from '../../../../../utils/constants';
import { FieldsStore } from '../../../../../../../../shared/mobx/stores';
import { ITableBuilderRowConfig } from '../../../../../../../../shared/features/TableBuilder/models/configs';

type TEntries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

@provide.transient()
class TasksListController {
  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(TableBuilderStore)
  protected tableBuilderStore: TableBuilderStore<ITask, 'tasks'>;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<ITasksFilters>;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController<ITask, 'tasks'>;

  public initiateTable = (
    handleRowClick: ITableBuilderRowConfig<ITask>['onRowClick'],
    addPlugConfig: () => void
  ): void => {
    const config = this.configsService.createConfig();

    this.tableBuilderController.initiateTable(config);
    this.tableBuilderController.addPaginationScrollEvent('tasks', () =>
      this.fetchTaskList(this.tableFiltersBuilderController.getAppliedFilters('tasks'), true)
    );

    this.tableBuilderController.addRowClickEvent(ETasksTableBuilderId.Tasks, handleRowClick);
    this.tableBuilderController.showDefaultPlug(ETasksTableBuilderId.Tasks);

    addPlugConfig();

    const isNoSeason = !this.seasonsStore.selectedSeason || this.seasonsStore.selectedSeason === '';
    const isNoFields = !this.fieldsStore.hasFields;

    if (isNoSeason || isNoFields) {
      this.tableBuilderController.displayPlug(ETasksTableBuilderId.Tasks, 'noSeason');
      this.tableBuilderController.hideLoader(ETasksTableBuilderId.Tasks);
    } else {
      this.tableBuilderController.displayPlug(ETasksTableBuilderId.Tasks, null);
      this.tableBuilderController.hideDefaultPlug(ETasksTableBuilderId.Tasks);
      this.tableBuilderController.hideLoader(ETasksTableBuilderId.Tasks);
    }
  };

  /**
   * Метод получения списка задач согласно фильтрам.
   * @param payload - выбранные фильтры.
   * @param isSavePagination - флаг, который говорит о том, сохраняем мы пагинацию или нет.
   * Когда активируется запрос после обновленных фильтров, то обнуляем пагинацию.
   */
  fetchTaskList = async (payload: ITasksFilters, isSavePagination?: boolean): Promise<void> => {
    if (!this.seasonsStore.selectedSeason) {
      return;
    }

    if (!isSavePagination) {
      this.tableBuilderController.addCurrentPage('tasks', 0);
      this.tableBuilderController.addTotalPages('tasks', 0);
    }

    const request: TypeApiRequest<'getTaskList'> = {
      ...payload,
      cultureId: payload?.cultureId?.map?.(id => (id === 'noCulture' ? null : id)),
      assigneeId: payload?.assigneeId?.map?.(id => (id === 'noAssignee' ? null : id)),
      seasonYear: Number(this.seasonsStore.selectedSeason),
      organizationId:
        this.organizationsStore.selectedOrganizationId !== 'my'
          ? this.organizationsStore.selectedOrganizationId
          : undefined,
    };

    const currentPage = this.tableBuilderStore.getCurrentPage('tasks');

    await this.tasksService.getTaskListByFilters(request, {
      query: {
        page: isSavePagination ? currentPage : 0,
        size: 20,
      },
      actions: {
        showLoader: () => {
          this.tableBuilderController.showLoader('tasks');
        },
        hideLoader: () => {
          this.tableBuilderController.hideLoader('tasks');
          this.tableBuilderController.hideDefaultPlug('tasks');
        },
        success: async response => {
          await this.fetchTotalTasksCount();

          this.tableBuilderController.addTotalPages('tasks', response.totalPages);
          this.tableBuilderController.addNumberOfEntities('tasks', response.totalElements);

          this.tableBuilderController.addElementList('tasks', response.content, 'id', {
            isClearPreviousList: !isSavePagination,
          });

          const hasAppliedFilters = this.checkIfHasAppliedFilters();
          const hasTaskList = response.content.length > 0;

          if (hasTaskList) {
            this.unBlockFilters();
            return;
          }

          if (hasAppliedFilters) this.unBlockFilters();
          else this.blockFilters();
        },
      },
    });
  };

  public blockFilters = (): void => {
    this.tableFiltersBuilderController.blockFilters(ETableFiltersBuilderId.Tasks);
  };

  public unBlockFilters = (): void => {
    this.tableFiltersBuilderController.unBlockFilters(ETableFiltersBuilderId.Tasks);
  };

  /**
   * Проверяем, есть ли примененные фильтры, но с одним "но":
   * Если у нас даты совпадают с сезоном, то учитываем, как будто у нас нет примененных дат.
   * Иначе мы никогда не сможем увидеть заглушку, которая отражает, что у пользователя нет созданных задач.
   */
  public checkIfHasAppliedFilters = (): boolean => {
    const appliedFilters: ITasksFilters =
      this.tableFiltersBuilderController.getAppliedFilters('tasks') || {};

    const appliedFilterList = Object.entries(appliedFilters) as TEntries<ITasksFilters>;

    if (!appliedFilterList.length) return false;

    const formattedList = appliedFilterList.filter(([key, value]) => {
      switch (key) {
        case 'planDateFrom': {
          const hasTheSameValue = value === this.seasonsStore.selectedSeassonData?.startDate;
          return !hasTheSameValue;
        }

        case 'planDateTo': {
          const hasTheSameValue = value === this.seasonsStore.selectedSeassonData?.endDate;
          return !hasTheSameValue;
        }

        default:
          return true;
      }
    });

    return formattedList.length > 0;
  };

  /**
   * Метод получения общего числа задач для отображения в header
   */
  protected fetchTotalTasksCount = async (): Promise<void> => {
    const payload: TypeApiRequest<'getTotalTasks'> = {
      organizationId: this.organizationsStore.selectedOrganizationId,
      seasonYear: Number(this.seasonsStore.selectedSeason),
    };

    await this.tasksService.getTotalTasksCount(payload, {
      query: {
        organizationId: payload.organizationId,
        seasonYear: payload.seasonYear,
      },
      actions: {
        handleLoading: isLoading => {
          if (isLoading) this.tableBuilderController.showLoader('tasks');
          else {
            this.tableBuilderController.hideLoader('tasks');
            this.tableBuilderController.hideDefaultPlug('tasks');
          }
        },
        handleSuccess: response => {
          this.tableBuilderController.addTotalEntities('tasks', response.totalAmountOfTasks);
        },
      },
    });
  };
}

export default TasksListController;
