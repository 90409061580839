import moment from 'moment/moment';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationViewStore as Store } from '../../stores';
import { TaskOperationViewApiService as ApiService } from '../TaskOperationViewApiService';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { toDouble } from '../../../../../mobx/stores/TaskStore/Task.store';

interface IDateRangeOptions {
  minDate?: Date;
  maxDate?: Date;
}

@provide.transient()
class TaskOperationViewDefaultValuesService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  public get cultureName() {
    return this.store.selectedTask?.culture?.name;
  }

  public get fieldName() {
    const cultureZone = this.store.selectedTask?.cultureZone;

    const labelData = [
      cultureZone?.fieldName,
      cultureZone?.name,
      `${toDouble(cultureZone?.area)} га`,
    ];

    return labelData.filter(Boolean).join(', ');
  }

  public get operationName() {
    return this.store.selectedTask?.operationInfo?.name;
  }

  public get operationType() {
    const operationValuesLength = this.store.selectedTask?.operationValues.length;

    if (operationValuesLength) {
      if (operationValuesLength === 1) {
        return this.store.selectedTask.operationValues[0].dictionaryValueName;
      } else {
        return `Выбрано ${operationValuesLength}`;
      }
    }
  }

  public get operationTypeLabel() {
    return this.store.operationTypeLabel;
  }

  public get assigneeName() {
    return this.store.selectedTask?.assignee?.fullName;
  }

  public get date() {
    const { selectedTask } = this.store;

    if (selectedTask?.executedDate) {
      return moment(selectedTask.executedDate).format('DD.MM.YYYY');
    }
  }
}

export default TaskOperationViewDefaultValuesService;
