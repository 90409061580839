import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { useTasksParams } from '../../../../../../hooks';
import { FullscreenContext } from '../../../../../../containers/fullscreen';
import { TASK_COMMON_DATA_FORM_REQUIRED_TEXT } from '../../constants';

const CultureSelect: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const bodyForceCloseOptions = useContext(FullscreenContext);

  const params = useTasksParams();

  const isCreate = taskStore.isCreateOne || taskStore.isCreateMultiple;
  const isBlocked = !isCreate || taskStore.isEditMode;

  const checkErrors = () => {
    if (taskStore.isCreateOne) {
      return taskStore.taskCreateOneSelectsWithErrors[0] === 'cultureId';
    }

    if (taskStore.isCreateMultiple) {
      return taskStore.taskCreateGroupSelectsWithErrors[0] === 'cultureId';
    }
  };

  useEffect(() => {
    (async () => {
      if (isCreate && taskStore.taskCreate?.cultureId) {
        await taskController.fetchOperationListAfterCultureChange(params.orgId);
      }
    })();
  }, [taskStore.taskCreate?.cultureId, isCreate]);

  const handleChange = useCallback((cultureId: string) => {
    taskController.changeCulture(cultureId ?? '');
  }, []);

  const defaultValue = taskStore.cultureDefaultValue;

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      placeholder: 'Выберите культуру',
      defaultValue,
      isRequired: true,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
      },
    },
    body: {
      optionList: taskStore.cultureOptionList,
    },
    visual: {
      label: 'Культура',
      isBlocked,
    },
    validation: {
      error: {
        errorList: checkErrors() ? [TASK_COMMON_DATA_FORM_REQUIRED_TEXT] : null,
      },
    },
    other: {
      dataTestId: 'task-culture-select',
    },
  };

  return <NewDropdown bodyForceCloseOptions={bodyForceCloseOptions} config={dropdownConfig} />;
};

CultureSelect.displayName = 'CultureSelect';

export default observer(CultureSelect);
