import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';
import { StyledTasksDrawerTask as Styled } from '../../styles';

import { TextExpand } from './components';

interface IProps {
  taskComment: string;
  dataTestId: string;
}
const TasksDrawerTaskComment: FC<IProps> = ({ taskComment, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__task-comment`);

  return (
    <Styled.CommentBlock {...getDataTestId('comment-block')}>
      <Styled.CommentLabel dataTestId={getDataTestId('comment-label')['data-test-id']}>
        Комментарий к задаче
      </Styled.CommentLabel>

      <TextExpand
        dataTestId={getDataTestId('comment-text')['data-test-id']}
        text={taskComment || 'Отсутствует'}
      />
    </Styled.CommentBlock>
  );
};

TasksDrawerTaskComment.displayName = 'TasksDrawerTaskComment';

export default memo(TasksDrawerTaskComment);
