import { ITotalSTO } from '../../../models/as-fields/STO/STO.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type GetTotalSTOReq = {
  organizationId: string;
  seasonYear: number;
};

type TypeResponse = ITotalSTO;

export const getTotalSTO: TypeApiRoute & {
  request: GetTotalSTOReq;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/sto/totalSTO`,
  method: 'GET',
  headers: {},
  request: {} as GetTotalSTOReq,
  response: {} as TypeResponse,
};

export type { GetTotalSTOReq };
