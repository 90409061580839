import { Typography } from '@farmlink/farmik-ui';

const DeletionModalTitle = () => {
  return (
    <Typography variant={'title'}>
      Удалить регистрацию?
    </Typography>
  );
};

export default DeletionModalTitle;