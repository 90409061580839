import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CalendarComponent } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { useTaskActionControl } from '../../../../../../hooks';
import {
  ETaskAction,
  ETaskStatus,
} from '../../../../../../../../../../api/models/as-fields/task/task.model';
import { TASK_COMMON_DATA_FORM_REQUIRED_TEXT } from '../../constants';

const PlanEndDate: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const [openToDate, setOpenToDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const controls = useTaskActionControl();

  const checkErrors = () => {
    if (taskStore.isCreateOne) {
      const isDependencyFieldValid = !(taskStore.taskCreateOneSelectsWithErrors[0] === 'fieldId');

      return (
        !isDependencyFieldValid || taskStore.taskCreateOneSelectsWithErrors[0] === 'planEndDate'
      );
    }

    if (taskStore.isCreateMultiple) {
      const isDependencyFieldValid = !(
        taskStore.taskCreateGroupSelectsWithErrors[0] === 'taskItems'
      );

      return (
        !isDependencyFieldValid || taskStore.taskCreateGroupSelectsWithErrors[0] === 'planEndDate'
      );
    }
  };

  useEffect(() => {
    const isForceOpenDateAllowed =
      taskStore?.taskCreate?.planStartDate &&
      !taskStore?.taskCreate?.planEndDate &&
      !isDateSelected;

    if (isForceOpenDateAllowed) {
      const formattedStartDate = moment(taskStore?.taskCreate?.planStartDate).format('DD.MM.YYYY');
      setOpenToDate(formattedStartDate);
    } else {
      setOpenToDate(null);
    }
  }, [taskStore?.taskCreate?.planStartDate, taskStore?.taskCreate?.planEndDate]);

  const isCreate = taskStore.isCreateOne || taskStore.isCreateMultiple;
  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditCommonInfoDates);
  const canEdit = isCreate || (taskStore.isEditMode && hasPermissionToEdit);

  const hasEmptyOperationId = isCreate ? !taskStore?.taskCreate?.operationId : false;
  const isBlocked =
    taskStore.isViewMode ||
    !canEdit ||
    hasEmptyOperationId ||
    taskStore?.selectedTask?.status === ETaskStatus.InWork;

  const todayDate = moment().format('DD.MM.YYYY');

  const initialPlanEndDate = taskController.planEndDateDefaultValue || todayDate;

  const defaultValue = !isCreate ? initialPlanEndDate : '';

  const currentDateValue =
    taskStore?.taskCreate?.planEndDate &&
    moment(taskStore?.taskCreate?.planEndDate).format('DD.MM.YYYY');

  const handleChange = useCallback((planEndDate: string): void => {
    taskController.changePlanEndDate(planEndDate);
    setIsDateSelected(true);
  }, []);

  return (
    <CalendarComponent
      label={'Плановое завершение'}
      defaultValue={defaultValue}
      datePickerOptions={{
        dateRange: taskController.dateRangeOptions,
      }}
      value={currentDateValue}
      forceOpenToDate={openToDate}
      placeholder={'Выберите дату'}
      onChange={(value: Date) => handleChange(moment(value).format('YYYY-MM-DD'))}
      isBlocked={isBlocked}
      isRequired
      isCloseOnChange
      error={checkErrors() ? TASK_COMMON_DATA_FORM_REQUIRED_TEXT : null}
      dataTestId={'task-plan-end-date'}
    />
  );
};

PlanEndDate.displayName = 'PlanEndDate';

export default observer(PlanEndDate);
