import React, { FC } from 'react';
import { ListRowProps, AutoSizer } from 'react-virtualized';
import { Ellipsis, VirtualizedList } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { TCulturesList } from '../../interfaces/CulturesBar.interfaces';

import Styled from './CulturesList.styles';

interface IProps {
  list: TCulturesList;
  rowHeight: number;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const FieldsListLegendCulturesList: FC<IProps> = props => {
  const getRender = ({ index, key, style }: ListRowProps) => {
    const culture = props.list[index];

    return (
      <Styled.ListItem
        key={key}
        $color={culture.color}
        style={style}
        {...props.getDataTestId(`list-item-${culture.name}`)}
      >
        <Styled.NameWrapper>
          <Ellipsis>{culture.name}</Ellipsis>
        </Styled.NameWrapper>

        <span>
          {culture.count} / {culture.area.toFixed(1)} га
        </span>
      </Styled.ListItem>
    );
  };

  return (
    <Styled.List>
      <AutoSizer>
        {size => (
          <VirtualizedList
            width={size.width + 10} // + 10px чтобы визуально вынести скролл за пределы контейнера
            height={size.height}
            rowCount={props.list.length}
            rowHeight={props.rowHeight}
            rowRenderer={getRender}
            overscanRowCount={3}
            withPadding
          />
        )}
      </AutoSizer>
    </Styled.List>
  );
};

export default FieldsListLegendCulturesList;
