import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import { useStore } from '../../../../../shared/utils/IoC';
import { TaskDefaultValuesService } from '../../../tasks/mobx/services';
import { TaskStore } from '../../../tasks/mobx/stores';
import { OperationsStore } from '../../stores/operations.store';
import { NO_CULTURE_SELECT_OPTION } from '../../../../../shared/utils/constants/selectOptions';
import {
  ETaskStatus,
  ETaskType,
  ITaskCreate,
} from '../../../../../../api/models/as-fields/task/task.model';
import { checkIfDateIsInTheRange } from '../../../../../shared/utils/helpers/checkIfDateIsInTheRange';
import { ETasksContextualReturnBackButtonText } from '../../../tasks/constants';
import { useOperationsParams } from '../useOperationsParams';
import { TTasksContextualLinkConfig } from '../../../tasks/types';
import { TaskOperationCreateStore } from '../../../tasks/modules/TaskOperationCreate/mobx/stores';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import { TaskOperationCreateApiService } from '../../../tasks/modules/TaskOperationCreate/mobx/services';

type TContextualReturn = TTasksContextualLinkConfig['from'];

const useOperationsTaskActions = () => {
  const taskDefaultValuesService = useStore(TaskDefaultValuesService);
  const taskOperationCreateApiService = useStore(TaskOperationCreateApiService);

  const profileStore = useStore(ProfileStore);
  const taskStore = useStore(TaskStore);
  const taskOperationCreateStore = useStore(TaskOperationCreateStore);
  const operationsStore = useStore(OperationsStore);

  const location = useLocation();
  const params = useOperationsParams();

  const getOperationData = (bindingDate?: string): string => {
    const selectedOperation = operationsStore.getOperation(params.operationId);

    if (!selectedOperation) return '';

    /**
     * Странный кейс. У нас может быть операция, диапазон дат которой не пересекается
     * с предустановленной датой в списке задач.
     */

    if (!bindingDate) return selectedOperation.id;

    const { startDate, endDate } = selectedOperation;
    const isInTheRange = checkIfDateIsInTheRange(bindingDate, startDate, endDate);

    if (!isInTheRange) return '';

    return selectedOperation.id;
  };

  const getContextualReturn = (): TContextualReturn => {
    return {
      backButtonLabel: ETasksContextualReturnBackButtonText.OperationsListing,
      url: `${location.pathname}${location.search}`,
    };
  };

  const presetTaskMonitoringData = (bindingDate?: string): void => {
    const cultureId =
      params.cultureId === 'emptyCulture' ? NO_CULTURE_SELECT_OPTION.value : params.cultureId;

    const selectedOperation = operationsStore.getOperation(params.operationId);

    const planStartDate =
      bindingDate ?? taskDefaultValuesService.getPresetPlanStartDate(selectedOperation);

    const data: Partial<ITaskCreate> = {
      cultureId,
      planStartDate,
      planEndDate: null,
      operationId: getOperationData(bindingDate),
    };

    if (bindingDate) {
      data.bindingDate = bindingDate;
    }

    taskStore.setTaskCreateData(data);
  };

  const presetTaskOperation = (): void => {
    const cultureId = params.cultureId === 'emptyCulture' ? '' : params.cultureId;

    const selectedOperation = operationsStore.getOperation(params.operationId);
    const operationId = getOperationData();

    const taskCreate: ITaskCreate = {
      cultureId,
      operationId,
      planStartDate: '',
      planEndDate: '',
      comment: '',
      status: ETaskStatus.Completed,
      assigneeId: profileStore?.user?.id ?? '',
      type: ETaskType.Operation,
      taskItems: [],
    };

    taskOperationCreateStore.setTaskCreate(taskCreate);

    if (cultureId) void taskOperationCreateApiService.getOperationOptionList();

    if (operationId) {
      void taskOperationCreateApiService.getConfig(selectedOperation.operationTypeInfo.id);
    }
  };

  return {
    presetTaskMonitoringData,
    presetTaskOperation,
    getContextualReturn,
  };
};

export default useOperationsTaskActions;
