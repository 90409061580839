import React from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { OperationName } from '../../style';

interface IProps {
  name: string;
}

const TasksListOperationName = ({ name }: IProps) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>(name);

  return (
    <AutoTooltip content={name} disabled={!isShowTooltip}>
      <OperationName ref={ref} noWrap ellipsis data-test-id={'task-item-operation-name'}>
        {name}
      </OperationName>
    </AutoTooltip>
  );
};

export default TasksListOperationName;
