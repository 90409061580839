import { CultureModel } from '../../../../../api/models/culture.model';
import { Field } from '../../../../../api/models/field.model';

type TFieldArg = Field[] | Field;

function extractCultures(fields: TFieldArg, returnNames: true): Set<string>;
function extractCultures(fields: TFieldArg, returnNames: false): Map<string, CultureModel>;
function extractCultures(
  fields: TFieldArg,
  returnNames: boolean
): Set<string> | Map<string, CultureModel> {
  const tempCollection = new Map<string, CultureModel>();
  const array = Array.isArray(fields) ? fields : [fields];

  array.forEach((field: Field) => {
    (field.cultureZones ?? []).forEach(zone => {
      if (zone.culture) {
        tempCollection.set(zone.culture.name, zone.culture);
      }
    });
  });

  if (returnNames) {
    return new Set(tempCollection.keys());
  }

  return tempCollection;
}

/**
 * Извлекает уникальные культуры из списка полей
 *
 * @param fields - поле или список полей откуда извлекать культуры. они будут извлечены из культурных зон
 * @param returnNames - если передать true то возвращает коллекцию имен культур
 */
export default extractCultures;
