import React, { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import SharedStyled from '../../../shared/styles/Shared.styles';

interface IProps {
  title: string;
  subtitle: string;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const ListCardTextWrapper: FC<IProps> = props => {
  return (
    <SharedStyled.TextWrapper {...props.getDataTestId('text-wrapper')}>
      <SharedStyled.Title dataTestId={props.getDataTestId('title')['data-test-id']}>
        {props.title}
      </SharedStyled.Title>
      <SharedStyled.Subtitle dataTestId={props.getDataTestId('subtitle')['data-test-id']}>
        {props.subtitle}
      </SharedStyled.Subtitle>
    </SharedStyled.TextWrapper>
  );
};

export default memo(ListCardTextWrapper);
