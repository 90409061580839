import { FC } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, TButtonMultiAction, TDropdownButtonProps } from '@farmlink/farmik-ui';
import { CSSProperties } from 'styled-components';

import {
  useTasksRouteActions,
  useTasksTaskOperationRoutes as useTaskOperationRoutes,
} from '../../../../../tasks/hooks';
import { useOperationsTaskActions } from '../../../../hooks/useOperationsTaskActions';

interface IProps {
  width: CSSProperties['width'];
  placement: TDropdownButtonProps['style']['placement'];
}

const OperationsFiltersActionButton: FC<IProps> = ({ width, placement }) => {
  const tasksRoutes = useTasksRouteActions();
  const taskOperationRoutes = useTaskOperationRoutes();
  const operationsTaskActions = useOperationsTaskActions();

  const btnActionForCreateOneTask: TButtonMultiAction = {
    title: 'Одну',
    onClick: () => {
      operationsTaskActions.presetTaskMonitoringData();
      tasksRoutes.goToTaskCreateOne(operationsTaskActions.getContextualReturn());
    },
  };

  const btnActionForCreateMultipleTask: TButtonMultiAction = {
    title: 'Группу',
    onClick: () => {
      tasksRoutes.goToTaskCreateGroup(operationsTaskActions.getContextualReturn());
    },
  };

  const btnActionForTaskOperationCreate: TButtonMultiAction = {
    title: 'Регистрация работ',
    onClick: () => {
      operationsTaskActions.presetTaskOperation();
      taskOperationRoutes.goToCommonInfo(operationsTaskActions.getContextualReturn());
    },
  };

  return (
    <DropdownButton
      dataTestId={'operations__create-tasks'}
      title={'Создать задачу'}
      multiActionList={[
        btnActionForCreateOneTask,
        btnActionForCreateMultipleTask,
        btnActionForTaskOperationCreate,
      ]}
      style={{
        color: 'primary',
        size: 'sm',
        placement,
        width,
      }}
    />
  );
};

OperationsFiltersActionButton.displayName = 'TasksFiltersActionButton';

export default observer(OperationsFiltersActionButton);
