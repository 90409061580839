import _ from 'lodash';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { ITaskCreate, TTaskUpdate } from '../../../../../api/models/as-fields/task/task.model';
import { TaskStore } from '../mobx/stores';

@provide.transient()
export class TaskValidatingService {
  @lazyInject(TaskStore)
  protected taskStore: TaskStore;

  public prepareTaskFields = (taskUpdate: Partial<TTaskUpdate>) => {
    const validatedTask = { ...taskUpdate };

    if (validatedTask?.comment) {
      validatedTask.comment = this.prepareComment(validatedTask.comment);
    }

    if (validatedTask?.assigneeId) {
      validatedTask.assigneeId = this.prepareAssignee(validatedTask.assigneeId);
    }

    return validatedTask;
  };

  public prepareAssignee = (assigneeId: string): string | null => {
    if (assigneeId === 'noAssignee') {
      return null;
    } else {
      return assigneeId;
    }
  };

  public prepareComment = (comment: string): string => {
    return comment.trim();
  };

  public validateCreateOne = () => {
    const taskCreate: ITaskCreate = this.taskStore?.taskCreate;

    if (!taskCreate) return;

    /**
     * Массив полей из taskCreate, для которых требуется валидация в TaskCreateOne
     * Порядок элементов имеет значение
     */
    const fieldsToCheck = ['cultureId', 'operationId', 'fieldId', 'planEndDate'];

    const errorFieldsSet = fieldsToCheck.filter(key => !taskCreate[key]);

    this.taskStore.setTaskCreateOneSelectsWithErrors(errorFieldsSet);
  };

  public validateCreateGroup = () => {
    const taskCreate: ITaskCreate = this.taskStore?.taskCreate;

    if (!taskCreate) return;

    /**
     * Массив полей из taskCreate, для которых требуется валидация в TaskCreateGroup
     * в случае создании группы массив полей сетается в taskCreate.taskItems, поэтому используется соответствующее имя
     * Порядок элементов имеет значение
     */
    const fieldsToCheck = ['cultureId', 'operationId', 'taskItems', 'planEndDate'];

    const errorFieldsSet = fieldsToCheck.filter(key => {
      if (key === 'taskItems') {
        return !taskCreate[key]?.length;
      } else {
        return !taskCreate[key];
      }
    });

    this.taskStore.setTaskCreateGroupSelectsWithErrors(errorFieldsSet);
  };
}

export default TaskValidatingService;
