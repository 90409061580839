import {
  Colors,
  DropdownMenu,
  ENotificationType,
  Icon,
  TDropdownMenuItem,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { EFieldAccessAction } from '../../../../../../../../../../../../../shared/constants/access-rules-action';
import { WeatherController, WeatherStore } from '../../../../../../../WeatherSlider/mobx';
import { Field } from '../../../../../../../../../../../../../../api/models/field.model';
import { SeasonsStore } from '../../../../../../../../../../../../stores/seasons.store';
import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { FieldsViewController as Controller } from '../../../../../../../../mobx';
import useFieldRoutes from '../../../../../../../../../../hooks/useFieldsRoutes';
import { EFieldsCommonModalName } from '../../../../../../../../../../modals';
import { useFieldsPermissions } from '../../../../../../../../../../hooks';

import './style.css';

interface IProps {
  field: Field;
  onOpen: () => void;
}

const FieldCardDropdownMenu: FC<IProps> = props => {
  const { field } = props;

  // Навигация
  const navigate = useNavigate();
  const fieldsRoutes = useFieldRoutes();
  const permissions = useFieldsPermissions();

  // Сторы
  const seasonsStore = useStore(SeasonsStore);
  const weatherStore = useStore(WeatherStore);

  // Контроллеры
  const controller = useStore(Controller);
  const weatherController = useStore(WeatherController);

  const modal = useModal();
  const notificator = useNotificator();

  const handleWeatherClick = (closePopupFn: () => void, value: boolean) => {
    if (value) {
      weatherController.open(field.id);
    } else {
      weatherController.close();
    }

    closePopupFn();
  };

  const handleEditClick = (closePopupFn: () => void) => {
    navigate(fieldsRoutes.fieldEditRoute(field.id));
    closePopupFn();
  };

  const handleNavigateSeasonClick = (closePopupFn: () => void, enableDraw = false) => {
    navigate(fieldsRoutes.fieldSeasonsViewRoute(field.id), {
      state: { enableDraw },
    });
    closePopupFn();
  };

  const handleDeleteClick = (closePopupFn: () => void) => {
    const modalId = EFieldsCommonModalName.DeleteFieldWarning;

    modal.openModalByModalId(modalId, null, checkboxValue => {
      const seasonFromDelete = checkboxValue ? null : Number(seasonsStore.selectedSeason);

      if (isForbiddenToDelete()) {
        notificator.setNotification({
          message: 'Нельзя удалить поле, включенное в опыт',
          style: { type: ENotificationType.Warning, placement: 'top-right' },
        });

        modal.closeModal();

        return;
      }

      void controller.deleteField(field, seasonFromDelete);

      modal.closeModal();
    });

    closePopupFn();
  };

  const isForbiddenToDelete = () => {
    return field.cultureZones.some(zone => zone.usedInExperiment);
  };

  const menuItems = useMemo((): TDropdownMenuItem[] => {
    return [
      {
        type: 'config',
        text: 'Погода',
        icon: <Icon icon={'sunOutline'} size={'medium'} />,
        dataTestId: 'weather-sun-item',
        onClick: close => handleWeatherClick(close, true),
        hide: weatherStore.isOpen,
      },
      {
        type: 'config',
        text: 'На карту',
        icon: <Icon icon={'size'} size={'medium'} />,
        dataTestId: 'weather-back-icon',
        onClick: close => handleWeatherClick(close, false),
        hide: !weatherStore.isOpen,
      },
      {
        type: 'config',
        text: 'Редактировать',
        icon: <Icon icon={'edit'} size={'medium'} />,
        dataTestId: 'edit-item',
        onClick: handleEditClick,
        hide: !permissions.hasAccess(EFieldAccessAction.EditField),
      },
      {
        type: 'config',
        text: 'Добавить культуру',
        icon: <Icon icon={'plus'} size={'medium'} />,
        dataTestId: 'add-culture-item',
        onClick: fn => handleNavigateSeasonClick(fn, true),
        hide: field?.cultureZones?.length >= 1,
      },
      {
        type: 'config',
        text: 'История посевов',
        icon: <Icon icon={'historyLeaf'} size={'medium'} />,
        dataTestId: 'season-history-item',
        onClick: handleNavigateSeasonClick,
      },
      {
        type: 'config',
        text: { value: 'Удалить поле', style: { color: Colors.red } },
        icon: <Icon icon={'bin'} size={'medium'} fill={Colors.red} />,
        dataTestId: 'remove-field-item',
        onClick: handleDeleteClick,
        hide: !permissions.hasAccess(EFieldAccessAction.DeleteField),
      },
    ];
  }, [field, weatherStore.isOpen]);

  return (
    <DropdownMenu
      popupProps={{
        arrow: false,
        position: 'right top',
        onOpen: props.onOpen,
      }}
      menuItems={menuItems as any}
      dataTestId={'field-view-context-menu'}
    />
  );
};

export default observer(FieldCardDropdownMenu);
