import React, { useMemo } from 'react';
import { useDataTestIdV2 } from '@farmlink/ui-kit';

import { ListingPage } from '../../../../../shared/features/UI/ListingPage';
import { TasksList } from '../TasksList';
import { SimpleLoader } from '../../../../../shared/features/UI/new/SimpleLoader';

import Styled from './TasksListingPageSkeleton.styles';

const TasksListingPageSkeleton = () => {
  const getDataTestId = useDataTestIdV2('tasks__listing-page__skeleton');

  const Loader = useMemo(() => {
    return (
      <Styled.LoaderWrapper {...getDataTestId('loader-wrapper')}>
        <SimpleLoader dataTestId={getDataTestId()['data-test-id']} size={24} />
        <Styled.Title {...getDataTestId('title')}>Загружаем ...</Styled.Title>
      </Styled.LoaderWrapper>
    );
  }, []);

  return (
    <ListingPage
      id={'tasks__listing-page'}
      filters={<Styled.FiltersLoader {...getDataTestId('filters')}>{Loader}</Styled.FiltersLoader>}
      list={<Styled.ListLoader {...getDataTestId('list')}>{Loader}</Styled.ListLoader>}
    />
  );
};

TasksList.displayName = 'TasksListingPageSkeleton';

export default TasksListingPageSkeleton;
