import { AutoTooltip, useModal, useSidebar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { EAppSettingsType } from '../../../../../..';
import SeasonAccordeon from '../../../../../shared/components/SeasonAccordeon/SeasonAccordeon';
import { useStore } from '../../../../../shared/utils/IoC';
import { SeasonsController } from '../../../../controllers/seasons/seasons.controller';
import { DashboardRoutes } from '../../../../dashboard.routes';
import { ProfileController } from '../../../../modules/profile/controllers/profile.controller';
import { ReactComponent as CalendarSvg } from '../../../../static/calendar.svg';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { UiStore } from '../../../../stores/ui.store';
import { SidebarController } from '../../sidebar.controller';
import { EDIT_SEASON_MODAL_ID } from '../../../../modules/seasons/modals/EditSeasonModal/EditSeasonModal.config';
import { CREATE_SEASON_MODAL_ID } from '../../../../modules/seasons/modals/CreateSeasonModal/CreateSeasonModal.config';
import { useOperationsParamsActions } from '../../../../modules/operations/hooks/useOperationsParamsActions';

import Styled from './style';

interface ISeasonsAccordeonProps {
  expanded?: boolean;
  slug?: string;
  disabled?: boolean;
  customDataTestId?: string;
}

export const SeasonsAccordeon: FC<ISeasonsAccordeonProps> = observer(
  ({ expanded, disabled: isDisabled, customDataTestId }) => {
    const seasonsController = useStore(SeasonsController);
    const { setSelectedSeason } = seasonsController;
    const seasons = useStore(SeasonsStore);
    const profileController = useStore(ProfileController);
    const { activeDropdown, setActiveDropdown } = useStore(SidebarController);
    const organization = useStore(OrganizationsStore);
    const { isEditSeasonModalOpen, setEditSeason } = useStore(UiStore);

    const { expandSidebar, setInBackground } = useSidebar();
    const { seasonsOptions, selectedSeason } = seasons;
    const navigate = useNavigate();
    const { openModalByModalId } = useModal();

    const { season: urlSeason, fieldId } = useParams<{ season: string; fieldId: string }>();

    const location = useLocation();
    const operationsParamsActions = useOperationsParamsActions();

    const handleSelectSeason = async (season: string) => {
      if (location.pathname.includes('operations')) {
        operationsParamsActions.clearParams();
      }

      profileController.setMultipleAppSettings({
        [EAppSettingsType.Season]: season,
        [EAppSettingsType.Organization]: organization.selectedOrganizationId,
      });

      await setSelectedSeason(season);

      setActiveDropdown(null);
      if (Boolean(urlSeason) && Boolean(fieldId)) {
        const newRoute = generatePath(DashboardRoutes.Fields, {
          orgId: organization.selectedOrganizationId,
        });
        navigate(newRoute);
      }
    };

    const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

    useEffect(() => {
      setInBackground(false);
    }, [isCreationModalOpen, isEditSeasonModalOpen]);

    const seasonTooltip = useMemo(
      () => seasonsOptions?.find(season => season.value === selectedSeason)?.label,
      [selectedSeason, seasonsOptions]
    );

    return expanded ? (
      <Styled.Wrapper $disabled={isDisabled}>
        <SeasonAccordeon
          onMouseOut={() => 1}
          options={seasonsOptions}
          value={selectedSeason}
          onCreate={() => openModalByModalId(CREATE_SEASON_MODAL_ID)}
          onChange={handleSelectSeason}
          dataTestId={customDataTestId}
          placeholder="Сезоны"
          isOpen={!isDisabled && activeDropdown === 'seasons'}
          onHover={() => 1}
          onOpen={() => setActiveDropdown('seasons')}
          onEdit={() => openModalByModalId(EDIT_SEASON_MODAL_ID)}
          disabled={isDisabled}
        />
      </Styled.Wrapper>
    ) : (
      <AutoTooltip disabled={!seasonTooltip} content={`Сезон ${seasonTooltip}`} position="right">
        <Styled.CalendarSmallBlock
          id="seasonShortIcon"
          data-test-id={`${customDataTestId}-short-icon`}
          onClick={() => {
            expandSidebar();
            // Задержка для отрабатывания анимации
            setTimeout(() => setActiveDropdown('seasons'), 360);
          }}
          $isDisabled={isDisabled}
        >
          <CalendarSvg />
        </Styled.CalendarSmallBlock>
      </AutoTooltip>
    );
  }
);
