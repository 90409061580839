import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import moment from 'moment';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { useTasksParams } from '../../../../../../hooks';
import { checkIfDateIsInTheRange } from '../../../../../../../../../shared/utils/helpers/checkIfDateIsInTheRange';
import { FullscreenContext } from '../../../../../../containers/fullscreen';
import { TASK_COMMON_DATA_FORM_REQUIRED_TEXT } from '../../constants';

const OperationSelect: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const bodyForceCloseOptions = useContext(FullscreenContext);

  const params = useTasksParams();

  const isCreate = taskStore.isCreateMultiple || taskStore.isCreateOne;

  const hasEmptyCultureId = isCreate ? !taskStore.taskCreate?.cultureId : false;

  const checkErrors = () => {
    if (taskStore.isCreateOne) {
      return taskStore.taskCreateOneSelectsWithErrors[0] === 'operationId';
    }

    if (taskStore.isCreateMultiple) {
      return taskStore.taskCreateGroupSelectsWithErrors[0] === 'operationId';
    }
  };

  const isBlocked = !isCreate || taskStore.isEditMode || hasEmptyCultureId;

  useEffect(() => {
    (async () => {
      if (isCreate && taskStore.taskCreate?.operationId) {
        await taskController.createListOfZoneExtendedByFieldAfterOperationChange(params.orgId);
      }
    })();
  }, [taskStore.taskCreate?.operationId, isCreate]);

  const optionList = useMemo(() => {
    const bindingDate = taskStore.taskCreate?.bindingDate;

    if (bindingDate) {
      return taskStore.operationOptionList.filter(({ initialModel }) => {
        return checkIfDateIsInTheRange(bindingDate, initialModel.startDate, initialModel.endDate);
      });
    } else {
      return taskStore.operationOptionList;
    }
  }, [taskStore.operationOptionList, taskStore.taskCreate?.bindingDate]);

  const handleChange = useCallback((operationId: string) => {
    taskController.changeOperation(operationId ?? '');
  }, []);

  const dropdownLabelDate = taskStore.taskCreate?.bindingDate
    ? `(для ${moment(taskStore.taskCreate?.bindingDate).format('D MMMM')})`
    : '';

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      placeholder: 'Выберите операцию',
      /**
       * Костыль. В связи с особенностью работы компонента, нужно передавать массив в качестве
       * дефолтного значения. В противном случае не будет сбрасываться выбранное значение.
       */
      defaultValueList: taskStore.operationDefaultValue,
      isRequired: true,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
      },
    },
    body: {
      optionList,
    },
    visual: {
      label: `Операция ${dropdownLabelDate}`,
      isBlocked,
    },
    validation: {
      error: {
        errorList: checkErrors() ? [TASK_COMMON_DATA_FORM_REQUIRED_TEXT] : null,
      },
    },
    other: {
      dataTestId: 'task-operation-select',
    },
  };

  return <NewDropdown bodyForceCloseOptions={bodyForceCloseOptions} config={dropdownConfig} />;
};

OperationSelect.displayName = 'OperationSelect';

export default observer(OperationSelect);
