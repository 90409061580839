import { FC, memo } from 'react';

import userSvg from '../../../../../../operations/components/TaskView/assets/userSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  assigneeName: string;
  dataTestId: string;
}
const TasksDrawerTaskAssignee: FC<IProps> = ({ assigneeName, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__task-assignee`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label dataTestId={getDataTestId('label')['data-test-id']}>Исполнитель</Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={userSvg} />

      <Styled.Info dataTestId={getDataTestId('info')['data-test-id']}>{assigneeName}</Styled.Info>
    </Styled.InfoBlock>
  );
};

TasksDrawerTaskAssignee.displayName = 'TasksDrawerTaskAssignee';

export default memo(TasksDrawerTaskAssignee);
