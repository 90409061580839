import _ from 'lodash';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../stores';
import {
  createCultureSelectOptionList,
  createOperationSelectOptionList,
} from '../../../../../../../../shared/utils/helpers/selectOptions';
import {
  OperationsService,
  SeasonsService,
  TasksService,
  FieldsApiService,
} from '../../../../../../../../shared/mobx/services/as-fields';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import { TypeApiRequest, TypeApiResponse } from '../../../../../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { TaskOperationConfigsService as ConfigsService } from '../../../../../../../../shared/mobx/services/as-fields/TaskOperationConfigsService';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { DictionaryService } from '../../../../../../../../shared/mobx/services/da-dictionary';
import { ETaskType } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { IDictionaryEntity } from '../../../../../../../../../api/models/da-dictionary/DictionaryEntity';
import { toDouble } from '../../../../../mobx/stores/TaskStore/Task.store';
import { IRequestStatusActions } from '../../../../../../../../../api/models/common/request';

@provide.transient()
class TaskOperationCreateApiService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(OrganizationsStore)
  private organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(SeasonsService)
  protected seasonsService: SeasonsService;

  @lazyInject(OperationsService)
  protected operationsService: OperationsService;

  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(FieldsApiService)
  protected fieldsApiService: FieldsApiService;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  public getCultureOptionList = async (): Promise<void> => {
    const payload: TypeApiRequest<'getSeasonCulture'> = {
      year: Number(this.seasonsStore.selectedSeason),
    };

    const hasOrganization: boolean =
      this.organizationsStore.selectedOrganizationId &&
      this.organizationsStore.selectedOrganizationId !== 'my';

    if (hasOrganization) payload.organizationId = this.organizationsStore.selectedOrganizationId;

    const response = await this.seasonsService.getCultureList(payload);

    if (!response?.cultureAndVarietyList) return;

    const cultureList = response.cultureAndVarietyList.map(({ culture }) => culture);
    const cultureOptionList = createCultureSelectOptionList(cultureList as any);

    this.store.setCultureOptionList(cultureOptionList);
  };

  public getOperationOptionList = async (): Promise<void> => {
    const payload: TypeApiRequest<'getOperations'> = {
      seasonYear: Number(this.seasonsStore.selectedSeason),
      cultureId: this.store.taskCreate?.cultureId,
    };

    const hasOrganization: boolean =
      this.organizationsStore.selectedOrganizationId &&
      this.organizationsStore.selectedOrganizationId !== 'my';

    if (hasOrganization) payload.organizationId = this.organizationsStore.selectedOrganizationId;

    const response = await this.operationsService.getOperationList(payload);

    if (!response) return;

    const optionList = createOperationSelectOptionList(response, { isAddInitialModel: true });

    this.store.setOperationOptionList(optionList);
  };

  public getConfig = async (operationTypeId: string): Promise<void> => {
    const response = await this.configsService.getTaskOperationConfigList({
      operationDictionaryValueId: operationTypeId,
    });

    if (!response) return;

    const appropriateConfig = response.find(({ taskType }) => taskType === ETaskType.Operation);

    if (!appropriateConfig) return;

    this.store.setConfig(appropriateConfig);

    void this.getConfigOptionList(appropriateConfig.dictionaryLink);
  };

  public getConfigOptionList = async (dictionaryLink: string): Promise<void> => {
    const response = await this.dictionaryService.getDictionaryEntityList({
      latestVersion: true,
      remoteName: dictionaryLink,
      fetchAttributes: true,
      originalOnly: true,
      dependencyRecords: [
        {
          dependencyName: 'techOperationType',
          dependencyRecordId: this.store.config.operationDictionaryValueId,
          directDependencies: false,
        },
      ],
    });

    if (!response?.content) return;

    const optionList = response.content.map<ISelectOption<IDictionaryEntity>>(e => ({
      value: e.id,
      label: e.name,
      initialModel: e,
    }));

    this.store.setConfigOptionList(optionList);
  };

  public getFieldOptionList = async (): Promise<void> => {
    const payload: TypeApiRequest<'getCultureZoneListBySto'> = {
      seasonYear: Number(this.seasonsStore.selectedSeason),
      cultureId: this.store.taskCreate?.cultureId,
      operationId: this.store.taskCreate?.operationId,
    };

    if (
      this.organizationsStore.selectedOrganizationId &&
      this.organizationsStore.selectedOrganizationId !== 'my'
    ) {
      payload.organizationId = this.organizationsStore.selectedOrganizationId;
    }

    const response = await this.fieldsApiService.getCultureZoneListBySto(payload);

    if (!response || !response.content) {
      return;
    }

    const sortedResponse = _.sortBy(response.content, [
      cultureZone => cultureZone.fieldName?.toLowerCase(),
      cultureZone => cultureZone.name?.toLowerCase(),
      'area',
    ]);

    const optionList = sortedResponse.map<ISelectOption>(cultureZone => {
      const labelData = [
        cultureZone.fieldName,
        cultureZone.name,
        `${toDouble(cultureZone.area)} га`,
      ];

      const formattedLabel = labelData.filter(Boolean).join(', ');

      return {
        value: cultureZone.id,
        label: formattedLabel,
        initialModel: cultureZone,
      };
    });

    this.store.setFieldOptionList(optionList);
  };

  public createTaskOperation = async (
    actions: IRequestStatusActions<TypeApiResponse<'createTaskList'>>
  ): Promise<void> => {
    const payload = this.store.taskCreate;

    if (!this.store.config) {
      delete payload.operationValues;
    }

    await this.tasksService.createTaskList(payload, {
      actions: {
        ...actions,
        handleSuccess: response => {
          this.store.clearHasChanges();
          actions.handleSuccess(response);
        },
      },
    });
  };
}

export default TaskOperationCreateApiService;
