import styled from 'styled-components';
import { Button, Colors } from '@farmlink/farmik-ui';

const Title = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: ${Colors.generalDark};
  margin-left: 22px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleButton = styled(Button)`
  width: 160px;
  height: 40px;
  border-radius: 12px;
`;

const StyledTaskCreateGroup = {
  Title,
  TitleWrapper,
  TitleButton,
};

export default StyledTaskCreateGroup;
