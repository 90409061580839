import { EIconButtonSize, IconButton } from '@farmlink/farmik-ui';
import useOnclickOutside from 'react-cool-onclickoutside';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import arrSize from '../../../../../../../../../../../shared/utils/arraySize';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../../../../../mobx';

import CulturesLine from './components/CulturesLine/CulturesLine';
import CulturesList from './components/CulturesList/CulturesList';
import Skeleton from './components/Skeleton/Skeleton';
import useComputedData from './hooks/useComputedData';
import Styled from './CulturesBar.styles';

interface IProps {
  isLoading: boolean;
}

const ROW_HEIGHT = 28;

const FieldsListCulturesBar: FC<IProps> = props => {
  // Сторы
  const store = useStore(Store);

  const getDataTestId = useDataTestIdV2('fields-list-cultures-bar');

  const data = useComputedData(store.filterManager?.filteredList);
  const ref = useOnclickOutside(() => {
    store.isOpenLegend = false;
  });

  const toggle = () => {
    store.isOpenLegend = !store.isOpenLegend;
  };

  const getListHeight = () => {
    const itemsCount = arrSize(data.culturesList, 6);
    const otherContentSumHeight = 64;

    return ROW_HEIGHT * itemsCount + otherContentSumHeight;
  };

  if (props.isLoading) {
    return <Skeleton getDataTestId={getDataTestId} />;
  }

  return (
    <Styled.Wrapper ref={ref} {...getDataTestId('wrapper')}>
      <Styled.BackBlock />

      <Styled.LegendWrapper
        isOpen={store.isOpenLegend}
        $openedHeight={getListHeight()}
        onClick={toggle}
      >
        <Styled.Header>
          <Styled.HeaderCaption>
            <Styled.Title {...getDataTestId('title')}>
              Поля: {data.generalStats.count} / {data.generalStats.area.toFixed(0)} га
            </Styled.Title>

            <Styled.ArrowIconWrapper isOpen={store.isOpenLegend}>
              <IconButton
                icon={'arrow_down'}
                size={EIconButtonSize.x12}
                colorScheme={Styled.ArrowBtnColorScheme}
              />
            </Styled.ArrowIconWrapper>
          </Styled.HeaderCaption>

          <CulturesLine culturesList={data.culturesList} getDataTestId={getDataTestId} />
        </Styled.Header>

        {store.isOpenLegend && (
          <CulturesList
            list={data.culturesList}
            rowHeight={ROW_HEIGHT}
            getDataTestId={getDataTestId}
          />
        )}
      </Styled.LegendWrapper>
    </Styled.Wrapper>
  );
};

export default observer(FieldsListCulturesBar);
