import { debounce, isEqual } from 'lodash';
import { useCallback } from 'react';

import { FieldAreaFilter } from '../../../../../../../../../utils';
import { IMinMaxRange } from '../../../../../../../../../utils/filters/FieldArea.filter';

type TUpdateInputFn = (transformedValue: IMinMaxRange) => void;

/**
 * Возвращает debounced функцию которая валидирует значение площади и сетает валидное значение в фильтр
 */
function useChangeFilterFn(filter: FieldAreaFilter, debounceMs?: number) {
  const toNumber = (value: IMinMaxRange<string | number>) => {
    return {
      min: Number(value.min || 0),
      max: Number(value.max || 0),
    };
  };

  /**
   * Трансформируем значения
   *
   * @param value
   * @param minMaxRange - допустимый диапазон из фильтра
   */
  const transformValue = (value: IMinMaxRange, minMaxRange: IMinMaxRange) => {
    const { min, max } = value;

    // Проверяем чтобы значения не выходили за допустимые пределы. Если выходят - выставляем пограничные значения
    const minInBounds = min < minMaxRange.min ? minMaxRange.min : min;
    const maxInBounds = max > minMaxRange.max ? minMaxRange.max : max || minMaxRange.max;

    /**
     * Проверяем что минимальное значение меньше максимального и наоборот.
     * Т.к в этой функции нет данных о том какой из инпутов изменяется, то по дефолту подстраиваться будет инпут минимального значения
     * @example 1
     *  min = 20
     *  max = 10 - изменили это значение
     *
     *  result: min = 10, max = 10
     *
     * @example 2
     *   min = 60 - изменили это значение
     *   max = 40
     *
     *   result: min = 40, max = 40
     */
    const validMin = minInBounds > maxInBounds ? maxInBounds : minInBounds;
    const validMax = maxInBounds < minInBounds ? validMin : maxInBounds;

    return { min: validMin, max: validMax };
  };

  return useCallback(
    debounce((value: IMinMaxRange<string | number>, inputValueUpdater: TUpdateInputFn) => {
      const numberValue = toNumber(value);
      const transformedValue = transformValue(numberValue, filter?.minMaxRange);

      // Меняем значение инпута в случае если трансформированное значение отличается от текущего
      if (!isEqual(numberValue, transformedValue)) {
        inputValueUpdater(transformedValue);
      }

      if (!filter) {
        return;
      }

      filter.setValue(transformedValue);
    }, debounceMs ?? 500),
    [filter, filter?.minMaxRange, debounceMs]
  );
}

export default useChangeFilterFn;
