import { chunk, flowRight, isArray, max, mean, min } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  EChecklistAttributeType as EAttrType,
  IChecklistAverageAttrRow,
  IChecklistAverageValue,
  IExtendedAverageValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeValue,
  IPutChecklistAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
} from '../../../../models';

type TAttrToDraw<T extends EAttrType = EAttrType.Int> = T extends EAttrType.Int
  ? TIntegerAttrToDraw
  : TDoubleAttrToDraw;
type TRow = IChecklistAverageAttrRow;
type TValue = IExtendedAverageValue;
type TAverageData = TAttrToDraw['options']['averageData'];
type TReturnValue<T extends EAttrType = EAttrType.Int> = Pick<TAttrToDraw<T>, 'value'> & {
  averageData: TAverageData;
};

// Проверяет, есть ли значение с длинным названием.
function checkIfHasLongVal(valList: TValue[]): boolean {
  return valList.some(checkIfValueIsLong);
}

// Проверяет, длинное ли значение.
function checkIfValueIsLong({ value }: TValue): boolean {
  return String(value).length > 4;
}

// Отфильтровывает список значений перед отрисовкой.
function filterValListToShow(valList: TValue[]): TValue[] {
  return valList.filter(({ culled }) => !culled);
}

// Проверяет, заполнено ли значение.
function checkIfHasFilledValue({ value }: TValue): boolean {
  return value !== '';
}

// Возвращает массив заполненных значений.
function getFilledValueList(valueList: TValue[]): TValue[] {
  return valueList.filter(checkIfHasFilledValue);
}

// Делит список значений на равные группы из 8 элементов.
function divideIntoLongGroups(valueList: IExtendedAverageValue[]): IExtendedAverageValue[][] {
  return chunk(valueList, 8);
}

// Очищает список значений.
function clearValueList(valueList: TValue[]): TValue[] {
  return valueList.map(el => ({ ...el, value: '' }));
}

// Получаем список значений согласно айди ряда.
function getValueListByRowId(valueList: TValue[], rowId: string): TValue[] {
  return valueList.filter(value => value.rowId === rowId);
}

// Получаем значение согласно его айди.
function getValueById(valueList: TValue[], id: string): TValue {
  return valueList.find(value => id === value.id);
}

// Получает массив значений ряда.
const getRowValueList = (valueList: TValue[], isBlocked?: boolean): TValue[] => {
  if (isBlocked) {
    return getFilledValueList(valueList);
  }

  return valueList;
};

// Сортирует список рядов согласно наличию длинного значения.
function sortRowListByLongValue(rowList: TRow[]): TRow[] {
  return [...rowList].sort((a, b) => {
    if (a?.hasLongValue) {
      return -1;
    }

    if (b?.hasLongValue) {
      return 1;
    }

    return 0;
  });
}

// Получает массив значений из всех рядов.
function getWholeValueList(rowList: TRow[]): TValue[] {
  return rowList.reduce<TValue[]>((valueList, row) => {
    valueList.push(...row.valueList);

    return valueList;
  }, []);
}

// Получает массив значений для выбраковки из всех рядов.
function getValueListToCull(rowList: TRow[]): TValue[] {
  return getFilledValueList(getWholeValueList(rowList));
}

// Получает список выбракованных значений.
function getCulledValueList(valueList: TValue[]): TValue[] {
  return valueList.filter(({ culled }) => culled);
}

// Получает первое пустой значение.
function getEmptyValue(valueList: TValue[]): TValue {
  return valueList.find(({ value }) => value === '');
}

// Группирует значения для создания рядов.
function createGroupListForRows(initialGroupList: TValue[][]): TValue[][] {
  const tempGroupList = initialGroupList.map(list => {
    const hasLongVal = checkIfHasLongVal(list);

    if (hasLongVal) {
      return chunk(list, 4);
    }

    return list;
  });

  return tempGroupList.reduce<TValue[][]>((groupList, group) => {
    const isTwoDimensional = group.some(el => isArray(el));

    if (isTwoDimensional) {
      group.forEach(list => groupList.push(list));
    } else {
      groupList.push(group as TValue[]);
    }

    return groupList;
  }, []);
}

// Заполняет ряд недостающими значениями.
function fillRow(valueList: TValue[], rowId: string): TValue[] {
  const hasLongValue = checkIfHasLongVal(valueList);
  const maxLength = hasLongValue ? 4 : 8;
  const numberOfValuesToAdd = maxLength - valueList.length;

  if (numberOfValuesToAdd) {
    const emptyValueList = addEmptyValueList([], { amount: numberOfValuesToAdd, rowId });

    return emptyValueList;
  }

  return [];
}

// Добавляет айдишник ряда к списку значений.
function addRowIdToValueList(valueList: TValue[], rowId: string): TValue[] {
  return valueList.map(value => ({ ...value, rowId }));
}

// Создает ряд.
const createRow = (valueList: TValue[]): TRow => {
  const rowId = uuid();

  const listToFill = fillRow(valueList, rowId);
  const rowValueList = [...valueList, ...listToFill];

  return {
    id: rowId,
    valueList: addRowIdToValueList(rowValueList, rowId),
    hasLongValue: checkIfHasLongVal(rowValueList),
  };
};

// Создает массив рядов.
function createRowList(valueList: TValue[]): TRow[] {
  const composeGroupListCreating = flowRight(
    createGroupListForRows,
    divideIntoLongGroups,
    filterValListToShow
  );

  const groupList = composeGroupListCreating(valueList);

  return groupList.reduce<TRow[]>((rowList, group) => {
    const row = createRow(group);

    rowList.push(row);

    return rowList;
  }, []);
}

// Получает список чисел из модели "значения".
const getNumList = (valueList: TValue[]): number[] => {
  return valueList.reduce<number[]>((numList, { value, culled }) => {
    if (value !== '' && !culled) {
      numList.push(Number(value));
    }

    return numList;
  }, []);
};

// Преобразует список значений в необходимый формат для отправки на бэк.
const getFormattedValueList = (extendedValueList: TValue[]): IChecklistAverageValue[] => {
  return extendedValueList.reduce<IChecklistAverageValue[]>((valueList, { value, culled }) => {
    if (value !== '') {
      valueList.push({
        value,
        culled,
      });
    }

    return valueList;
  }, []);
};

// Возвращает модель, которая необходима для обновления атрибута на фронте.
const getPartialValue = <T extends EAttrType>(
  { initialModel }: TAttrToDraw<T>,
  valueList: TValue[]
): Partial<IPutChecklistAttributeValue> => {
  switch (initialModel.attribute.type) {
    case EAttrType.Int:
      return {
        checkListAttributeId: initialModel.id,
        integerValues: getFormattedValueList(valueList),
      };
    case EAttrType.Double:
      return {
        checkListAttributeId: initialModel.id,
        doubleValues: getFormattedValueList(valueList),
      };
    default:
      return {};
  }
};

// Считает среднее значение.
function calculateAverage({
  numList,
  isNeedToBeRounded,
  precision,
}: {
  numList: number[];
  isNeedToBeRounded?: boolean;
  precision?: number;
}): string | number {
  if (!numList?.length) {
    return '';
  }

  const average = mean(numList);

  if (isNeedToBeRounded) {
    return Math.round(average);
  }

  return average.toFixed(precision);
}

// Проверяет нужно ли округлять значение.
function checkIfValueNeedsToBeRounded(type: EAttrType): boolean {
  return type === EAttrType.Int;
}

// Добавляет пустые значения для ряда.
function addEmptyValueList(
  valueList: TValue[],
  config?: {
    amount?: number;
    rowId?: string;
    where?: 'before' | 'after';
  }
): TValue[] {
  const newValueList: TValue[] = [];

  const amount = config?.amount ?? 4;
  const where = config?.where || 'after';

  for (let i = 0; i < amount; i++) {
    newValueList.push({
      id: uuid(),
      value: '',
      culled: false,
      rowId: config?.rowId,
    });
  }

  if (where === 'before') {
    return [...newValueList, ...valueList];
  } else {
    return [...valueList, ...newValueList];
  }
}

// Создает данные для атрибута среднего значения.
function createAverageAttrData(
  attr: IGetChecklistAttribute,
  attrVal?: IGetChecklistAttributeValue | IPutChecklistAttributeValue
): TAverageData {
  const valueList = attrVal?.integerValues ?? attrVal?.doubleValues ?? [];

  const extendedValueList = valueList.map<TValue>(el => ({
    ...el,
    id: uuid(),
    rowId: null,
  }));

  const hasNotCulledValue = extendedValueList.some(({ culled }) => !culled);

  const availableValueList = hasNotCulledValue
    ? extendedValueList
    : addEmptyValueList(extendedValueList, { amount: 8 });

  const numList = getNumList(availableValueList);
  const isNeedToBeRounded = checkIfValueNeedsToBeRounded(attr.attribute.type);

  return {
    average: calculateAverage({ numList, isNeedToBeRounded, precision: attr.attribute?.precision }),
    min: min(numList) ?? '',
    max: max(numList) ?? '',
    rowList: createRowList(availableValueList),
    culledValueList: getCulledValueList(extendedValueList),
  };
}

// Обновляет атрибут среднего значения, т.е. пересчитывает все данные и возвращает обновленный конфиг.
function updateChecklistAttrValue<T extends EAttrType>(attrToDraw: TAttrToDraw<T>) {
  return (changeAverageData: TAverageData): TReturnValue<T> => {
    const valueList = getWholeValueList(changeAverageData.rowList);

    const numList = getNumList(valueList);
    const isNeedToBeRounded = checkIfValueNeedsToBeRounded(attrToDraw.initialModel.attribute.type);

    const readyData: TAverageData = {
      ...changeAverageData,
      average: calculateAverage({
        numList,
        isNeedToBeRounded,
        precision: attrToDraw.initialModel.attribute?.precision,
      }),
      min: min(numList) ?? '',
      max: max(numList) ?? '',
    };

    return {
      value: getPartialValue<T>(attrToDraw, [...valueList, ...changeAverageData.culledValueList]),
      averageData: readyData,
    } as TReturnValue<T>;
  };
}

// Заменяет значение из ряда на отредактированное.
function changeValues<T extends EAttrType>(
  attrToDraw: TAttrToDraw<T>,
  changedValueList: TValue[]
): TReturnValue<T> {
  const compose = flowRight(
    updateChecklistAttrValue(attrToDraw),
    getAverageAttrDtaWithChangedValue
  );

  return compose(attrToDraw.options.averageData, changedValueList);
}

// Заменяет значение ряда на новое.
function changeRowValueList(row: TRow, changedValueList: TValue[]): TRow {
  const listWithChangedValue = row.valueList.map(value => {
    const changedValue = getValueById(changedValueList, value.id);

    if (changedValue) {
      return changedValue;
    }

    return value;
  });

  const hasLongValue = checkIfHasLongVal(listWithChangedValue);
  const listLength = listWithChangedValue.length;

  if (!hasLongValue && listLength !== 8) {
    const updatedValueList = addEmptyValueList(listWithChangedValue, { rowId: row.id });

    return {
      ...row,
      hasLongValue,
      valueList: updatedValueList,
    };
  }

  return {
    ...row,
    hasLongValue,
    valueList: listWithChangedValue,
  };
}

// Заменяет отредактированное значение.
function getAverageAttrDtaWithChangedValue(
  averageData: TAverageData,
  changedValueList: TValue[]
): TAverageData {
  const rowById: Map<string, TRow> = new Map<string, TRow>();

  averageData.rowList.forEach(row => {
    const changedListOfThisRow = getValueListByRowId(changedValueList, row.id);

    if (changedListOfThisRow.length) {
      const changedRow = changeRowValueList(row, changedListOfThisRow);

      if (changedRow?.hasLongValue) {
        const tempSmallGroupList = chunk(changedRow.valueList, 4);
        const smallGroupList =
          tempSmallGroupList.length === 1 ? [...tempSmallGroupList, []] : tempSmallGroupList;

        const [firstGroup, lastGroup] = smallGroupList;

        const isFirstGroupHasFilledValue = firstGroup.some(checkIfHasFilledValue);
        const isLastGroupHasFilledValue = lastGroup.some(checkIfHasFilledValue);

        const isFirstGroupHasLongValue = checkIfHasLongVal(firstGroup);
        const isLastGroupHasLongValue = checkIfHasLongVal(lastGroup);

        const isBothHaveFilledValue = isFirstGroupHasFilledValue && isLastGroupHasFilledValue;

        const updateRowByGroup = (group: TValue[]): void => {
          const updatedRow: TRow = { ...changedRow, valueList: group };
          rowById.set(updatedRow.id, updatedRow);
        };

        const createNewRowWithGroup = (group: TValue[]): void => {
          const createdRow = createRow(group);
          rowById.set(createdRow.id, createdRow);
        };

        if (isBothHaveFilledValue) {
          if (isFirstGroupHasLongValue) {
            updateRowByGroup(firstGroup);
            createNewRowWithGroup(lastGroup);

            return;
          }

          if (isLastGroupHasLongValue) {
            createNewRowWithGroup(firstGroup);
            updateRowByGroup(lastGroup);

            return;
          }
        }

        if (isFirstGroupHasFilledValue) {
          updateRowByGroup(firstGroup);

          return;
        }

        if (isLastGroupHasFilledValue) {
          updateRowByGroup(lastGroup);

          return;
        }
      }

      rowById.set(changedRow.id, changedRow);
    } else {
      rowById.set(row.id, row);
    }
  });

  return { ...averageData, rowList: [...rowById.values()] };
}

// Добавляет новый ряд и делает перерасчет данных.
function addRow<T extends EAttrType>(attrToDraw: TAttrToDraw<T>): TReturnValue<T> {
  const compose = flowRight(updateChecklistAttrValue(attrToDraw), getAverageAttrDataWithAddedRow);

  return compose(attrToDraw.options.averageData);
}

// Добавляет новый ряд.
function getAverageAttrDataWithAddedRow(averageData: TAverageData): TAverageData {
  const newRow = createRow([]);

  return {
    ...averageData,
    rowList: [...averageData.rowList, newRow],
  };
}

// Удаляет ряд и делает перерасчет данных.
function deleteRow<T extends EAttrType>(
  attrToDraw: TAttrToDraw<T>,
  rowId: string
): TReturnValue<T> {
  const compose = flowRight(
    updateChecklistAttrValue(attrToDraw),
    getAverageAttrDataWithoutDeletedRow
  );

  return compose(attrToDraw.options.averageData, rowId);
}

// Удаляет ряд.
function getAverageAttrDataWithoutDeletedRow(
  averageData: TAverageData,
  rowId: string
): TAverageData {
  const rowListWithoutDeleted = averageData.rowList.filter(({ id }) => id !== rowId);

  return {
    ...averageData,
    rowList: rowListWithoutDeleted,
  };
}

// Выбраковывает значениея.
function cullValues<T extends EAttrType>(
  attrToDraw: TAttrToDraw<T>,
  valueListToCull: TValue[]
): TReturnValue<T> {
  const dataWithChangedValues = getAverageAttrDtaWithChangedValue(
    attrToDraw.options.averageData,
    clearValueList(valueListToCull)
  );

  const culledValueList = valueListToCull.map(value => ({ ...value, culled: true }));

  const dataWithUpdatedCulledValues = {
    ...dataWithChangedValues,
    culledValueList: [...dataWithChangedValues.culledValueList, ...culledValueList],
  };

  return updateChecklistAttrValue(attrToDraw)(dataWithUpdatedCulledValues);
}

// Удаляет выбракованное значение.
function deleteCulledValue<T extends EAttrType>(
  attrToDraw: TAttrToDraw<T>,
  culledValue: TValue
): TReturnValue<T> {
  const rowList = checkIfValueIsLong(culledValue)
    ? sortRowListByLongValue(attrToDraw.options.averageData.rowList)
    : attrToDraw.options.averageData.rowList;

  const wholeValueList = getWholeValueList(rowList);
  const emptyValue = getEmptyValue(wholeValueList);

  const culledValueListWithoutDeleted = attrToDraw.options.averageData.culledValueList.filter(
    ({ id }) => id !== culledValue.id
  );

  const dataWithUpdatedCulledValues = {
    ...attrToDraw.options.averageData,
    culledValueList: culledValueListWithoutDeleted,
  };

  if (emptyValue) {
    const changedEmptyValue: TValue = { ...emptyValue, culled: false, value: culledValue.value };

    const dataWithChangedValues = getAverageAttrDtaWithChangedValue(dataWithUpdatedCulledValues, [
      changedEmptyValue,
    ]);

    return updateChecklistAttrValue<T>(attrToDraw)(dataWithChangedValues);
  }

  const newRow = createRow([{ ...culledValue, culled: false }]);

  const dataWithAddedRow = {
    ...dataWithUpdatedCulledValues,
    rowList: [...dataWithUpdatedCulledValues.rowList, newRow],
  };

  return updateChecklistAttrValue<T>(attrToDraw)(dataWithAddedRow);
}

const isAverageChecklistAttr = (attr: IGetChecklistAttribute['attribute']): boolean => {
  const isMultiselect = attr?.isMultiselect;
  const hasAverageType = attr?.calculationType === 'AVERAGE';

  return isMultiselect && hasAverageType;
};

const AverageAttributeHelpers = {
  getPartialValue,
  getRowValueList,
  createAverageAttrData,
  changeValues,
  addRow,
  deleteRow,
  getFilledValueList,
  getValueListToCull,
  cullValues,
  deleteCulledValue,
  isAverageChecklistAttr,
};

export default AverageAttributeHelpers;
