import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OperationsStore } from '../stores/operations.store';
import { Task, taskLabels, TasksStore, TaskStatuses } from '../stores/tasks.store';
import { ETaskType } from '../../../../../api/models/as-fields/task/task.model';

@provide.singleton()
export class TasksListingController {
  @lazyInject(OperationsStore)
  operationsStore: OperationsStore;

  @lazyInject(TasksStore)
  tasksStore: TasksStore;

  public getDateToTasks = (): any => {
    const result = {};

    const sortedTasksArray = this.sortTasksByType(Array.from(this.tasksStore.tasksMap?.values()));

    sortedTasksArray.forEach(task => {
      const preparedTask = {
        ...task,
        statusLabel: this.getStatusLabel(task.status),
        cultureName: task?.culture?.name || 'Культура не указана',
      };

      if (result[task.bindingDate as string]) {
        result[task.bindingDate as string].push(preparedTask);
      } else {
        result[task.bindingDate as string] = [preparedTask];
      }
    });

    return result;
  };

  protected sortTasksByType = (tasks: Task[]): Task[] => {
    return tasks.sort((a, b) => (a.type === ETaskType.Monitoring ? -1 : 1));
  };

  protected getStatusLabel(status: TaskStatuses): string {
    return taskLabels[status] || 'нет данных';
  }
}
