import { useCallback } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';

function useInputHint(inputValue: string, filteredList: Array<string>) {
  return useCallback(() => {
    const safeInputValue = escapeRegExp(inputValue);
    const regexp = new RegExp(`^${safeInputValue}`, 'i');

    return filteredList.find(item => regexp.test(item))?.replace(regexp, inputValue);
  }, [inputValue, filteredList]);
}

export default useInputHint;
