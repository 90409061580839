import { ChangeEvent, forwardRef, memo, useMemo, useState } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { Tooltipster } from '../../../../../../../../../shared/components/Tooltipster/Tooltipster';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './styles';
import { TInputProps } from './types';

const MAXIMAL_LENGTH_DEFAULT = 100;

const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      onChange,
      label,
      isRequired,
      className,
      isDisabled,
      isBlocked,
      value,
      placeholder,
      type,
      error,
      isWithoutErrorText,
      maxLength,
      dataTestId,
      id,
      tooltip,
      onBlur,
      as = 'input',
      isResizable,
      height,
      hideScrollbar,
      Icon,
    },
    ref
  ) => {
    const [isHover, setIsHover] = useState(false);

    const { isShowTooltip, ref: overflowRef } = useShowTooltip<HTMLInputElement>([
      value,
      window.innerWidth,
      isHover,
      ref,
    ]);

    const isDisplayTooltip = useMemo(
      () =>
        isHover &&
        document.activeElement !== overflowRef.current && // check on focus
        isShowTooltip,
      [isHover, isShowTooltip, document.activeElement, overflowRef]
    );

    const setMultipleRef = node => {
      if (overflowRef) overflowRef.current = node;
      // @ts-ignore
      if (ref) ref.current = node;
    };

    const getDataTestId = useDataTestIdV2(dataTestId ?? 'input');

    const IconToDraw = useMemo(() => {
      if (as === 'textarea') return;

      if (Icon) {
        return (
          <Styled.IconWrapper
            {...getDataTestId('icon-wrapper')}
            $isLabel={Boolean(label)}
            $align={'end'}
          >
            {Icon}
          </Styled.IconWrapper>
        );
      }

      if (isBlocked) {
        return (
          <Styled.IconWrapper
            {...getDataTestId('icon-wrapper')}
            $isLabel={Boolean(label)}
            $align={'end'}
          >
            <Styled.IconMinus {...getDataTestId('minus-icon')} />
          </Styled.IconWrapper>
        );
      }
    }, [isBlocked, as, Icon]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      onChange?.(event.target.value.toString());
    };

    return (
      <Styled.Wrapper {...getDataTestId()}>
        <Styled.TopBlock {...getDataTestId('header')}>
          {label && (
            <Styled.LabelWrapper {...getDataTestId('label-wrapper')}>
              <Styled.Label required={isRequired} {...getDataTestId('label')}>
                {label}
              </Styled.Label>

              {tooltip && (
                <>
                  <Tooltipster id={`tooltip-${id}`} title={tooltip} placement={'top-start'}>
                    <Styled.IconInfo {...getDataTestId('info-icon')} />
                  </Tooltipster>
                </>
              )}
            </Styled.LabelWrapper>
          )}
        </Styled.TopBlock>

        <AutoTooltip content={value} disabled={!isDisplayTooltip}>
          <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <Styled.InputField
              className={className}
              disabled={isDisabled || isBlocked}
              value={value}
              placeholder={placeholder}
              type={type}
              onChange={handleChange}
              maxLength={maxLength ? maxLength : MAXIMAL_LENGTH_DEFAULT}
              data-test-id={dataTestId}
              id={id}
              ref={setMultipleRef}
              onBlur={onBlur}
              as={as}
              autoComplete={'off'}
              $error={error}
              $isBlocked={isBlocked}
              $isResizable={isResizable}
              $height={height}
              $hideScrollbar={hideScrollbar}
              $isIconDisplayed={Boolean(IconToDraw)}
            />
          </div>
        </AutoTooltip>

        {IconToDraw}

        {error && !isWithoutErrorText && (
          <Styled.ErrorMessage data-test-id={`${dataTestId}_errorMessage`}>
            {error}
          </Styled.ErrorMessage>
        )}
      </Styled.Wrapper>
    );
  }
);

Input.displayName = 'Input';

export default memo(Input);
