import { Input } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Field } from '../../../../../../../api/models/field.model';
import { toFixedWithCeilBackEnd } from '../../../../../../shared/utils/toFixedWithCeil';
import { EFieldAccessAction } from '../../../../../../shared/constants/access-rules-action';
import useFieldsPermissions from '../../../hooks/useFieldsPermissions';
import { TCreatedField } from '../../../modules/FieldsCreate/types';

import { ReactComponent as MapSvg } from './assets/creation-map-mock.svg';
import Styled from './EditableListItem.styles';

interface IProps {
  field: Field | TCreatedField;
  onInputChange: (value: string) => void;
  onDeleteClick: () => void;
}

const EditableListItem: FC<IProps> = props => {
  const { field } = props;

  const permissions = useFieldsPermissions();

  const getError = () => {
    if (typeof field.error === 'boolean') {
      return field.errorDescription;
    }

    return field.error;
  };

  const getLabel = () => {
    const fixedArea = toFixedWithCeilBackEnd(field.areaInHectare);

    return getError() ? getError() : `Площадь: ${fixedArea} га`;
  };

  return (
    <Styled.Wrapper data-test-id={'fields-creation-props-wrapper'}>
      <Styled.ContentWrapper>
        <Styled.MapIcon data-test-id={'fields-creation-map-icon'}>
          <MapSvg data-test-id={'fields-creation-map-svg'} />
        </Styled.MapIcon>

        <Styled.RightColumn data-test-id={'fields-creation-right-column'}>
          <Input
            value={field.name}
            onChange={props.onInputChange}
            data-test-id={'fields-creation-name'}
          />

          <Styled.BottomSectionWrapper data-test-id={'fields-creation-bottom-section-wrapper'}>
            <Styled.AreaLabel
              isError={Boolean(field.error)}
              data-test-id={'fields-creation-props-area'}
            >
              {getLabel()}
            </Styled.AreaLabel>

            {permissions.hasAccess(EFieldAccessAction.DeleteField) && (
              <Styled.Delete>
                <div onClick={props.onDeleteClick} data-test-id={'fields-creation-props-delete'}>
                  Удалить
                </div>
              </Styled.Delete>
            )}
          </Styled.BottomSectionWrapper>
        </Styled.RightColumn>
      </Styled.ContentWrapper>

      <Styled.Line />
    </Styled.Wrapper>
  );
};

export default observer(EditableListItem);
