import {
  Button,
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo, useState } from 'react';
import L from 'leaflet';

import { useChecklistInstanceActions } from '../../../../../../../operationsAndTasks/components/instance/ListOfChecklistInstance/components/ChecklistInstance/hooks';
import { useTasksParams, useTasksRouteActions } from '../../../../../../hooks';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskController } from '../../../../../../mobx/controllers';
import { TaskStore } from '../../../../../../mobx/stores';
import { ETaskStatus } from '../../../../../../../../../../api/models/as-fields/task/task.model';
import { ETaskTabs } from '../../../../../../models';
import InstanceListActualityService from '../../../../../../../operationsAndTasks/services/instanceList/instanceListActuality.service';
import { NO_CHECKLIST_FOUND_MODAL_ID } from '../../../../../../modals/noChecklistFoundModal';
import {
  ENotificationScheme,
  getNotificationScheme,
} from '../../../../../../../../../shared/utils/helpers/getNotificationScheme';
import { ChecklistsController } from '../../../../../../modules/Checklists/mobx/controllers';
import { ChecklistsStore } from '../../../../../../modules/Checklists/mobx/stores';
import { ChecklistInstancesController } from '../../../../../../../operationsAndTasks/controllers/checklist.instances.controller';
import { InspectionPointsStore } from '../../../../../InspectionPoints/mobx/stores';
import { useContextualLink } from '../../../../../../../../../shared/features/ContextualLink/hooks';
import { TTasksContextualLinkConfig } from '../../../../../../types';
import { EContextualLinkId } from '../../../../../../../../constants/configs';

import Styled from './TaskControlButtons.styles';

type TLinkState = TTasksContextualLinkConfig['from']['state'];

const TaskControlButtons: FC = () => {
  const taskStore = useStore(TaskStore);
  const checklistsStore = useStore(ChecklistsStore);
  const taskController = useStore(TaskController);
  const { deleteReadyToRemoveItems } = useStore(InstanceListActualityService);

  const checklistsController = useStore(ChecklistsController);
  const { saveInstances } = useStore(ChecklistInstancesController);
  const {
    tempLatLngOfEditablePoint,
    setTempLatLngOfEditablePoint,
    selectedPoint,
    pointElementList,
  } = useStore(InspectionPointsStore);

  const [isLongLoading, setIsLongLoading] = useState(false);

  const params = useTasksParams();

  const tasksRouteActions = useTasksRouteActions();
  const notificatorActions = useNotificator();
  const modalActions = useModal();
  const { updateDrawInst } = useChecklistInstanceActions();
  const contextualLink = useContextualLink();

  const isCreating = taskStore.isCreateOne || taskStore.isCreateMultiple;
  const isNotCanceled = taskStore.selectedTask?.status !== ETaskStatus.Canceled;

  const isMapTab = taskStore.taskTab === ETaskTabs.Map;
  const isEditPoint = taskStore.taskTab === ETaskTabs.EditPoint;

  const hasEmptyRequiredFields = useMemo(() => {
    if (!taskStore.taskCreate) {
      return false;
    }

    if (taskStore.isCreateOne) {
      return (
        !taskStore.taskCreate.cultureId ||
        !taskStore.taskCreate.operationId ||
        !taskStore.taskCreate?.cultureZoneId ||
        !taskStore.taskCreate.planStartDate ||
        !taskStore.taskCreate.planEndDate
      );
    }

    if (taskStore.isCreateMultiple) {
      return (
        !taskStore.taskCreate.cultureId ||
        !taskStore.taskCreate.operationId ||
        !taskStore.taskCreate?.taskItems?.length ||
        !taskStore.taskCreate.planStartDate ||
        !taskStore.taskCreate.planEndDate
      );
    }
  }, [taskStore.taskCreate, taskStore.isCreateOne, taskStore.isCreateMultiple]);

  const primaryButtonText = useMemo(() => {
    if (taskStore.previewTaskList.length) {
      return 'Подтвердить ';
    }

    switch (taskStore.mode) {
      case 'create-one':
        return 'Создать задачу';
      case 'create-multiple':
        return 'Создать';
      case 'edit':
        return 'Сохранить';
      case 'edit-point':
        return 'Сохранить новые координаты';
      default:
        return 'Сохранить';
    }
  }, [taskStore.mode, taskStore.previewTaskList]);

  const primaryButtonDisabledState = useMemo(() => {
    if (isCreating && hasEmptyRequiredFields) {
      return true;
    }

    if (isMapTab && !pointElementList.length) {
      return true;
    }

    if (isEditPoint && !tempLatLngOfEditablePoint) {
      return true;
    }

    return false;
  }, [
    isCreating,
    hasEmptyRequiredFields,
    taskStore.taskTab,
    tempLatLngOfEditablePoint,
    pointElementList,
  ]);

  const getSaveMessage = () => {
    if (isMapTab) {
      return 'Точки успешно изменены';
    }

    return 'Изменения успешно сохранены';
  };

  const getOnSaveRedirect = (id: string) => {
    taskStore.setMode('view');

    if (isMapTab) {
      return tasksRouteActions.goToMap(id);
    }

    return tasksRouteActions.goToTask(id);
  };

  const handleSaveClick = () => {
    (async () => {
      await Promise.all(deleteReadyToRemoveItems());

      const isTaskUpdated = await taskController.updateTask();
      const isInstancesSaved = isMapTab
        ? await saveInstances(null, true, taskStore.selectedTask?.id)
        : true;

      if (isTaskUpdated && isInstancesSaved) {
        notificatorActions.setNotification(
          getNotificationScheme(ENotificationScheme.Dark, getSaveMessage())
        );

        getOnSaveRedirect(taskStore.selectedTask?.id);

        if (isMapTab) {
          taskController.fetchTask(taskStore.selectedTask?.id, { isUpdatePermissions: true });
        }
      }
    })();
  };

  const handleCreateTask = async () => {
    taskController.validateCreateOne();

    if (taskStore.taskCreateOneSelectsWithErrors.length) return;

    const createdTask = await taskController.createTask(params.orgId);

    if (createdTask) {
      /**
       * Обновляем состояние контекстного возврата, чтобы при нажатии на кнопку "вернуться к списку",
       * подсветить недавно созданную задачу.
       */
      contextualLink.setStateFrom<TLinkState>(EContextualLinkId.TasksTaskBackButton, {
        taskId: createdTask.id,
        page: 0,
      });

      notificatorActions.setNotification({
        message: 'Задача успешно создана',
        style: {
          type: ENotificationType.Success,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          autoClose: 10000,
          hideProgressBar: false,
          theme: ENotificatorTheme.Dark,
        },
      });

      if (
        createdTask?.checklistsAvailable ||
        createdTask?.checklistsMachineryAvailable ||
        createdTask?.checklistsFieldAvailable
      ) {
        getOnSaveRedirect(createdTask.id);
      } else {
        modalActions.openModalByModalId(NO_CHECKLIST_FOUND_MODAL_ID, null, () => {
          getOnSaveRedirect(createdTask.id);
        });
      }
    } else {
      notificatorActions.setNotification({
        message: `Ошибка создания задачи`,
        style: {
          type: ENotificationType.Warning,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          autoClose: 10000,
          hideProgressBar: false,
          theme: ENotificatorTheme.Dark,
        },
      });
    }
  };

  const handleShowPreviewTasks = useCallback(() => {
    taskController.validateCreateGroup();

    if (taskStore.taskCreateGroupSelectsWithErrors.length) return;

    taskController.createPreviewTasks();
  }, []);

  const handleCreateTaskList = async () => {
    const timeoutId = setTimeout(() => setIsLongLoading(true), 1000);

    const createdTaskList = await taskController.createTaskList();

    clearTimeout(timeoutId);

    if (createdTaskList?.length) {
      /**
       * Обновляем состояние контекстного возврата, чтобы при нажатии на кнопку "вернуться к списку",
       * подсветить недавно созданную задачу.
       */
      contextualLink.setStateFrom<TLinkState>(EContextualLinkId.TasksTaskBackButton, {
        taskId: createdTaskList[0].id,
        page: 0,
      });

      notificatorActions.setNotification({
        message: `Успешно создано задач: ${createdTaskList?.length}`,
        style: {
          type: ENotificationType.Success,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          autoClose: 10000,
          hideProgressBar: false,
          theme: ENotificatorTheme.Dark,
        },
      });
    } else {
      notificatorActions.setNotification({
        message: `Ошибка создания задач`,
        style: {
          type: ENotificationType.Warning,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          autoClose: 10000,
          hideProgressBar: false,
          theme: ENotificatorTheme.Dark,
        },
      });
    }
  };

  const handleChecklistSaveClick = async (): Promise<void> => {
    const isSuccess = await checklistsController.saveAttrValues();

    if (isSuccess) {
      tasksRouteActions.goToChecklist(params.checklistInstanceId);
    }

    // const savingResult = await checklistInstancesController.saveInstanceAttributeValues();
    //
    // if (savingResult === ESaveInstanceAttributeValuesResults.success) {
    //   tasksRouteActions.goToChecklist(params.checklistInstanceId);
    // }
    //
    // if (savingResult === ESaveInstanceAttributeValuesResults.validationError) {
    //   return;
    // }
    //
    // if (savingResult === ESaveInstanceAttributeValuesResults.saveError) {
    //   modalActions.openModalByModalId('resendChecklistData', {
    //     resendHandler: handleChecklistSaveClick,
    //   });
    // }
  };

  const handleEditMapPointClick = () => {
    const notificationScheme = getNotificationScheme(
      ENotificationScheme.Dark,
      `Координаты точки ${selectedPoint.positionNumber} изменены`
    );

    notificatorActions.setNotification(notificationScheme);

    tasksRouteActions.goToMapCreatePoint(null, true);

    // @ts-ignore
    updateDrawInst(selectedPoint, {
      type: selectedPoint.type,
      coordinates: L.GeoJSON.latLngToCoords(tempLatLngOfEditablePoint),
    });

    setTempLatLngOfEditablePoint(null);
  };

  const handlePrimaryButtonClick = (): void => {
    if (taskStore.taskTab === ETaskTabs.Checklists) {
      handleChecklistSaveClick();

      return;
    }

    if (taskStore.mode === 'edit') {
      handleSaveClick();
    } else if (taskStore.mode === 'create-one') {
      handleCreateTask();
    } else if (taskStore.previewTaskList.length) {
      handleCreateTaskList();
    } else if (taskStore.mode === 'create-multiple') {
      handleShowPreviewTasks();
    } else if (taskStore.mode === 'edit-point') {
      handleEditMapPointClick();
    }
  };

  if (taskStore.taskTab === ETaskTabs.Checklists) {
    if (!checklistsStore.selectedChecklist) return <></>;
  }

  if (!isCreating && !isNotCanceled) {
    return <></>;
  }

  return (
    <Styled.Wrapper>
      <Styled.ButtonWrapper>
        {!taskStore.isViewMode ? (
          <Button
            color={'primary'}
            type={'button'}
            onClick={handlePrimaryButtonClick}
            disabled={primaryButtonDisabledState}
            isLoading={isLongLoading}
            dataTestId={
              isCreating ? 'fullscreen-create-task-button' : 'fullscreen-save-task-button'
            }
          >
            {primaryButtonText}
          </Button>
        ) : null}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

TaskControlButtons.displayName = 'TaskControlButtons';

export default observer(TaskControlButtons);
