import { EIconButtonSize, IconButton, useContextualHelpActions } from '@farmlink/farmik-ui';
import { FC } from 'react';

import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../shared/constants/contextualPath';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { usePageContextualHelp } from '../../../../../../../../../shared/hooks';
import SharedStyled from '../../../../../../components/shared/styles/Shared.styles';

import Styled from './Header.styles';

interface IProps {
  title: string;
  subtitle: string;
  onBackClick: () => void;
  mode: 'view' | 'edit';
}

const LeftPanelHeader: FC<IProps> = ({ title, subtitle, onBackClick, mode }) => {
  usePageContextualHelp(EContextualParentPath.CultureZonesEditor);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.CultureZonesEditor,
    mode === 'view'
      ? ContextualPaths.CultureZonesEditorTitleView
      : ContextualPaths.CultureZonesEditorTitleEdit,
    { position: 'bottom-right', positionAdjustment: { x: -30, y: 0 } }
  );

  const getDataTestId = useDataTestIdV2('field-seasons-leftpanel-header');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <IconButton
        icon={'arrow_left'}
        size={EIconButtonSize.x12}
        colorScheme={SharedStyled.hoverIconColorScheme}
        dataTestId={getDataTestId('arrow-icon')['data-test-id']}
        onClick={onBackClick}
      />

      <SharedStyled.TextWrapper {...getDataTestId('text-wrapper')}>
        <SharedStyled.Title
          fontSize={16}
          fontWeight={600}
          dataTestId={getDataTestId('title')['data-test-id']}
        >
          {title ?? ''}
        </SharedStyled.Title>

        <SharedStyled.Subtitle dataTestId={getDataTestId('subtitle')['data-test-id']}>
          {subtitle ?? ''}
        </SharedStyled.Subtitle>
      </SharedStyled.TextWrapper>

      {ContextualHelpIcon ? ContextualHelpIcon : null}
    </Styled.Wrapper>
  );
};

export default LeftPanelHeader;
