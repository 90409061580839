import { makeAutoObservable } from 'mobx';

import { EFieldsListSort, TFiltersTuple } from '../../../../interfaces/Filter.interface';
import { FilterStore } from '../../../../../../../shared/features/FilterManager';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { Field } from '../../../../../../../../api/models/field.model';
import { ELeftPanelType } from '../../interfaces/LeftPanelType.enum';
import { FIELDS_FILTER_MANAGER_ID } from '../../../../utils';

@provide.singleton()
class FieldsViewStore {
  @lazyInject(FilterStore)
  private filterStore: FilterStore;

  private _isInitialize = false;

  // Определяет развернута ли легенда культур. (Которая в списке полей)
  private _isOpenLegend = false;

  private _leftPanelType: ELeftPanelType = ELeftPanelType.FIELDS_LIST;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Возвращает фильтр-менеджер
   */
  public get filterManager() {
    const id = FIELDS_FILTER_MANAGER_ID;
    return this.filterStore.getManager<TFiltersTuple, Field, EFieldsListSort>(id);
  }

  public get leftPanelType() {
    return this._leftPanelType;
  }

  public set leftPanelType(value) {
    this._leftPanelType = value;
  }

  public get isInitialize() {
    return this._isInitialize;
  }

  public set isInitialize(value) {
    this._isInitialize = value;
  }

  public get isOpenLegend() {
    return this._isOpenLegend;
  }

  public set isOpenLegend(value) {
    this._isOpenLegend = value;
  }

  public openFilter() {
    this._leftPanelType = ELeftPanelType.FILTER;

    this.filterManager?.tempEditor.enable();
  }
}

export default FieldsViewStore;
