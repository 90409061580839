import { Colors } from '@farmlink/farmik-ui';

import { GetFieldByIdReq } from '../../../../../../api/endpoints/fields/get.field.by.id';
import { IRequestStatusActions } from '../../../../../../api/models/common/request';
import { Field } from '../../../../../../api/models/field.model';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';
import { lazyInject, provide } from '../../../../utils/IoC';
import { OrganizationsStore } from '../../../../../dashboard/stores/organizations.store';
import { SeasonsStore } from '../../../../../dashboard/stores/seasons.store';
import { ProfileStore } from '../../../../../dashboard/modules/profile/stores/ProfileStore';
import { FieldsStore } from '../../../stores';

type TFieldsListRequest = TypeApiRequest<'getFields'>;
type TCultureZonesRequest = TypeApiRequest<'getCultureZoneList'>;
type TCultureZonesResponse = TypeApiResponse<'getCultureZoneList'>;

/**
 * Данный сервис отвечает за фетчинг и удаление полей и их культруных зон
 */
@provide.transient()
class FieldsApiService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(FieldsStore)
  private fieldsStore: FieldsStore;

  public fetchField(payload: GetFieldByIdReq): Promise<Field | null> {
    if (!payload?.seasonYear) {
      payload.seasonYear = Number(this.seasonsStore.selectedSeason);
    }

    return this.axios.api
      .getFieldById(payload)
      .then(field => field)
      .catch(() => null);
  }

  /**
   * Метод выполняет фетчинг полей. Поля могут быть закешированны на стороне бэка
   * В случае если в fieldsStore нет полей, то выполнит фетчинг полей с флагом force, что означает что метод пропустит проверку кэшей и вернет список полей
   * Когда добавляются новые поля, кэш становится невалидным. Если кэш невалидный, то тригернет евент инвалидации кэша
   * @param request
   * @param skipInvalidationEvent - пропускает триггер евента в случае если кэш является невалидным
   */
  public async fetchFieldsList(
    request?: Partial<TFieldsListRequest>,
    skipInvalidationEvent = false
  ): Promise<Field[] | null> {
    const { hasFields } = this.fieldsStore;
    const random = Math.random().toString(16).substring(2, 5);

    console.time(`[${random}] Fetch ${hasFields ? 'Cached' : ''} Fields List`);

    this.fieldsStore.isLoading = true;

    await this.seasonsStore.waitSeasons();
    await this.profileStore.waitUser();

    const requestData = this.getRequestData(request);
    const response = this.fetchFields(!hasFields, requestData);

    return response
      .then(fieldList => {
        // H15-5852: У некоторых полей нет цвета культуры. Сетаем дефолтный
        fieldList.forEach(item => {
          (item.cultureZones ?? []).forEach(zone => {
            if (!zone.culture.attrs.assistanceColorLegend) {
              zone.culture.attrs.assistanceColorLegend = Colors.grey;
            }
          });
        });

        this.fieldsStore.setAllFields(fieldList, skipInvalidationEvent);

        return fieldList;
      })
      .catch(err => {
        if (err.response?.status === 304) {
          return this.fieldsStore.fieldsList;
        }

        this.fieldsStore.clearFieldsById();
        return [];
      })
      .finally(() => {
        this.fieldsStore.isLoading = false;
        this.fieldsStore.fieldsLoaded = true;

        console.timeEnd(`[${random}] Fetch ${hasFields ? 'Cached' : ''} Fields List`);

        return [];
      });
  }

  public deleteField(fieldId: string, seasonYear: number | null) {
    if (typeof seasonYear === 'number') {
      return this.axios.api.deleteFieldFromCurrentSeason({ fieldId, seasonYear });
    }

    return this.axios.api.deleteFieldById({ fieldId });
  }

  public fetchCultureZoneList(
    request: TCultureZonesRequest
  ): Promise<TCultureZonesResponse | null> {
    return this.axios.api
      .getCultureZoneList(request)
      .then(res => res)
      .catch(() => null);
  }

  public getCultureZoneListBySto = async (
    payload: TypeApiRequest<'getCultureZoneListBySto'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getCultureZoneListBySto'>>;
    }
  ): Promise<TypeApiResponse<'getCultureZoneListBySto'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axios.api.getCultureZoneListBySto(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  private fetchFields(force: boolean, request: TFieldsListRequest): Promise<Field[]> {
    const { seasonYear, organizationId } = request;

    return this.axios.api
      .getCachedFields({ seasonYear, organizationId, force }, { omit: ['organizationId'] })
      .then(fieldsList => fieldsList);
  }

  private getRequestData(request?: Partial<TFieldsListRequest>): TFieldsListRequest {
    return {
      organizationId: this.organizationStore.getOrganizationId({ skipDefault: true }),
      seasonYear: Number(this.seasonsStore.selectedSeason),
      withCultureZones: true,
      withGeometry: true,
      sort: 'name',
      ...request,
    };
  }
}

export default FieldsApiService;
