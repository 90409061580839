import { Badge, EBadgeSize, EIconButtonSize, Icon, IconButton } from '@farmlink/farmik-ui';
import { useNavigate } from 'react-router-dom';
import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { FieldsApiService } from '../../../../../../../../../shared/mobx/services/as-fields';
import { OrganizationsStore } from '../../../../../../../../stores/organizations.store';
import SharedStyled from '../../../../../../components/shared/styles/Shared.styles';
import { FontSizesObj } from '../../../../../../../../../shared/constans/sizes';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import { ELeftPanelType } from '../../../../interfaces/LeftPanelType.enum';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../../../mobx';
import { useFieldsRoutes } from '../../../../../../hooks';

import LeftPanelHeaderSkeleton from './components/Skeleton/Skeleton';
import SearchInput from './components/SearchInput/SearchInput';
import Styled from './Header.styles';

interface IProps {
  search?: boolean;
  filter?: boolean;
  multiselect?: boolean;
  backButton?: boolean;
  onBackClick?: () => void;
}

const LeftPanelHeader: FC<IProps> = props => {
  const { multiselect, filter, search, backButton } = props;

  // Навигация
  const navigate = useNavigate();
  const fieldsRoutes = useFieldsRoutes();

  // Сторы
  const store = useStore(Store);
  const seasonsStore = useStore(SeasonsStore);
  const orgStore = useStore(OrganizationsStore);

  // Сервисы
  const fieldsAPIService = useStore(FieldsApiService);

  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const { filterManager } = store;
  const notEmptyList = filterManager?.filteredList.length > 0;
  const hasActiveFilters = Boolean(filterManager?.filters.getCounter());
  const seasonLabel = seasonsStore.selectedSeassonData?.label ?? '';
  const orgName = orgStore.currentOrganization?.name ?? 'Организация';

  const getDataTestId = useDataTestIdV2('fields-view-leftpanel-header');

  const handleMultiselectClick = () => {
    navigate(fieldsRoutes.fieldsMultiselectRoute);
  };

  const handleFilterClick = () => {
    // Синхронизиуем поля
    void fieldsAPIService.fetchFieldsList().finally(() => {
      store.openFilter();
    });
  };

  const handleBackClick = () => {
    if (typeof props.onBackClick === 'function') {
      if (store.leftPanelType === ELeftPanelType.FILTER) {
        // Синхронизиуем поля после выхода из фильтров
        void fieldsAPIService.fetchFieldsList().finally(() => {
          props.onBackClick();
        });

        return;
      }

      props.onBackClick();

      return;
    }

    // Синхронизиуем поля
    void fieldsAPIService.fetchFieldsList().finally(() => {
      store.leftPanelType = ELeftPanelType.FIELDS_LIST;
    });
  };

  const handleSearchToggler = (state: 'open' | 'close') => {
    // Синхронизиуем поля
    void fieldsAPIService.fetchFieldsList().finally(() => {
      setIsSearchOpen(state === 'open');
    });
  };

  const getIconDataTestId = (secondary: string) => {
    return getDataTestId(`${secondary}-icon`)['data-test-id'];
  };

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {backButton && (
        <Styled.BackIcon
          icon={'arrow_left'}
          size={EIconButtonSize.x12}
          colorScheme={SharedStyled.hoverIconColorScheme}
          onClick={handleBackClick}
          dataTestId={getDataTestId('back-icon')['data-test-id']}
        />
      )}

      {store.isInitialize && <LeftPanelHeaderSkeleton />}

      {!store.isInitialize && (
        <Styled.TextWrapper>
          <SharedStyled.Title
            dataTestId={getDataTestId('title')['data-test-id']}
            fontSize={16}
            fontWeight={600}
          >
            {orgName}
          </SharedStyled.Title>

          <SharedStyled.Subtitle dataTestId={getDataTestId('subtitle')['data-test-id']}>
            {seasonLabel}
          </SharedStyled.Subtitle>
        </Styled.TextWrapper>
      )}

      {!store.isInitialize && (
        <Styled.IconsWrapper {...getDataTestId('icons')}>
          {multiselect && notEmptyList && (
            <Styled.StrokeIconWrapper onClick={handleMultiselectClick}>
              <Icon
                icon={'multiselect'}
                size={'medium'}
                dataTestId={getIconDataTestId('multiselect')}
              />
            </Styled.StrokeIconWrapper>
          )}

          {filter && (notEmptyList || hasActiveFilters) && (
            <Styled.StrokeIconWrapper onClick={handleFilterClick}>
              <Badge
                content={filterManager?.filters.getCounter()}
                size={EBadgeSize.x14}
                position={{ top: -2, right: 0 }}
                style={FontSizesObj.small}
                hideEmpty
              >
                <Icon icon={'filter'} size={'medium'} dataTestId={getIconDataTestId('filter')} />
              </Badge>
            </Styled.StrokeIconWrapper>
          )}

          {search && notEmptyList && (
            <IconButton
              icon={'search'}
              size={EIconButtonSize.x16}
              onClick={() => handleSearchToggler('open')}
              colorScheme={Styled.IconsScheme}
              dataTestId={getIconDataTestId('search')}
            />
          )}
        </Styled.IconsWrapper>
      )}

      {isSearchOpen && <SearchInput onBackClick={() => handleSearchToggler('close')} />}
    </Styled.Wrapper>
  );
};

export default observer(LeftPanelHeader);
