import { TypeApiRoute } from '../../models/type.api.request';
import { Field } from '../../models/field.model';

type TypeRequest = {
  organizationId: string;
  seasonYear: number;
  force?: boolean;
};

type TypeResponse = Array<Field>;

export const getCachedFields: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request =>
    `/api/as-fields/fields/fullList?${
      request.organizationId.length > 0 ? `organizationId=${request.organizationId}` : ''
    }`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
