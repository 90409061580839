import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import DeletionModalTitle from './components/DeletionModalTitle';

export const TASK_OPERATION_DELETION_MODAL = 'TASK_OPERATION_DELETION_MODAL';

const operationDeletionModalConfig: TModalConfig = {
  name: TASK_OPERATION_DELETION_MODAL,
  id: TASK_OPERATION_DELETION_MODAL,
  dataTestId: 'deletionModal',
  title: DeletionModalTitle(),
  type: EModalType.Warning,
  description: {
    value: 'Удаленные данные невозможно будет восстановить',
    $styles: { marginBottom: '32px', fontSize: '14px', wordBreak: 'break-word'},
  },
  styledProps: {
    $size: EModalSize.Large,
    $height: '316px',
    $modalPaddings: '38px 50px',
    $isFullContentWidth: true
  },
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
};

export default operationDeletionModalConfig;
