import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationViewStore as Store } from '../../stores';
import { TasksService } from '../../../../../../../../shared/mobx/services/as-fields';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { ITaskEvent } from '../../../../../../../../../api/models/task/events/task.event.mode';
import { ITaskOperationConfig } from '../../../../../../../../../api/models/as-fields/taskOperationConfig';
import { TaskOperationConfigsService as ConfigsService } from '../../../../../../../../shared/mobx/services/as-fields/TaskOperationConfigsService';

@provide.transient()
class TaskOperationViewApiService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  public getActiveTask = async (taskId: string): Promise<void> => {
    const payload: TypeApiRequest<'getTask'> = {
      id: taskId,
    };

    const response: ITask = await this.tasksService.getTask(payload);

    if (!response) return;

    this.store.setSelectedTask(response);
  };

  public getTaskEventList = async (taskId: string): Promise<void> => {
    const payload: TypeApiRequest<'getTask'> = {
      id: taskId,
    };

    const response: ITaskEvent[] = await this.tasksService.getTaskEventList(payload);

    if (!response) return;

    this.store.setStatusPillEventList(response);
  };

  public getTaskOperationConfig = async (dictionaryValueId: string): Promise<void> => {
    if (!dictionaryValueId) return;

    const payload: TypeApiRequest<'getTaskOperationConfigList'> = {
      operationDictionaryValueId: dictionaryValueId,
    };

    const response: ITaskOperationConfig[] = await this.configsService.getTaskOperationConfigList(
      payload
    );

    if (!response[0]) return;

    this.store.setOperationTypeLabel(response[0]?.descriptionUi);
  };

  public deleteTask = async (): Promise<void> => {
    await this.tasksService.deleteTask({
      id: this.store.selectedTask.id,
    });
  };
}

export default TaskOperationViewApiService;
