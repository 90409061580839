import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';
import { GetTaskReq } from '../../../../../../api/endpoints/tasks/get.task';
import { TUpdateTaskReq } from '../../../../../../api/endpoints/as-fields/tasks/updateTask';
import { ITask } from '../../../../../../api/models/as-fields/task/task.model';
import { TSetStatusReq } from '../../../../../../api/endpoints/tasks/set.status';
import { GetTaskEventListReq, GetTotalTasksReq } from '../../../../../../api/api';
import { IRequestStatusActions } from '../../../../../../api/models/common/request';
import { ITotalTasks } from '../../../../../../api/models/task/task.model';

@provide.singleton()
class TasksService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getTask = async (payload: GetTaskReq): Promise<ITask> => {
    const { getTask } = this.axiosService.api;

    try {
      const task = await getTask(payload);

      return task;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getTaskListByFilters = async (
    payload: TypeApiRequest<'getTaskList'>,
    config?: {
      query?: Record<string, any>;
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TypeApiResponse<'getTaskList'>) => void;
      };
    }
  ): Promise<TypeApiResponse<'getTaskList'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.getTaskList(payload, { query: config?.query });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  getTotalTasksCount = async (
    payload: TypeApiRequest<'getTotalTasks'>,
    config?: {
      query?: Record<string, any>;
      actions?: IRequestStatusActions<TypeApiResponse<'getTotalTasks'>>;
    }
  ): Promise<ITotalTasks> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getTotalTasks(payload, { query: config?.query });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw e;
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  updateTask = async (payload: TUpdateTaskReq): Promise<ITask> => {
    const { updateTask } = this.axiosService.api;

    try {
      const task = await updateTask(payload, { omit: ['id'] });

      return task;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  createTask = async (
    payload: TypeApiRequest<'createTask'>
  ): Promise<TypeApiResponse<'createTask'>> => {
    try {
      const task = await this.axiosService.api.createTask(payload);

      return task;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  deleteTask = async (
    payload: TypeApiRequest<'deleteTask'>
  ): Promise<TypeApiResponse<'deleteTask'>> => {
    try {
      const response = await this.axiosService.api.deleteTask(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  public createTaskList = async (
    payload: TypeApiRequest<'createTaskList'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'createTaskList'>>;
    }
  ): Promise<TypeApiResponse<'createTaskList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.createTaskList(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };

  setStatus = async (payload: TSetStatusReq): Promise<boolean> => {
    const { setStatus } = this.axiosService.api;

    try {
      await setStatus(payload);

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getTaskEventList = async (payload: GetTaskEventListReq) => {
    try {
      const eventList = await this.axiosService.api.getTaskEventList(payload);

      return eventList;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export default TasksService;
