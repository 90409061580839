import { EFieldTooltip } from '../../../../../../../../../dashboard/constants/FieldTooltip.enum';
import { extractCultures } from '../../../../../../../../utils/helpers/extractCultures';
import { Field } from '../../../../../../../../../../api/models/field.model';
import { mapToArray } from '../../../../../../../../utils/helpers/map/mapToArray';
import { toFixedWithCeil } from '../../../../../../../../utils/toFixedWithCeil';

const getAreaLabel = (area: Field['area']): string => `${toFixedWithCeil(area)} га`;

const makeTitle = (field: Field, values: EFieldTooltip[]) => {
  const area = getAreaLabel(field.area);

  if (values.includes(EFieldTooltip.Name) && values.includes(EFieldTooltip.Area)) {
    return `<b>${field.name} : ${area}</b> <br>`;
  }

  if (values.includes(EFieldTooltip.Name)) {
    return `<b>${field.name}</b> <br>`;
  }

  if (values.includes(EFieldTooltip.Area)) {
    return `<b>${area}</b> <br>`;
  }

  return '';
};

const makeCulturesList = (field: Field, values: EFieldTooltip[]): string => {
  if (!values.includes(EFieldTooltip.Culture)) {
    return '';
  }

  const uniqueNames = mapToArray(extractCultures(field, true));

  return uniqueNames.length
    ? `<span style='display: block; margin-top: -2px'>${uniqueNames.join('<br>')}</span>`
    : 'Нет культуры';
};

const makeTooltip = (field: Field, values: EFieldTooltip[]): string | null => {
  if (!values.length) {
    return null;
  }

  const title = makeTitle(field, values);
  const cultures = makeCulturesList(field, values);

  return `${title} ${cultures}`;
};

export default makeTooltip;
