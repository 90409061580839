import { makeAutoObservable } from 'mobx';

import {
  IGetChecklistAttributeListPayload,
  TDeleteChecklistInstanceReq,
  TGetChecklistInstanceListReq,
} from '../../../../../../api/api';
import {
  IGetChecklistAttribute,
  IGetChecklistAttributeEnumValue,
} from '../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IGetChecklist } from '../../../../../../api/models/checklist/checklist.model';
import {
  IGetChecklistInstance,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../api/models/checklist/instance/checklist.instance.model';
import { IGetChecklistStage } from '../../../../../../api/models/checklist/stage/checklist.stage.model';
import { ERequestStatus } from '../../../../../shared/constants/requests';
import { Axios, TypeApiRequest } from '../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../shared/utils/IoC';

@provide.singleton()
export class ChecklistsService {
  @lazyInject(Axios)
  protected axios: Axios;

  // Статусы загрузки

  private _checklistRequestStatus: ERequestStatus | null = null;

  private _instanceRequestStatus: ERequestStatus | null = null;

  private _stageRequestStatus: ERequestStatus | null = null;

  private _attributeRequestStatus: ERequestStatus | null = null;

  private _intensityRequestStatus: ERequestStatus | null = null;

  private _instanceListRequestStatus: ERequestStatus | null = null;

  private _instanceEventListRequestStatus: ERequestStatus | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Геттеры

  get checklistRequestStatus() {
    return this._checklistRequestStatus;
  }

  get instanceRequestStatus() {
    return this._instanceRequestStatus;
  }

  get stageRequestStatus() {
    return this._stageRequestStatus;
  }

  get attributeRequestStatus() {
    return this._attributeRequestStatus;
  }

  get intensityRequestStatus() {
    return this._intensityRequestStatus;
  }

  get instanceListRequestStatus() {
    return this._instanceListRequestStatus;
  }

  get instanceEventListRequestStatus() {
    return this._instanceEventListRequestStatus;
  }

  get isLoading(): boolean {
    const checklistIsLoading = this._checklistRequestStatus === ERequestStatus.Pending;
    const instanceIsLoading = this._instanceRequestStatus === ERequestStatus.Pending;
    const stageIsLoading = this._stageRequestStatus === ERequestStatus.Pending;
    const attributeIsLoading = this._attributeRequestStatus === ERequestStatus.Pending;
    const intensityIsLoading = this._intensityRequestStatus === ERequestStatus.Pending;
    const instanceListIsLoading = this._instanceListRequestStatus === ERequestStatus.Pending;

    return (
      checklistIsLoading ||
      instanceIsLoading ||
      stageIsLoading ||
      attributeIsLoading ||
      intensityIsLoading ||
      instanceListIsLoading
    );
  }

  // Сеттеры

  setChecklistRequestStatus = (status: ERequestStatus): void => {
    this._checklistRequestStatus = status;
  };

  setInstanceRequestStatus = (status: ERequestStatus): void => {
    this._instanceRequestStatus = status;
  };

  setStageRequestStatus = (status: ERequestStatus): void => {
    this._stageRequestStatus = status;
  };

  setAttributeRequestStatus = (status: ERequestStatus): void => {
    this._attributeRequestStatus = status;
  };

  setIntensityRequestStatus = (status: ERequestStatus): void => {
    this._intensityRequestStatus = status;
  };

  setInstanceListRequestStatus = (status: ERequestStatus): void => {
    this._instanceListRequestStatus = status;
  };

  setInstanceEventListRequestStatus = (status: ERequestStatus): void => {
    this._instanceEventListRequestStatus = status;
  };

  // Методы по очищению стора

  clearStore = (): void => {
    this._checklistRequestStatus = null;
    this._instanceRequestStatus = null;
    this._stageRequestStatus = null;
    this._attributeRequestStatus = null;
    this._intensityRequestStatus = null;
    this._instanceListRequestStatus = null;
    this._instanceEventListRequestStatus = null;
  };

  // Методы запросов

  // Методы чек-листов

  fetchChecklistInstanceList = async (
    payload: TGetChecklistInstanceListReq
  ): Promise<IGetChecklistInstanceByTaskId[] | null> => {
    this.setInstanceListRequestStatus(ERequestStatus.Pending);

    const queryTypes = payload?.types?.join?.('&types=');

    try {
      const { content } = await this.axios.api.getChecklistInstanceList(
        { taskId: payload.taskId, size: payload?.size, isActive: true },
        { omit: ['taskId'], query: { types: queryTypes } }
      );

      this.setInstanceListRequestStatus(ERequestStatus.Fulfilled);

      return content;
    } catch (e) {
      this.setInstanceListRequestStatus(ERequestStatus.Rejected);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  fetchChecklistById = async (id: string): Promise<IGetChecklist> => {
    this.setChecklistRequestStatus(ERequestStatus.Pending);

    try {
      const fetchedChecklist = await this.axios.api.getChecklistById({ id }, { omit: ['id'] });

      this.setChecklistRequestStatus(ERequestStatus.Fulfilled);

      return fetchedChecklist;
    } catch (e) {
      this.setChecklistRequestStatus(ERequestStatus.Rejected);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  // Методы точек чек-листов

  fetchInstanceById = async (id: string): Promise<IGetChecklistInstance> => {
    this.setInstanceRequestStatus(ERequestStatus.Pending);

    try {
      const fetchedInstance = await this.axios.api.getChecklistInstanceById(
        { id },
        { omit: ['id'] }
      );

      this.setInstanceRequestStatus(ERequestStatus.Fulfilled);

      return fetchedInstance;
    } catch (e) {
      this.setInstanceRequestStatus(ERequestStatus.Rejected);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchStageList = async (checklistId: string): Promise<IGetChecklistStage[]> => {
    this.setStageRequestStatus(ERequestStatus.Pending);

    try {
      const fetchedStageList = await this.axios.api.getChecklistStageList({ checklistId });

      this.setStageRequestStatus(ERequestStatus.Fulfilled);

      return fetchedStageList;
    } catch (e) {
      this.setStageRequestStatus(ERequestStatus.Rejected);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchAttributeList = async (
    payload: IGetChecklistAttributeListPayload
  ): Promise<IGetChecklistAttribute[]> => {
    this.setAttributeRequestStatus(ERequestStatus.Pending);

    try {
      const { content } = await this.axios.api.getChecklistAttributeList(payload);

      this.setAttributeRequestStatus(ERequestStatus.Fulfilled);

      return content;
    } catch (e) {
      this.setAttributeRequestStatus(ERequestStatus.Rejected);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchAttributesByCheckListPublicId = async (
    publicId: string,
    payload?: TypeApiRequest<'getChecklistAttributeByCheckListPublicId'>
  ): Promise<IGetChecklistAttribute[]> => {
    this.setChecklistRequestStatus(ERequestStatus.Pending);

    try {
      const { content } = await this.axios.api.getChecklistAttributeByCheckListPublicId(payload, {
        path: publicId,
      });

      this.setChecklistRequestStatus(ERequestStatus.Fulfilled);

      return content;
    } catch (e) {
      this.setChecklistRequestStatus(ERequestStatus.Rejected);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchEnumListByAttributeId = async (
    attributeId: string
  ): Promise<IGetChecklistAttributeEnumValue[]> => {
    try {
      const fetchedEnumList = await this.axios.api.getChecklistEnumListByAttributeId(
        { attributeId },
        { omit: ['attributeId'] }
      );

      return fetchedEnumList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  deleteChecklistInstance = async (payload: TDeleteChecklistInstanceReq): Promise<boolean> => {
    try {
      await this.axios.api.deleteChecklistInstance(payload, { omit: ['id'] });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  fetchChecklistInstanceEventList = async (
    payload: TypeApiRequest<'getChecklistInstanceEventList'>
  ) => {
    this.setInstanceEventListRequestStatus(ERequestStatus.Pending);

    try {
      const eventList = await this.axios.api.getChecklistInstanceEventList(payload);

      this.setInstanceEventListRequestStatus(ERequestStatus.Fulfilled);

      return eventList;
    } catch (error) {
      this.setInstanceEventListRequestStatus(ERequestStatus.Rejected);

      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
}
