import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  TaskOperationViewApiService as ApiService,
  TaskOperationViewDefaultValuesService as DefaultValuesService,
} from '../../services';
import { TaskOperationViewStore as Store } from '../../stores';
import { TasksParams } from '../../../../../models';

@provide.transient()
class TaskOperationViewController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  @lazyInject(DefaultValuesService)
  protected defaultValuesService: DefaultValuesService;

  public initiatePage = async (params: TasksParams): Promise<void> => {
    await this.apiService.getActiveTask(params.taskId);
    await this.apiService.getTaskEventList(params.taskId);
    await this.apiService.getTaskOperationConfig(
      this.store.selectedTask?.operationInfo?.operationTypeInfo.id
    );

    this.store.setIsPageLoading(false);
  };

  public deleteTask = (): void => {
    this.apiService.deleteTask();
  };

  public get cultureName() {
    return this.defaultValuesService.cultureName;
  }

  public get fieldName() {
    return this.defaultValuesService.fieldName;
  }

  public get operationName() {
    return this.defaultValuesService.operationName;
  }

  public get operationType() {
    return this.defaultValuesService.operationType;
  }

  public get operationTypeLabel() {
    return this.defaultValuesService.operationTypeLabel;
  }

  public get assigneeName() {
    return this.defaultValuesService.assigneeName;
  }

  public get date() {
    return this.defaultValuesService.date;
  }

  public clearStore = (): void => {
    this.store.clearIsPageLoading();
    this.store.clearSelectedTask();
    this.store.clearStatusPillEventList();
    this.store.clearOperationTypeLabel();
  };
}

export default TaskOperationViewController;
