import { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment/moment';

import { TChecklistsDateAttrToDraw as TDateAttrToDraw } from '../../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  attrToDraw: TDateAttrToDraw;
}

const ChecklistsDateAttr: FC<IProps> = ({ attrToDraw }) => {
  const { id, groupId, initialModel, value, isBlocked, dependentFileAttrId } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__date-attribute');

  const seasonsStore = useStore(SeasonsStore);

  const checklistsController = useStore(ChecklistsController);

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);
  const placeholder = useAttrPlaceholder(initialModel.attribute);

  const errorString = useMemo(() => errorList.join('\n'), [errorList]);

  const handleChange = useCallback((newValue: string): void => {
    checklistsController.changeAttrValue(EAttrType.Date, groupId, {
      ...value,
      dateValue: newValue,
    });
  }, []);

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <CalendarComponent
            label={initialModel.attribute?.name}
            value={
              value.dateValue ? moment(value.dateValue, 'YYYY-MM-DD')?.format('DD.MM.YYYY') : ''
            }
            defaultValue={
              value.dateValue ? moment(value.dateValue, 'YYYY-MM-DD')?.format('DD.MM.YYYY') : ''
            }
            onChange={(newDate: Date) =>
              handleChange(newDate ? moment(newDate).format('YYYY-MM-DD') : undefined)
            }
            placeholder={placeholder}
            datePickerOptions={{
              dateRange: {
                minDate: moment(seasonsStore.selectedSeassonData.startDate, 'YYYY-MM-DD').toDate(),
                maxDate: moment(seasonsStore.selectedSeassonData.endDate, 'YYYY-MM-DD')
                  .hours(23)
                  .minutes(59)
                  .seconds(59)
                  .milliseconds(999)
                  .toDate(),
              },
            }}
            tooltip={initialModel.toolTip}
            isRequired={initialModel.isRequired}
            isBlocked={isBlocked}
            isCloseOnChange={true}
            isClearable
            error={errorString}
            dataTestId={getDataTestId('calendar')['data-test-id']}
          />

          <CSSContainer
            display={'flex'}
            justifyContent={'space-between'}
            dataTestId={getDataTestId('additional-info')['data-test-id']}
          >
            <FileAttrContainer
              groupId={groupId}
              attrId={dependentFileAttrId}
              isNeedAdjustableContainer
            />
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsDateAttr.displayName = 'ChecklistsDateAttr';

export default observer(ChecklistsDateAttr);
