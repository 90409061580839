import { Input } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../../../../../mobx';
import { IMinMaxRange } from '../../../../../../../../utils/filters/FieldArea.filter';
import FilterSectionHeader from '../../components/SectionHeader/SectionHeader';

import Styled from './FieldAreaFilter.styles';
import useChangeFilterFn from './hooks/useChangeFilterFn';

type TInputValues = IMinMaxRange<string | number>;

const onlyFloatNumber = new RegExp(/^-?\d*\.?\d*$/);

const FieldAreaFilterSection: FC = () => {
  // Сторы
  const store = useStore(Store);

  const [input, setInput] = useState<TInputValues>({ min: '', max: '' });
  const filter = store.filterManager.filters.getOne('area');

  const debounceChangeFilter = useChangeFilterFn(filter, 1000);
  const getDataTestId = useDataTestIdV2('field-area-filter');

  const handleInputChange = (value: string, type: 'min' | 'max') => {
    if (!onlyFloatNumber.test(value)) {
      return;
    }

    const newValue = { ...input, [type]: value };

    setInput(newValue);
    debounceChangeFilter(newValue, setInput);
  };

  useEffect(() => {
    if (filter.value) {
      setInput({
        min: filter.value.min,
        max: filter.value.max,
      });
    }
  }, [filter.value]);

  return (
    <Styled.Wrapper>
      <FilterSectionHeader
        title={'Площадь, га'}
        showResetBtn={false}
        getDataTestId={getDataTestId}
      />

      <Styled.InputsContainer>
        <Input
          value={input.min || ''}
          placeholder={`От ${filter?.minMaxRange.min}`}
          onChange={value => handleInputChange(value, 'min')}
        />
        <Input
          value={input.max || ''}
          placeholder={`До ${filter?.minMaxRange.max}`}
          onChange={value => handleInputChange(value, 'max')}
        />
      </Styled.InputsContainer>
    </Styled.Wrapper>
  );
};

export default observer(FieldAreaFilterSection);
