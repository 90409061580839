import { Button, ENotificationType, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useMemo, useState } from 'react';

import { useStore } from '../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreStore as StoChecklistsStore } from '../../../../mobx/stores';
import { StoChecklistsCoreController as StoChecklistsController } from '../../../../mobx/controllers';
import { useStoNavigation, useStoParams } from '../../../../../../../../../../../../hooks';
import getNotificationScheme, {
  ENotificationScheme,
} from '../../../../../../../../../../../../../../../shared/utils/helpers/getNotificationScheme/getNotificationScheme';
import { StoAttributeCoreController as AttributeController } from '../../modules/StoAttribute/mobx/controllers';
import { StoOperationCoreController as OperationController } from '../../../../../../mobx/controllers';
import { SimpleLoader } from '../../../../../../../../../../../../../../../shared/features/UI/new/SimpleLoader';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './StoChecklistControlButtons.styles';

const StoChecklistControlButtons: FC = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const checklistsStore = useStore(StoChecklistsStore);

  const operationController = useStore(OperationController);
  const checklistsController = useStore(StoChecklistsController);
  const attributeController = useStore(AttributeController);

  const params = useStoParams();
  const navigations = useStoNavigation();
  const notificator = useNotificator();

  const handleButtonClick = () => {
    const handleSuccess = (): void => {
      (async () => {
        notificator.setNotification(
          getNotificationScheme(
            ENotificationScheme.Dark,
            'Настройки параметров сохранились. Не забудьте опубликовать изменения',
            { type: ENotificationType.Success }
          )
        );

        attributeController.clearStore();
        attributeController.clearAlreadyChangedAttrIds();

        await operationController.fetchSto(params.stoId, params.versionId);

        checklistsStore.setIsLoading(true);
        checklistsController.clearStore();

        await checklistsController.initiatePage(params, navigations);
      })();
    };

    checklistsController.saveStoChecklistsAttrsTables({
      stoId: params.stoId,
      operationId: params.operationId,
      successHandler: handleSuccess,
      loadingHandler: setIsSaving,
    });
  };

  const getDataTestId = useDataTestIdV2('sto__checklist__control-buttons');

  const Loader = useMemo(() => {
    return (
      <Button
        color={'default'}
        type={'button'}
        disabled
        dataTestId={getDataTestId('button-loader')['data-test-id']}
      >
        <Styled.LoaderWrapper {...getDataTestId('button-loader__loader-wrapper')}>
          <SimpleLoader dataTestId={getDataTestId()['data-test-id']} size={24} color={'#FFFFFF'} />
        </Styled.LoaderWrapper>
      </Button>
    );
  }, []);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.ButtonWrapper
        {...getDataTestId('button-wrapper')}
        $isHidden={Boolean(params.versionId)}
      >
        {isSaving ? (
          Loader
        ) : (
          <Button
            color={'primary'}
            type={'button'}
            onClick={handleButtonClick}
            disabled={checklistsStore.isLoading || !checklistsStore.hasChanges}
            dataTestId={getDataTestId('button-save')['data-test-id']}
          >
            Сохранить
          </Button>
        )}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

StoChecklistControlButtons.displayName = 'StoChecklistControlButtons';

export default observer(StoChecklistControlButtons);
