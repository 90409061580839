import { PolylineOptions } from 'leaflet';
import { Colors } from '@farmlink/farmik-ui';
import { Geometry } from '@turf/turf';
import { cloneDeep } from 'lodash';

import { BaseCulturePolygon } from '../../../../../../../../../shared/features/map/utils/MapElements/polygons';
import LayerHoverHandler from '../../../../../../../../../shared/features/map/utils/helpers/LayerHoverHandler';
import { CultureZone } from '../../../../../../../../../../api/models/field.model';
import CulturesLayerGroup from '../CulturesLayerGroup';

interface IState {
  isSelected: boolean;
  isTransparent: boolean;
  skipHandleHover: boolean;
}

/**
 * КЗ-полигон для {@link CulturesLayerGroup}.
 * Наследуется от базового КЗ-полигона
 */
class CulturePolygon extends BaseCulturePolygon {
  private _beforeTransparentOptions: PolylineOptions;

  private _lastValidGeometry: Geometry;

  private _isSelected = false;
  private _isTransparent = false;

  public readonly hoverHandler = new LayerHoverHandler(this);

  constructor(geometry: Geometry, dataModel: CultureZone) {
    super(geometry, dataModel);

    this._lastValidGeometry = this.getInfo().geometry;

    this.hoverHandler.register({ color: '#780BBB' });
  }

  public getLastValidGeometry() {
    return this._lastValidGeometry;
  }

  public get isSelected() {
    return this._isSelected;
  }

  public get isTransparent() {
    return this._isTransparent;
  }

  public toggleTransparent(value: boolean) {
    if (value) {
      this._beforeTransparentOptions = cloneDeep(this.options);

      this.setStyle({ fillColor: 'transparent' });

      this._prevOptions = this.options;
      this._isTransparent = true;

      return;
    }

    if (this._beforeTransparentOptions) {
      this.setStyle(this._beforeTransparentOptions);

      this._prevOptions = this._beforeTransparentOptions;

      this._beforeTransparentOptions = null;
      this._isTransparent = false;

      if (this.isSelected) {
        this.toggleHighlight(true);
      }
    }
  }

  public toggleHighlight(value: boolean) {
    const color = value ? '#780BBB' : Colors.generalWhite;

    this.setStyle({ color });

    this._prevOptions.color = color;
    this.updateGeomanCachedColor(color);

    this._isSelected = value;
    this.hoverHandler.skipHandle = value;
  }

  /**
   * Обновляет последнюю валидную геометрию
   */
  public updateValidGeometry(geometry: Geometry) {
    this._lastValidGeometry = geometry;
  }

  public getState(): IState {
    return {
      isSelected: this.isSelected,
      isTransparent: this.isTransparent,
      skipHandleHover: this.hoverHandler.skipHandle,
    };
  }

  public setState(state: IState) {
    this.toggleHighlight(state.isSelected);
    this.toggleTransparent(state.isTransparent);

    this.hoverHandler.skipHandle = state.skipHandleHover;
  }
}

export default CulturePolygon;
