import turfDifference from '@turf/difference';
import { buffer as turfBuffer, Position } from '@turf/turf';

import BaseCulturePolygon from '../../../../../../../../../shared/features/map/utils/MapElements/polygons/BaseCulturePolygon';
import {
  BasePolygon,
  FieldPolygon,
} from '../../../../../../../../../shared/features/map/utils/MapElements/polygons';
import {
  calculateArea,
  mergePolygons,
} from '../../../../../../../../../shared/features/map/utils/helpers';
import HolePolygon from '../HolePolygon/HolePolygon';

/**
 * Принимает полигон поля, и список КЗ-полигонов.
 * Мержит КЗ-полигоны в один полигон, после чего находит разницу между полигоном поля и смерженным КЗ-полигоном.
 * Полученная разница является дырами.
 *
 * ### В текущей реализации полигоны-дырки не могут быть мультиполигонами
 * ### Не отрисовывает полигоны чья площадь меньше минимальной (0.001 га)
 */
function calculateHolePolygonsOfField(
  fieldPolygon: FieldPolygon,
  culturesPolygonsList: BaseCulturePolygon[] = []
): HolePolygon[] {
  if (!culturesPolygonsList.length) {
    return [];
  }

  const fieldTurfPolygon = fieldPolygon.toTurf();
  const culturesTurfPolygons = culturesPolygonsList.map(makeBuffer);

  const merged = mergePolygons(culturesTurfPolygons);
  const diff = turfDifference(fieldTurfPolygon, merged);

  if (!diff) {
    return [];
  }

  const { geometry } = diff;

  const isValidArea = createMinAreaFilter(0.001);

  if (geometry.type === 'MultiPolygon') {
    return geometry.coordinates.filter(isValidArea).map(coords => {
      return new HolePolygon({
        type: 'Polygon',
        coordinates: coords,
      });
    });
  }

  if (!isValidArea(geometry.coordinates)) {
    return [];
  }

  return [new HolePolygon(geometry)];
}

/**
 * Увеличиваем исходный полигон на 1 см по всему периметру.
 * Это необходимо из-за особенностей работы функции "snap" у geoman.
 * Без этого увеличения в половине случаев полигоны-дырки будут генерироваться с погрешностями
 */
function makeBuffer(polygon: BasePolygon) {
  return turfBuffer(polygon.toTurf(), 1, { units: 'centimeters', steps: 1 });
}

/**
 * Создает функцию фильтрации по минимальной площади
 * Если площадь больше переданного значения то возвращает true
 */
function createMinAreaFilter(minArea: number): (coords: Position[][]) => boolean {
  return coords => {
    const coordsArea = calculateArea({ type: 'Polygon', coordinates: coords });

    return coordsArea > minArea;
  };
}

export default calculateHolePolygonsOfField;
