import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import conditionalObjectProp from '../../../../../../../../../../../shared/utils/conditionalObjectProp';

interface IWrapperProps {
  isFixed: boolean;
}

const Caption = styled('span')({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: Colors.accentGreen,
});

const Wrapper = styled('button')<IWrapperProps>(
  {
    position: 'absolute',
    top: -5,
    right: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: '6px 8px',
    cursor: 'pointer',
    zIndex: 999,

    backgroundColor: Colors.lightGreen,
    borderRadius: ESpaceSize.S,
    border: 'none',

    ':hover': {
      [Caption]: {
        color: Colors.accentGreenHover,
      },
    },
  },
  ({ isFixed }) => ({
    ...conditionalObjectProp(isFixed, {
      position: 'fixed',
      top: 24,
      right: 48,
      width: 32,
      height: 32,
    }),
  })
);

export default {
  Wrapper,
  Caption,
};
