import { FC } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import Styled from './FiltersEntityCounter.styles';

interface IProps {
  count: number;
  totalCount: number;
  dataTestId: string;
  mode: FiltersEntityCounterModes;
}

export enum FiltersEntityCounterModes {
  Tasks = 'TASKS',
  Sto = 'STO',
}

const DESCRIPTION_CONTEXTS: { [K in FiltersEntityCounterModes]: string } = {
  [FiltersEntityCounterModes.Tasks]: 'задач',
  [FiltersEntityCounterModes.Sto]: 'СТО',
};

const FiltersEntityCounter: FC<IProps> = ({ count, totalCount, dataTestId, mode }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);
  const textContext = DESCRIPTION_CONTEXTS[mode];

  const getCountContent = () => {
    if (count === totalCount) {
      return String(totalCount);
    } else {
      return `${count} из ${totalCount}`;
    }
  };

  const getTooltipContent = () => {
    if (count === totalCount) {
      return `Общее количество ${textContext}`;
    } else {
      return `Количество  ${textContext}, соответствующих заданным фильтрам, и общее число  ${textContext}`;
    }
  };

  return (
    <AutoTooltip
      dataTestId={getDataTestId('tooltip')['data-test-id']}
      content={getTooltipContent()}
    >
      <Styled.CounterContent {...getDataTestId('counter')}>
        {getCountContent()}
      </Styled.CounterContent>
    </AutoTooltip>
  );
};

export default FiltersEntityCounter;
