import React, { FC, useState } from 'react';
import { Icon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { SwiperSlider } from '../../../../../../components/SwiperSlider/SwiperSlider';
import { CultureModel } from '../../../../../../../../api/models/culture.model';
import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';
import { Colors } from '../../../../../../constans/colors';
import { useStore } from '../../../../../../utils/IoC';

import { CulturesLegendStore as Store } from './mobx';
import Styled from './CulturesLegend.styles';

interface IProps {
  // массив имен выбранных культур
  selectedCultures?: string[];

  // массив имен заблокированных культур
  disabledCultures?: string[];

  onClick?: (culture: CultureModel) => void;
}

const CulturesLegend: FC<IProps> = props => {
  // Сторы
  const store = useStore(Store);

  const getDataTestId = useDataTestIdV2('map-cultures-legend');

  const [hasScrollButtons, setHasScrollButtons] = useState(false);
  const isInteractive = Boolean(props.onClick);

  const isSelected = (culture: CultureModel) => {
    if (!props.selectedCultures) {
      return false;
    }

    return props.selectedCultures.includes(culture.name);
  };

  const isDisabled = (culture: CultureModel) => {
    if (!props.selectedCultures) {
      return false;
    }

    return props.disabledCultures.includes(culture.name);
  };

  const handleClick = (culture: CultureModel) => {
    if (isDisabled(culture)) {
      return;
    }

    props.onClick?.(culture);
  };

  // Не показываем легенду если нет культур
  if (store.isNoCultures) {
    return null;
  }

  return (
    <Styled.CulturesListWrapper hasScrollButtons={hasScrollButtons} {...getDataTestId('wrapper')}>
      <SwiperSlider onToggleScrollButtons={setHasScrollButtons}>
        {store.cultures.map(culture => (
          <Styled.CultureWrapper
            key={`legend-${culture.id}`}
            $color={culture.attrs?.assistanceColorLegend ?? Colors.gray}
            isInteractive={!isDisabled(culture) && isInteractive}
            isSelected={isSelected(culture)}
            isDisabled={isDisabled(culture)}
            onClick={() => handleClick(culture)}
            {...getDataTestId(`item-${culture.name}`)}
          >
            <Styled.CultureColor>
              {isSelected(culture) && <Icon icon={'check'} size={'xsmall'} />}
            </Styled.CultureColor>
            <Styled.CultureLabel>{culture.name}</Styled.CultureLabel>
          </Styled.CultureWrapper>
        ))}
      </SwiperSlider>
    </Styled.CulturesListWrapper>
  );
};

export default observer(CulturesLegend);
