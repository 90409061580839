import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, TButtonMultiAction } from '@farmlink/farmik-ui';
import { useLocation } from 'react-router-dom';
import { CSSProperties } from 'styled-components';

import {
  useTasksRouteActions,
  useTasksTaskOperationRoutes as useTaskOperationRoutes,
} from '../../../../../hooks';
import { ETasksContextualReturnBackButtonText } from '../../../../../constants';

interface IProps {
  width: CSSProperties['width'];
}

const TasksFiltersActionButton: FC<IProps> = ({ width }) => {
  const { pathname } = useLocation();
  const { goToTaskCreateOne, goToTaskCreateGroup } = useTasksRouteActions();
  const taskOperationRoutes = useTaskOperationRoutes();

  const btnActionForCreateOneTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Одну',
      onClick: () => {
        goToTaskCreateOne({
          backButtonLabel: ETasksContextualReturnBackButtonText.TasksListing,
          url: pathname,
        });
      },
    };
  }, [goToTaskCreateOne, pathname]);

  const btnActionForCreateMultipleTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Группу',
      onClick: () => {
        goToTaskCreateGroup({
          backButtonLabel: ETasksContextualReturnBackButtonText.TasksListing,
          url: pathname,
        });
      },
    };
  }, [goToTaskCreateGroup, pathname]);

  const btnActionForTaskOperationCreate = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Регистрация работ',
      onClick: () => {
        taskOperationRoutes.goToCommonInfo({
          backButtonLabel: ETasksContextualReturnBackButtonText.TasksListing,
          url: pathname,
        });
      },
    };
  }, [pathname, taskOperationRoutes.goToCommonInfo]);

  return (
    <DropdownButton
      dataTestId={'tasks__create-task'}
      title={'Создать задачу'}
      multiActionList={[
        btnActionForCreateOneTask,
        btnActionForCreateMultipleTask,
        btnActionForTaskOperationCreate,
      ]}
      style={{
        color: 'primary',
        size: 'sm',
        placement: 'bottom',
        width,
      }}
    />
  );
};

TasksFiltersActionButton.displayName = 'TasksFiltersActionButton';

export default observer(TasksFiltersActionButton);
