import { TypeApiRoute } from '../../models/type.api.request';
import { ITotalTasks } from '../../models/task/task.model';

type GetTotalTasksReq = {
  organizationId: string;
  seasonYear: number;
};

type TypeResponse = ITotalTasks;

export const getTotalTasks: TypeApiRoute & {
  request: GetTotalTasksReq;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/task/totalTasks`,
  method: 'GET',
  headers: {},
  request: {} as GetTotalTasksReq,
  response: {} as TypeResponse,
};

export type { GetTotalTasksReq };
