import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #151f32;
`;

const FiltersLoader = styled.div`
  height: 67px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
`;

const ListLoader = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  background-color: white;
`;

const StyledTasksListingPageSkeleton = {
  FiltersLoader,
  ListLoader,
  LoaderWrapper,
  Title,
};

export default StyledTasksListingPageSkeleton;
