import { Button, DropdownButton, Icon, TButtonMultiAction, useModal } from '@farmlink/farmik-ui';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { CREATE_SEASON_MODAL_ID } from '../../../../../../../../../seasons/modals/CreateSeasonModal/CreateSeasonModal.config';
import { EFieldAccessAction } from '../../../../../../../../../../../shared/constants/access-rules-action';
import { importedFieldsSessionStorageHelpers } from '../../../../../../../FieldsImport/utils/helpers';
import { OrganizationsStore } from '../../../../../../../../../../stores/organizations.store';
import { EFieldsImportModalName } from '../../../../../../../FieldsImport/utils/constants';
import useFieldsPermissions from '../../../../../../../../hooks/useFieldsPermissions';
import { SeasonsStore } from '../../../../../../../../../../stores/seasons.store';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import alertSvg from '../../../../../../../../assets/alert-black-16x16.svg';
import useFieldRoutes from '../../../../../../../../hooks/useFieldsRoutes';
import { FieldsImportController } from '../../../../../../../FieldsImport';

import Styled from './Footer.styles';

const FieldsViewListFooter = () => {
  // Навигация
  const navigate = useNavigate();
  const routes = useFieldRoutes();

  // Сторы
  const seasons = useStore(SeasonsStore);
  const organizationsStore = useStore(OrganizationsStore);

  // Контроллеры
  const fieldsImportController = useStore(FieldsImportController);

  const modal = useModal();
  const permissions = useFieldsPermissions();

  const hasSelectedSeason = Boolean(seasons.selectedSeason);

  const importedFieldsToken = importedFieldsSessionStorageHelpers.getToken(
    organizationsStore.selectedOrganizationId,
    seasons.selectedSeason
  );

  const handleClickOnFieldCreation = (): void => {
    navigate(routes.fieldsCreateRoute());
  };

  const handleClickOnUploadFields = (): void => {
    modal.openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: false });
  };

  const buttonMultiActionList: TButtonMultiAction[] = [
    {
      title: 'Продолжить с черновиком',
      description: 'Вернитесь к работе с загруженным файлом',
      onClick: async (): Promise<void> => {
        if (importedFieldsToken) {
          const isSuccess = await fieldsImportController.fetchImportedList(importedFieldsToken);

          if (isSuccess) {
            modal.openModalByModalId(EFieldsImportModalName.Loader);

            navigate(routes.fieldsImportRoute);
          } else {
            modal.openModalByModalId(EFieldsImportModalName.FileImport, {
              isSomethingWentWrong: true,
            });
          }
        }
      },
      icon: alertSvg,
      isHidden: !importedFieldsToken,
    },
    {
      title: 'Добавить поле',
      description: 'Нарисовать контур',
      onClick: handleClickOnFieldCreation,
      icon: <Icon icon={'plus'} size={'medium'} />,
    },
    {
      title: 'Загрузить файлы',
      description: 'KML, geoJSON, shape',
      onClick: handleClickOnUploadFields,
      icon: <Icon icon={'download'} size={'medium'} />,
    },
  ];

  if (!permissions.hasAccess(EFieldAccessAction.CreateFieldOrSeason)) {
    return null;
  }

  if (hasSelectedSeason) {
    return (
      <Styled.DropdownButtonWrapper>
        <DropdownButton
          title={'Добавить поле'}
          multiActionList={buttonMultiActionList}
          style={{ color: 'primary', placement: 'top' }}
          dataTestId={'add-field-button'}
          onClick={handleClickOnFieldCreation}
        />
      </Styled.DropdownButtonWrapper>
    );
  }

  return (
    <Styled.SeasonButtonWrapper>
      <Button
        color={'primary'}
        type={'button'}
        onClick={() => modal.openModalByModalId(CREATE_SEASON_MODAL_ID)}
        dataTestId={'add-season-button'}
      >
        Добавить сезон
      </Button>
    </Styled.SeasonButtonWrapper>
  );
};

export default observer(FieldsViewListFooter);
