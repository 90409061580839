import moment from 'moment';
import { ReactNode } from 'react';

import { FieldsStore } from '../../../../../../../../shared/mobx/stores';
import { lazyInject, provide, useStore } from '../../../../../../../../shared/utils/IoC';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { TaskStatuses } from '../../../../../models/TaskStatuses/TaskStatuses.enum';
import { taskLabels } from '../../../../../stores/tasks.store';
import { ITasksFilters } from '../../../models';
import { TTasksFiltersFilterConfig as TFilterConfig } from '../../../types/configs';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import {
  TasksFiltersActionButton as ActionButton,
  TasksFiltersUploadButton,
} from '../../../containers/header';
import {
  ITableFiltersBuilderConfig,
  ITableFiltersBuilderPresetConfig,
} from '../../../../../../../../shared/features/TableFiltersBuilder/models/configs';
import { ITableFiltersBuilderValue } from '../../../../../../../../shared/features/TableFiltersBuilder/models/data';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { TasksFiltersEntityCounter } from '../../../components';
import { ETableFiltersBuilderId } from '../../../../../../../constants/configs/TableFiltersBuilderId';

@provide.transient()
class TasksFiltersConfigsService {
  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  createConfig = (ContextualHelpIcon: ReactNode): ITableFiltersBuilderConfig<ITasksFilters> => {
    const isOrgMy = this.organizationsStore.selectedOrganizationId === 'my';
    const isNoSeason = !this.seasonsStore.selectedSeason || !this.seasonsStore.selectedSeassonData;
    const isNoFields = !this.fieldsStore.hasFields;

    if (isNoSeason || isNoFields) {
      return {
        id: ETableFiltersBuilderId.Tasks,
        headerConfig: {
          title: 'Задачи',
          ContextualHelpIcon,
          actionButtonConfig: {},
        },
      };
    }

    const presetList = this.createPresetList();
    const filterList = this.createFilterList();

    return {
      id: ETableFiltersBuilderId.Tasks,
      headerConfig: {
        title: 'Задачи',
        additionalTitleContent: (
          <TasksFiltersEntityCounter dataTestId={'tasks-table-filters-header'} />
        ),
        ContextualHelpIcon,
        iconsConfig: {
          iconList: !isOrgMy
            ? [{ id: 'upload-button', element: <TasksFiltersUploadButton /> }]
            : [],
          isShowToggleFilters: Boolean(!presetList.length && filterList.length),
        },
        actionButtonConfig: {
          customRenderConfig: {
            renderButton: () => <ActionButton width={'170px'} />,
          },
        },
      },
      presetConfigList: presetList,
      filterConfigList: filterList,
    };
  };

  createPresetList = (): ITableFiltersBuilderPresetConfig<ITasksFilters>[] => {
    const isTheCurrentWithinTheSeason = this.checkIfTheCurrentDateWithinTheSeason();
    if (!isTheCurrentWithinTheSeason) return [];

    const isCurrentSeason = this.seasonsStore.selectedSeassonData?.isCurrent;
    if (!isCurrentSeason) return [];

    const weekPlan = this.createWeekPlanPreset();
    const weekActual = this.createWeekActualPreset();
    const today = this.createTodayPreset();

    return [weekPlan, weekActual, today];
  };

  createWeekPlanPreset = (): ITableFiltersBuilderPresetConfig<ITasksFilters> => {
    const statuses = this.createWeekPlanPresetRelatedStatuses();
    const dates = this.createWeekPlanPresetRelatedDates();

    const weekPlan: ITableFiltersBuilderPresetConfig<ITasksFilters> = {
      id: 'week-plan',
      name: 'План на 7 дней',
      relatedFilterValueList: [statuses, ...dates],
    };

    return weekPlan;
  };

  createWeekPlanPresetRelatedStatuses = (): ITableFiltersBuilderValue<ITasksFilters> => {
    const statusOptionList: ISelectOption[] = [
      {
        value: TaskStatuses.New,
        label: taskLabels[TaskStatuses.New],
      },
      {
        value: TaskStatuses.InWork,
        label: taskLabels[TaskStatuses.InWork],
      },
    ];

    return { filterId: 'status', type: 'select', selectOptionList: statusOptionList };
  };

  createWeekPlanPresetRelatedDates = (): ITableFiltersBuilderValue<ITasksFilters>[] => {
    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD');

    return [
      {
        filterId: 'planDateFrom',
        type: 'date-range',
        dateValue: startDate,
      },
      {
        filterId: 'planDateTo',
        type: 'date-range',
        dateValue: endDate,
        isHidden: true,
      },
    ];
  };

  createWeekActualPreset = (): ITableFiltersBuilderPresetConfig => {
    const statuses = this.createWeekActualPresetRelatedStatuses();
    const dates = this.createWeekActualPresetRelatedDates();

    const weekPlan: ITableFiltersBuilderPresetConfig = {
      id: 'week-actual',
      name: 'Факт за 7 дней',
      relatedFilterValueList: [statuses, ...dates],
    };

    return weekPlan;
  };

  createWeekActualPresetRelatedStatuses = (): ITableFiltersBuilderValue<ITasksFilters> => {
    const statusOptionList: ISelectOption[] = [
      {
        value: TaskStatuses.Completed,
        label: taskLabels[TaskStatuses.Completed],
      },
      {
        value: TaskStatuses.Canceled,
        label: taskLabels[TaskStatuses.Canceled],
      },
    ];

    return { filterId: 'status', type: 'select', selectOptionList: statusOptionList };
  };

  createWeekActualPresetRelatedDates = (): ITableFiltersBuilderValue<ITasksFilters>[] => {
    const endDate = moment().format('YYYY-MM-DD');
    const startDate = moment(endDate).subtract(6, 'days').format('YYYY-MM-DD');

    return [
      {
        filterId: 'planDateFrom',
        type: 'date-range',
        dateValue: startDate,
      },
      {
        filterId: 'planDateTo',
        type: 'date-range',
        dateValue: endDate,
        isHidden: true,
      },
    ];
  };

  createTodayPreset = (): ITableFiltersBuilderPresetConfig => {
    const dates = this.createTodayPresetRelatedDates();

    const weekPlan: ITableFiltersBuilderPresetConfig = {
      id: 'today',
      name: 'Сегодня',
      relatedFilterValueList: [...dates],
    };

    return weekPlan;
  };

  createTodayPresetRelatedDates = (): ITableFiltersBuilderValue<ITasksFilters>[] => {
    const startDate = moment().format('YYYY-MM-DD');

    return [
      {
        filterId: 'planDateFrom',
        type: 'date-range',
        dateValue: startDate,
      },
      {
        filterId: 'planDateTo',
        type: 'date-range',
        dateValue: startDate,
        isHidden: true,
      },
    ];
  };

  createFilterList = (): TFilterConfig[] => [
    this.createCulturesFilter(),
    this.createFieldsFilter(),
    this.createOperationTypesFilter(),
    this.createAssigneesFilter(),
    this.createStatusesFilter(),
    this.createPlanDateFromFilter(),
    this.createPlanDateToFilter(),
    this.createTypesFilter(),
    this.createIsExpiredFilter(),
  ];

  createCulturesFilter = (): TFilterConfig => {
    return { id: 'cultureId', name: 'Культура', type: 'select' };
  };

  createFieldsFilter = (): TFilterConfig => {
    return {
      id: 'fieldId',
      name: 'Поля',
      type: 'select',
      selectOptions: { isDisplayAllTagsAsOne: true, useCustomLabel: true, customName: 'Поле' },
    };
  };

  createOperationTypesFilter = (): TFilterConfig => {
    return { id: 'operationTypeId', name: 'Операции', type: 'select' };
  };

  createAssigneesFilter = (): TFilterConfig => {
    return {
      id: 'assigneeId',
      name: 'Исполнитель',
      type: 'select',
    };
  };

  createStatusesFilter = (): TFilterConfig => {
    return { id: 'status', name: 'Статус', type: 'select' };
  };

  createPlanDateFromFilter = (): TFilterConfig => {
    const minDate = moment(this.seasonsStore.selectedSeassonData.startDate).toDate();
    const maxDate = moment(this.seasonsStore.selectedSeassonData.endDate).toDate();

    const filter: TFilterConfig = {
      id: 'planDateFrom',
      name: 'Укажите дату',
      type: 'date-range',
      dateRangeOptions: {
        relatedEndDateFilterId: 'planDateTo',
      },
    };

    if (minDate && maxDate) {
      filter.dateRangeOptions.dateRange = { minDate, maxDate };
    }

    const defaultValue = this.getDefaultPlanDateFromValue();
    const isNeedToHideValue = defaultValue === this.seasonsStore.selectedSeassonData.startDate;

    filter.defaultValue = {
      filterId: 'planDateFrom',
      type: 'date-range',
      dateValue: defaultValue,
      dateValueAfterCleaning: this.seasonsStore.selectedSeassonData.startDate,
      isHidden: isNeedToHideValue,
    };

    return filter;
  };

  createPlanDateToFilter = (): TFilterConfig => {
    const filter: TFilterConfig = {
      id: 'planDateTo',
      name: 'Укажите дату',
      type: 'date-range',
      isHidden: true,
    };

    filter.defaultValue = {
      filterId: 'planDateTo',
      type: 'date-range',
      dateValue: this.getDefaultPlanDateToValue(),
      dateValueAfterCleaning: this.seasonsStore.selectedSeassonData.endDate,
      isHidden: true,
    };

    return filter;
  };

  createTypesFilter = (): TFilterConfig => {
    return {
      id: 'typeList',
      name: 'Тип задачи',
      type: 'select',
    };
  };

  createIsExpiredFilter = (): TFilterConfig => {
    return {
      id: 'expired',
      name: 'Только просроченные',
      type: 'boolean',
      booleanOptions: { isNullOnFalse: true },
    };
  };

  protected getDefaultPlanDateFromValue = (): string | undefined => {
    const isTheCurrentWithinTheSeason = this.checkIfTheCurrentDateWithinTheSeason();
    if (!isTheCurrentWithinTheSeason) return this.seasonsStore.selectedSeassonData.startDate;

    /**
     * Высчитываем начало недели от текущего дня.
     */
    const daysToSubtract = moment().isoWeekday() - 1;

    return moment().subtract(daysToSubtract, 'days').format('YYYY-MM-DD');
  };

  protected getDefaultPlanDateToValue = (): string | undefined => {
    const isTheCurrentWithinTheSeason = this.checkIfTheCurrentDateWithinTheSeason();
    if (!isTheCurrentWithinTheSeason) return this.seasonsStore.selectedSeassonData.endDate;

    /**
     * Высчитываем конец недели от текущего дня.
     */
    const daysToAdd = 7 - moment().isoWeekday();

    return moment().add(daysToAdd, 'days').format('YYYY-MM-DD');
  };

  protected checkIfTheCurrentDateWithinTheSeason = (): boolean => {
    const minDateMoment = moment(this.seasonsStore.selectedSeassonData.startDate);
    const maxDateMoment = moment(this.seasonsStore.selectedSeassonData.endDate);

    if (!minDateMoment || !maxDateMoment) return false;

    const currentDateMoment = moment();
    const isBetween = currentDateMoment.isBetween(minDateMoment, maxDateMoment);

    if (!isBetween) return false;

    return true;
  };
}

export default TasksFiltersConfigsService;
