import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { Container } from '../../../../../../../shared/features/UI/new/Container';
import { EContainerHeaderType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';
import { TablePlugBuilder as Plug } from '../../../../../../../shared/features/UI/plugs/TablePlugBuilder';

import { TaskOperationCreateController as Controller } from './../../mobx/controllers';
import { TaskOperationCreateStore as Store } from './../../mobx/stores';
import {
  TaskOperationCreateFieldsList as List,
  TaskOperationCreateFieldsLoader as Loader,
  TaskOperationCreateFieldsSearch as Search,
} from './components';

const TaskOperationCreateFields: FC = () => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const isWarningShown = store.checkIfTaskHasError('taskItems');
  const isNoFieldsPlugShown = !store.fieldOptionList.length;

  const activeCultureName = controller.activeCultureName.toLowerCase();
  const getDataTestId = useDataTestIdV2('tasks__task-operation-create__fields');

  useEffect(() => {
    controller.getFieldOptionList();
  }, []);

  if (store.isFieldOptionsLoading) {
    return <Loader dataTestId={getDataTestId('loader')['data-test-id']} />;
  }

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      containerStyles={{ gap: '16px' }}
      bodyStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: `Поля: ${activeCultureName || 'Culture Plug'}`,
        },
        additionalBottomContent: isWarningShown ? (
          <Typography
            variant={'note'}
            color={'redInfo'}
            dataTestId={getDataTestId('warning-title')['data-test-id']}
          >
            Необходимо выбрать минимум одно поле
          </Typography>
        ) : null,
      }}
    >
      {isNoFieldsPlugShown ? (
        <Plug
          autoRenderConfig={{
            content: {
              title: 'Поля будут доступны после публикации СТО',
              description: 'Опубликуйте СТО или измените культуру',
            },
            header: { icon: 'search' },
          }}
        />
      ) : (
        <>
          <Search dataTestId={getDataTestId('search')['data-test-id']} />

          <List dataTestId={getDataTestId('list')['data-test-id']} />
        </>
      )}
    </Container>
  );
};

export default observer(TaskOperationCreateFields);
