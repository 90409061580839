import { computed, makeObservable, observable } from 'mobx';

import { TFilterMemento, TUpdateMeta } from '../interfaces/Filters.interface';

import FiltersManager from './FilterManager';

type TPrivateProps = '_value';

/**
 * Абстрактный класс фильтра.
 * Любой фильтр должен наследоваться от данного класса
 * Тригерит фильтрацию при присвоении нового значения в this.value
 */
abstract class Filter<ID extends string = any, V = unknown, E = unknown> {
  private readonly _id: ID;
  private _value: V | null = null;
  protected _defaultValue: V | null = null;
  protected _manager: FiltersManager;

  protected constructor(id: ID, defaultValue: V | null = null) {
    this._id = id;

    this._defaultValue = defaultValue;
    this._value = defaultValue;

    makeObservable<Filter, TPrivateProps>(this, {
      _value: observable,
      value: computed,
      selectedCount: computed,
    });
  }

  // Определяет условие пустого значения фильтра. Фильтры с пустым значением не участвуют в фильтрации
  public abstract get isEmptyValue(): boolean;

  /**
   * Основное правило фильтрации. Данная функция будет применена к каждому элементу массива manager.sourceList
   * Только для внутреннего использования
   */
  public abstract _getFilterRule(item: E): boolean;

  /**
   * Вызывается внутри filterManager при изменении sourceList.
   * Используется для инициализации внутренних значений фильтра которые зависят от sourceList
   */
  public _initState?(): void;

  /**
   * Вызывается внутри filterManager при изменении value в любом фильтре.
   * Используется для обновления внутренних значений фильтра
   */
  public _updateState?(meta?: TUpdateMeta): void;

  /**
   * Взвращает функцию, которая восстанавливает сохраненное состояние
   * @see {@link TempEditor}
   */
  public abstract createMemento(): TFilterMemento;

  /**
   * Сбрасывает значение фильтра
   * @param skipFiltering - пропускает фильтрацию после сброса
   */
  public abstract resetValue(skipFiltering?: boolean): void;

  public get id() {
    return this._id;
  }

  public get value() {
    return this._value;
  }

  // Возвращает кол-во выбранных значений фильтра
  public get selectedCount(): number {
    return this.isEmptyValue ? 0 : 1;
  }

  /**
   * Устанавливает новое значение в фильтр. Вызывает фильтрацию manager.sourceList массива и обновление остальных фильтров
   * @param v - новое значение
   * @param skipFiltering - прорпускает фильтрацию
   */
  public setValue(v: V, skipFiltering = false) {
    this._value = v;

    if (!skipFiltering) {
      this._manager.update({ triggeredBy: 'filter', filterId: this._id });
    }
  }

  public _setManager(manager: FiltersManager) {
    this._manager = manager;
  }
}

export default Filter;
