import * as turf from '@turf/turf';

import MapDrawerStore from '../../../../../../../shared/features/map/modules/MapDrawer/stores/MapDrawer.store';
import { MapDrawerController, MapEventBus } from '../../../../../../../shared/features/map/modules';
import { BasePolygon } from '../../../../../../../shared/features/map/utils/MapElements';
import CulturePolygonValidator from '../../utils/validators/CulturePolygonValidator';
import { CultureZone, Field } from '../../../../../../../../api/models/field.model';
import { CultureModel } from '../../../../../../../../api/models/culture.model';
import { EFieldTooltip } from '../../../../../../constants/FieldTooltip.enum';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { FieldsMapCoreController } from '../../../../mobx/controllers';
import {
  IMapLayerGroupClickPayload,
  IMapLayerGroupConfig,
  IMapPolygonError,
} from '../../../../../../../shared/features/map/models';
import {
  CulturePolygon,
  CulturesLayerGroup,
  HolePolygon,
} from '../../utils/mapElements/CulturesLayerGroup';
import Store from '../stores/FieldCultures.store';

/**
 * Класс отвечает за работу с культурами и картой
 */
@provide.transient()
class FieldCulturesController extends FieldsMapCoreController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  @lazyInject(MapDrawerStore)
  private mapDrawerStore: MapDrawerStore;

  public async renderField(activeField: Field) {
    this.destroy();

    if (!activeField) {
      return;
    }

    const field = this.store.setActiveField(activeField);

    // Инициализируем карту. Делаем связь слой - поле
    await this.buildMap([field], this.getLayerGroupConfig);
    this.registerMapEvents();

    // Force необоходимо из-за того что поле имеет одинаковый id и разную geometry в зависимости от версии
    this.selectField(field, { force: true });

    this.culturesLegendStore.setCultures(field);
    this.layerFillController.setStrategy(EFieldFill.Cultures, field);
    this.layerTooltipController.setContent([EFieldTooltip.Name]);
  }

  public destroy() {
    this.mapCoreController.clear();
    this.store.clear();
  }

  /**
   * Возвращает элемент карты с группой КЗ-полигонов
   */
  public get layerGroup(): CulturesLayerGroup {
    const { activeField } = this.store;

    return this.getLayerGroupByField(activeField) as CulturesLayerGroup;
  }

  /**
   * Возвращает текущий редактируемый КЗ-полигон
   */
  public getEditablePolygon() {
    const editableCultureZoneId = this.store.editableCultureZone?.id;
    return this.layerGroup.getCulturePolygon(editableCultureZoneId);
  }

  /**
   * Удаляет культурную зону из стора и карты
   */
  public deleteCultureZone(zone: CultureZone) {
    this.store.removeCultureZone(zone);
    this.renderLayerGroup();
  }

  /**
   * Рендерит слой согласно последней сохраненной коллекции культурных зон
   */
  public restoreSnapshot() {
    this.store.resetCultureZonesToSnapshot();
    this.renderLayerGroup();
  }

  /**
   * Меняет культуру для культурной зоны. (Обновляет цвет полигона и данные в сторе)
   */
  public changeCultureOfZone(zone: CultureZone, newCulture: CultureModel) {
    this.store.updateCultureZoneValue(zone.id, 'culture', newCulture);
    this.renderLayerGroup();

    this.enableEditCultureZone(zone, false);
  }

  /**
   * Подсвечивает культурную зону на карте и в списке
   */
  public highlightCultureZone(cultureZone: CultureZone) {
    this.store.setSelectedCultureZone(cultureZone);
    this.layerGroup.highlightCulturePolygon(cultureZone.id);
  }

  /**
   * Включает редактирование КЗ-полигона предварительно сохранив текущие КЗ
   */
  public enableEditCultureZone(cultureZone: CultureZone, makeSnapshot = true) {
    const polygon = this.layerGroup.getCulturePolygon(cultureZone.id);
    const currEditablePolygon = this.getEditablePolygon();

    if (!polygon) {
      return;
    }

    if (makeSnapshot) {
      this.store.makeCultureZonesSnapshot();
    }

    this.mapDrawerController.disableEditPolygon(currEditablePolygon);
    this.mapDrawerController.enableEditPolygon(polygon, {
      keepInside: this.layerGroup.getMainPolygon(),
    });

    this.store.setSelectedCultureZone(null);
    polygon.toggleHighlight(false);

    this.store.setEditableCultureZone(cultureZone);
  }

  /**
   * Включает рисование нового КЗ-полигона предварительно сохранив текущие КЗ
   */
  public enableDrawPolygon() {
    this.store.makeCultureZonesSnapshot();

    this.mapDrawerController.enableDrawPolygon({
      continueDrawing: false,
    });
  }

  protected getLayerGroupConfig = (field: Field): IMapLayerGroupConfig => {
    const config = super.getLayerGroupConfig(field);
    config.layerGroup = new CulturesLayerGroup(field);

    return config;
  };

  protected registerMapEvents(): void {
    this.coreStore.mapEventListeners.forEach(listener => listener.off());

    const listener1 = MapEventBus.on('draw.polygon.create', this.handleCreatePolygon);
    const listener2 = MapEventBus.on('draw.polygon.edit', this.handleEditPolygon);
    const listener3 = MapEventBus.on('popup.click', this.handleHolePopupAppendClick);
    const listener4 = MapEventBus.on('layerGroup.click', this.handleLayerGroupClick);

    this.coreStore.setMapEventListeners([listener1, listener2, listener3, listener4]);
  }

  private handleCreatePolygon = (drawnPolygon: BasePolygon) => {
    const intersection = this.cutPolygonAlongFieldBounds(drawnPolygon);
    drawnPolygon.remove();

    if (!intersection) {
      return;
    }

    const createdCultureZone = this.store.addCultureZone(intersection.geometry);
    const hasOverlap = this.store.checkForOverlap(createdCultureZone);

    this.renderLayerGroup();

    if (!hasOverlap) {
      this.enableEditCultureZone(createdCultureZone, false);
    }
  };

  public handleEditPolygon = (polygon: CulturePolygon): IMapPolygonError[] => {
    if (!(polygon instanceof CulturePolygon)) {
      return [];
    }

    const errors = new CulturePolygonValidator(polygon, this.layerGroup)
      .checkIntersections()
      .resetToLastValidGeometry();

    if (errors.length) {
      return errors;
    }

    this.store.updateCultureZoneGeometry(polygon);
    this.layerGroup.renderHolePolygons();
    polygon.bringToFront();

    return errors;
  };

  private handleHolePopupAppendClick = (polygon: HolePolygon) => {
    if (this.store.editableCultureZone) {
      return;
    }

    this.store.makeCultureZonesSnapshot();

    const cultureZone = this.store.addCultureZone(polygon.getInfo().geometry);
    this.renderLayerGroup();

    this.enableEditCultureZone(cultureZone, false);
  };

  private handleLayerGroupClick = (payload: IMapLayerGroupClickPayload) => {
    const polygon = payload.clickedPolygon;

    if (this.store.editableCultureZone || this.mapDrawerStore.isDrawEnabled) {
      return;
    }

    if (polygon instanceof CulturePolygon) {
      this.highlightCultureZone(polygon.dataModel);
    }
  };

  private cutPolygonAlongFieldBounds(polygon: BasePolygon) {
    const fieldPolygon = this.layerGroup.getMainPolygon();
    return turf.intersect(fieldPolygon.toTurf(), polygon.toTurf());
  }

  /**
   * Перерисовывает КЗ-полигоны на основе геометрий культурных зон из стора
   * Перерисовывает полигоны-дырки
   */
  private renderLayerGroup() {
    const { culturesZonesList } = this.store;

    this.layerGroup.renderCulturePolygons(culturesZonesList);
    this.layerGroup.renderHolePolygons();
  }
}

export default FieldCulturesController;
