import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

export const NO_CHECKLIST_FOUND_MODAL_ID = 'noChecklistFoundModalId';

export const noChecklistFoundModalConfig: TModalConfig = {
  id: NO_CHECKLIST_FOUND_MODAL_ID,
  name: NO_CHECKLIST_FOUND_MODAL_ID,
  type: EModalType.Warning,
  dataTestId: NO_CHECKLIST_FOUND_MODAL_ID,
  title: 'Шаблон чек-листа не найден',
  description: {
    value:
      'Мы не нашли чек-лист, который можно применить к этому типу операции и культуре. Обратитесь к администратору, чтобы его настроить',
    $styles: {
      textAlign: 'center',
      letterSpacing: '-0.1px;',
      marginBottom: '36px',
      wordBreak: 'break-word',
    },
  },
  styledProps: {
    $size: EModalSize.Medium,
  },
  successButton: {
    title: 'Понятно',
    color: 'primary',
  },
};
