import { Geometry } from '@turf/turf';
import { PolylineOptions } from 'leaflet';
import ReactDOM from 'react-dom';

import { MapEventBus } from '../../../../../../../../../shared/features/map/modules';
import { BasePolygon } from '../../../../../../../../../shared/features/map/utils/MapElements/polygons';

import { CreateZonePopup } from './CreateZonePopup/CreateZonePopup';

class HolePolygon extends BasePolygon {
  constructor(geometry: Geometry, options?: PolylineOptions) {
    super(geometry, { snapIgnore: true, ...options });

    this.registerPopup();
  }

  public setPopup(value: boolean) {
    if (value) {
      this.registerPopup();
    } else {
      this.unbindPopup();
    }
  }

  private registerPopup() {
    if (this.getPopup()) {
      return;
    }

    const popupNode = document.createElement('div');

    ReactDOM.render(
      <CreateZonePopup
        onAccept={() => {
          MapEventBus.emit('popup.click', this);
          this.closePopup();
        }}
        onDeny={() => this.closePopup()}
      />,
      popupNode
    );

    this.bindPopup(popupNode, {
      autoClose: false,
      closeButton: false,
      className: 'hole-popup',
      interactive: false,
    });
  }
}

export default HolePolygon;
