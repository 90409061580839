import styled from 'styled-components';

const Wrapper = styled.div`
  pointer-events: all;
`;

const Overlay = styled('div')({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 9999,
});

const Styled = {
  Wrapper,
  Overlay,
};

export default Styled;
