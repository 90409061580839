import { IMapLayerGroupClickPayload } from '../../../../../../../shared/features/map/models';
import { MapEventBus } from '../../../../../../../shared/features/map/modules';
import { EFieldTooltip } from '../../../../../../constants/FieldTooltip.enum';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { WeatherController } from '../../components/WeatherSlider/mobx';
import { Field } from '../../../../../../../../api/models/field.model';
import { FieldsMapCoreController } from '../../../../mobx';
import { EFieldsMode } from '../../../../utils';
import Store from '../stores/FieldsView.store';
import { ELeftPanelType } from '../../interfaces/LeftPanelType.enum';

@provide.transient()
class FieldsViewController extends FieldsMapCoreController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(WeatherController)
  protected weatherController: WeatherController;

  public async initialize() {
    this.store.isInitialize = true;

    this.coreStore.fieldsMode = EFieldsMode.DISPLAY;

    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.fieldsApiService.fetchFieldsList(null, true);

    // Подготовка карты. Центрирование
    this.centerMapToFieldBounds(this.fieldToCenter ?? fieldsList[0]);
    this.registerMapEvents();

    this.registerFieldsCacheEvent();

    // Инициализируем фильтр
    this.setSourceList(fieldsList);

    // Устанавливаем левый сайдбар
    this.store.leftPanelType = ELeftPanelType.FIELDS_LIST;

    this.store.isInitialize = false;
  }

  public destroy() {
    super.destroy();
    this.weatherController.close();
    this.store.filterManager?.emitter.removeAllListeners();
  }

  public async rerenderMap(fieldsList: Field[], selectFirst = false) {
    await this.buildMap(fieldsList, this.getLayerGroupConfig);

    this.setMapControls(fieldsList);

    if (selectFirst) {
      this.selectField(fieldsList[0]);
    }
  }

  public selectField(field: Field | null) {
    if (!field || this.coreStore.isSelectedField(field)) {
      return;
    }

    super.selectField(field);

    void this.layerFillController.setStrategy('last', field);
    void this.weatherController.loadWeather(field.id, true);
  }

  public deselectField() {
    super.deselectField();
    this.layerFillController.setStrategy(EFieldFill.Cultures);
  }

  public deleteField(field: Field, seasonYear: number): Promise<Field> {
    return super.deleteField(field, seasonYear).then(res => {
      // Обновляем фильтр
      const { fieldsList } = this.fieldsStore;
      this.store.filterManager.updateSourceList(fieldsList);

      this.weatherController.close();

      return res;
    });
  }

  protected registerMapEvents() {
    const listener1 = MapEventBus.on('layerGroup.click', this.handleLayerGroupClick);
    const listener2 = MapEventBus.on('layerGroup.cancelSelected', () => this.deselectField());

    this.coreStore.setMapEventListeners([listener1, listener2]);
  }

  private registerFieldsCacheEvent() {
    const listener = this.fieldsStore.emitter.on(
      'invalidate_cache',
      this.handleInvalidateFieldsCache
    );

    this.coreStore.mapEventListeners.push(listener);
  }

  protected handleLayerGroupClick = (payload: IMapLayerGroupClickPayload) => {
    const field = this.getFieldByLayerGroup(payload.layerGroup);
    this.selectField(field);
  };

  private handleInvalidateFieldsCache = (newFields: Field[]) => {
    const { filterManager } = this.store;

    // после вызова сработает filterManager.emitter и вызовется рендер элементов карты
    filterManager.updateSourceList(newFields);
  };

  private setSourceList(fieldsList: Field[]) {
    const { filterManager } = this.store;

    filterManager.clear();
    filterManager.emitter.removeAllListeners('changeFilteredList');

    filterManager.emitter.on('changeFilteredList', (list, trigger) => {
      void this.rerenderMap(list, trigger !== 'updateSourceListFn');
    });

    // после вызова сработает filterManager.emitter и вызовется рендер элементов карты
    filterManager.updateSourceList(fieldsList);
  }

  private setMapControls(fieldsList: Field[]) {
    void this.layerFillController.setStrategy(EFieldFill.Cultures);
    this.layerTooltipController.setContent([EFieldTooltip.Name]);
    this.culturesLegendStore.setCultures(fieldsList);
  }
}

export default FieldsViewController;
