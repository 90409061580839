import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../constans/sizes';

interface IBaseProps {
  isOpen: boolean;
}

interface ITextProps {
  selected: boolean;
}

const Body = styled('div')<IBaseProps>({
  position: 'absolute',

  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  width: 160,
  padding: '10px 12px 12px',

  borderBottomLeftRadius: ESpaceSize.M,
  borderBottomRightRadius: ESpaceSize.M,

  backgroundColor: Colors.generalWhite,
  outline: 'none',
});

const Option = styled('div')({
  display: 'flex',
  gap: 8,

  borderRadius: ESpaceSize.S,
  cursor: 'pointer',

  padding: '4px 8px',
  marginLeft: -8,
  marginRight: -8,

  ...FontSizesObj.medium,

  '& span': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  '&:last-child': {
    marginBottom: 0,
  },

  '&:hover': {
    backgroundColor: Colors.technicalBgGrey,
  },
});

const Text = styled('span')<ITextProps>({}, ({ selected }) => ({
  color: selected ? Colors.accentGreen : Colors.generalBlack,

  ...FontSizesObj.medium,
}));

const Divider = styled('div')({
  width: '100%',
  height: '1px',
  position: 'relative',
  backgroundColor: Colors.generalWhite,

  '&:after': {
    content: "''",
    position: 'absolute',
    left: ESpaceSize.M,
    right: ESpaceSize.M,
    zIndex: 101,
    height: '1px',
    backgroundColor: Colors.borderShadowGray,
  },
});

export default {
  Body,
  Text,
  Option,
  Divider,
};
