import { generatePath } from 'react-router-dom';
import { ITabsProps } from '@farmlink/farmik-ui';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TasksParams } from '../../../../../models';
import { TasksRoute } from '../../../../../tasks.route';
import { TaskOperationCreateStore as Store } from '../../stores';
import { ArrayElement } from '../../../../../../../../shared/utils/types/ArrayElement';

type TTab = ArrayElement<ITabsProps['content']>;

@provide.transient()
class TaskOperationCreateTabsService {
  @lazyInject(Store)
  protected store: Store;

  public get pathListWithoutModalWarning(): string[] {
    return this.store.tabList.map(({ id }) => id);
  }

  public initiateTabs = (params: Pick<TasksParams, 'orgId'>): void => {
    const tabList: TTab[] = [
      this.createCommonInfoTab(params.orgId),
      this.createFieldsTab(params.orgId),
      this.createVerificationTab(params.orgId),
    ];

    this.store.setTabList(tabList);
  };

  public toggleTabsIsDisableState = (): void => {
    const [, fields, verification] = this.store.tabList;

    this.store.setTabList(prev => {
      return prev.map(tab => {
        if (tab.id === fields.id) {
          return {
            ...tab,
            disabled: this.getFieldsIsDisableState(),
          };
        }

        if (tab.id === verification.id) {
          return {
            ...tab,
            disabled: this.getVerificationIsDisableState(),
          };
        }

        return tab;
      });
    });
  };

  protected createCommonInfoTab = (orgId: string): TTab => {
    return {
      label: 'Постановка',
      id: generatePath(TasksRoute.TaskOperationCreateCommonInfo, { orgId }),
      disabled: false,
    };
  };

  protected createFieldsTab = (orgId: string): TTab => {
    return {
      label: 'Поля',
      id: generatePath(TasksRoute.TaskOperationCreateFields, { orgId }),
      disabled: true,
    };
  };

  protected createVerificationTab = (orgId: string): TTab => {
    return {
      label: 'Проверка',
      id: generatePath(TasksRoute.TaskOperationCreateVerification, { orgId }),
      disabled: true,
    };
  };

  protected getFieldsIsDisableState = (): boolean => {
    const { cultureId, operationId, operationValues } = this.store.taskCreate;

    const hasFilledConfig = this.store.config ? Boolean(operationValues?.length) : true;

    return !(Boolean(cultureId) && Boolean(operationId) && hasFilledConfig);
  };

  protected getVerificationIsDisableState = (): boolean => {
    const { cultureId, operationId, taskItems, operationValues } = this.store.taskCreate;

    const hasFilledConfig = this.store.config ? Boolean(operationValues?.length) : true;

    return !(
      Boolean(cultureId) &&
      Boolean(operationId) &&
      Boolean(taskItems.length) &&
      hasFilledConfig
    );
  };
}

export default TaskOperationCreateTabsService;
