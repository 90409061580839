import { generatePath } from 'react-router-dom';

import { lazyInject, provide } from '../../../../../../../../../../../../../../shared/utils/IoC';
import {
  StoChecklistsTableConfigsService as TableConfigsService,
  StoChecklistsTableChangesService as TableChangesService,
} from '../../services';
import { StoChecklistsCoreStore as CoreStore } from '../../stores';
import { IComparisonTableBuilderConfig } from '../../../../../../../../../../../../../../shared/features/ComparisonTableBuilder/models/configs';
import { StoService } from '../../../../../../../../../../../../../../shared/mobx/services/as-fields';
import { IStoParams } from '../../../../../../../../../../../models';
import useStoNavigation from '../../../../../../../../../../../hooks/useStoNavigation/useStoNavigation';

@provide.transient()
class StoChecklistsCoreController {
  @lazyInject(CoreStore)
  protected coreStore: CoreStore;

  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(TableConfigsService)
  protected tableConfigsService: TableConfigsService;

  @lazyInject(TableChangesService)
  protected tableChangeService: TableChangesService;

  public getPathListWithoutModalWarning = (
    params: Pick<IStoParams, 'orgId' | 'stoId' | 'operationId'>
  ): string[] => {
    const { checklistList } = this.coreStore;

    return checklistList.map(({ id }) =>
      generatePath(`/:orgId/sto/:stoId/operations/:operationId/checklists/:checklistId`, {
        ...params,
        checklistId: id,
      })
    );
  };

  public checkIfChecklistIsSelected = (checklistId: string): boolean => {
    return this.coreStore.getChecklistUpdate(checklistId)?.isSelected;
  };

  public checkIfAttrIsSelected = (
    checklistId: string,
    stageId: string,
    attrId: string
  ): boolean => {
    return this.coreStore.getAttrUpdate(checklistId, stageId, attrId)?.isSelected;
  };

  public checkIfAttrIsUnsaved = (checklistId: string, stageId: string, attrId: string): boolean => {
    return this.coreStore.getAttrUpdate(checklistId, stageId, attrId)?.technicalProps?.isUnsaved;
  };

  public checkIfChecklistSwitcherIsDisabled = (currentChecklistId: string): boolean => {
    const { checklistUpdateList } = this.coreStore;

    const selectedCheckListList = checklistUpdateList.filter(({ isSelected }) => isSelected);
    const [firstCheckList] = selectedCheckListList;

    return selectedCheckListList.length === 1 && firstCheckList.id === currentChecklistId;
  };

  public changeChecklist: TableChangesService['changeChecklist'] = (checklistId, data) => {
    this.tableChangeService.changeChecklist(checklistId, data);
  };

  public changeAttr: TableChangesService['changeAttr'] = (checklistId, stageId, data) => {
    this.tableChangeService.changeAttr(checklistId, stageId, data);
  };

  public initiatePage = async (
    params: IStoParams,
    navigations: ReturnType<typeof useStoNavigation>
  ): Promise<void> => {
    const tableList = await this.initiateTables({
      id: params.stoId,
      operationTypeId: params.operationId,
      ...(params.versionId && { ...(params.versionId && { versionId: params.versionId }) }),
    });

    if (!tableList.length) return;

    if (params.checklistId) {
      const alreadySelectedTable = tableList.find(table => table.id === params.checklistId);

      if (!alreadySelectedTable) return;

      if (params.stageId && params.attributeId) {
        navigations.navigateToAttribute(params, {
          query: { ...(params.versionId && { versionId: params.versionId }) },
        });

        return;
      }

      navigations.navigateToChecklist(params, {
        query: { ...(params.versionId && { versionId: params.versionId }) },
      });

      return;
    }

    const [firstTable] = tableList;

    navigations.navigateToChecklist(
      {
        ...params,
        checklistId: firstTable.id,
      },
      {
        query: { ...(params.versionId && { versionId: params.versionId }) },
      }
    );
  };

  public initiateTables = async (
    payload: Parameters<TableConfigsService['initiateTables']>[0]
  ): Promise<IComparisonTableBuilderConfig[]> => {
    const createdTableList = await this.tableConfigsService.initiateTables(payload);

    this.coreStore.setIsLoading(false);

    return createdTableList;
  };

  public saveStoChecklistsAttrsTables = async ({
    stoId,
    operationId,
    successHandler,
    loadingHandler,
  }: {
    stoId: string;
    operationId: string;
    successHandler: () => void;
    loadingHandler: (isLoading: boolean) => void;
  }): Promise<void> => {
    const formattedChecklistList = this.tableChangeService.getFormattedChecklistList();

    await this.stoService.saveStoChecklistsAttrsTables(
      {
        id: stoId,
        operationTypeId: operationId,
        checklists: formattedChecklistList,
      },
      {
        actions: {
          handleLoading: loadingHandler,
          handleSuccess: () => successHandler(),
        },
      }
    );
  };

  public clearStore = (): void => {
    this.coreStore.clearIsLoading();
    this.coreStore.clearHasChanges();
    this.coreStore.clearChecklistsById();
    this.coreStore.clearChecklistsUpdateById();
    this.coreStore.clearStagesUpdateByIdByChecklistId();
    this.coreStore.clearAttrsUpdateByIdByStageIdByChecklistId();
  };
}

export default StoChecklistsCoreController;
