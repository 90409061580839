import escapeRegExp from 'lodash/escapeRegExp';

function useTextHighlighter(inputValue: string) {
  return (option: string) => {
    const saveValue = escapeRegExp(inputValue);
    const re = new RegExp(saveValue, 'i');

    return option.replace(re, `<b>$&</b>`);
  };
}

export default useTextHighlighter;
