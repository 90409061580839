import { FC, memo } from 'react';

import fieldSvg from '../../../../../../operations/components/TaskView/assets/fieldSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  fieldName: string;
  dataTestId: string;
}
const TasksDrawerFieldName: FC<IProps> = ({ fieldName, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__field-name`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label dataTestId={getDataTestId('label')['data-test-id']}>Поле</Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={fieldSvg} />

      <Styled.Info dataTestId={getDataTestId('info')['data-test-id']}>{fieldName}</Styled.Info>
    </Styled.InfoBlock>
  );
};

TasksDrawerFieldName.displayName = 'TasksDrawerFieldName';

export default memo(TasksDrawerFieldName);
