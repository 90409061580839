import { useEffect, useMemo } from 'react';

import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { ProfileStore } from '../../../profile/stores/ProfileStore';

const useTaskDataConsistency = () => {
  const fields = useStore(FieldsStore);
  const seasons = useStore(SeasonsStore);
  const profile = useStore(ProfileStore);
  const organizations = useStore(OrganizationsStore);

  const isFieldsLoaded = useMemo(() => {
    if (fields.isLoading) return false;

    return fields.fieldsLoaded;
  }, [fields.isLoading, fields.fieldsLoaded]);

  const isSeasonLoaded = useMemo(() => {
    return seasons.isLoaded;
  }, [seasons.isLoaded]);

  const isProfileLoaded = useMemo(() => {
    return profile.isLoaded;
  }, [profile.isLoaded]);

  const isOrganizationLoaded = useMemo(() => {
    return (
      Boolean(organizations.currentOrganization) || organizations.selectedOrganizationId === 'my'
    );
  }, [organizations.currentOrganization]);

  useEffect(() => {
    const noConsistenceList = [];

    if (!isSeasonLoaded) {
      noConsistenceList.push('seasons');
    }

    if (!isFieldsLoaded) {
      noConsistenceList.push('fields');
    }

    if (!isProfileLoaded) {
      noConsistenceList.push('profile');
    }
    if (!isOrganizationLoaded) {
      noConsistenceList.push('organization');
    }

    if (noConsistenceList.length) {
      console.warn('Обнаружены не консистентные данные при загрузке задач:', noConsistenceList);
    }
  }, [isFieldsLoaded, isSeasonLoaded, isProfileLoaded, isOrganizationLoaded]);

  const isTaskDataConsistency =
    isFieldsLoaded && isSeasonLoaded && isProfileLoaded && isOrganizationLoaded;

  return {
    isFieldsLoaded,
    isSeasonLoaded,
    isProfileLoaded,
    isOrganizationLoaded,
    isTaskDataConsistency,
  };
};

export default useTaskDataConsistency;
