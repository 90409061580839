import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import Styled from './Skeleton.styles';

const LeftPanelHeaderSkeleton: FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.TextWrapper>
        <Skeleton className={'text'} width={120} height={12} duration={0} />
        <Skeleton className={'text'} width={60} height={12} duration={0} />
      </Styled.TextWrapper>

      <Styled.IconsWrapper>
        <Skeleton className={'text'} width={18} height={18} duration={0} />
        <Skeleton className={'text'} width={18} height={18} duration={0} />
        <Skeleton className={'text'} width={18} height={18} duration={0} />
      </Styled.IconsWrapper>
    </Styled.Wrapper>
  );
};

export default LeftPanelHeaderSkeleton;
