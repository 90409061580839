import { Typography } from '@farmlink/farmik-ui';

const WarningBeforeLeavingModalTitle = () => {
  return (
    <Typography variant={'title'} align={'center'}>
      Если покинуть экран, несохранённые данные будут потеряны
    </Typography>
  );
};

export default WarningBeforeLeavingModalTitle;
