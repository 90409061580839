import { Button, useModal } from '@farmlink/farmik-ui';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { SessionStore } from '../../../../../../../../../authorization/stores/session.store';
import useFieldRoutes from '../../../../../../hooks/useFieldsRoutes';
import { ButtonLoader } from '../../../../../../../../../shared/components/ButtonLoader/ButtonLoader';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { FieldsMapCoreStore } from '../../../../../../mobx';
import { EFieldsImportModalName } from '../../../../utils/constants';
import { FieldsImportController as Controller, FieldsImportStore as Store } from '../../../../mobx';

import Styled from './Footer.styles';

const FieldsImportListFooter: FC = () => {
  // Навигация
  const navigate = useNavigate();
  const routes = useFieldRoutes();

  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const store = useStore(Store);
  const sessionStore = useStore(SessionStore);
  const fieldsMapCoreStore = useStore(FieldsMapCoreStore);

  const modal = useModal();

  const handleClose = (): void => {
    controller.removeImportedFields();
    controller.destroy();
    navigate(routes.fieldsViewRoute);
  };

  const handleSave = () => {
    controller.saveImportedFieldList().then(res => {
      if (Array.isArray(res)) {
        modal.closeModal();
        navigate(routes.fieldsViewRoute);

        // Добавляем сохраненные поля в список новых. Они будут отображены вначале списка
        fieldsMapCoreStore.setNewFields(res);
      }
    });
  };

  useEffect(() => {
    if (store.saveErrorModal === EFieldsImportModalName.FileImport) {
      modal.openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: true });
      store.clearSaveErrorModal();

      navigate(routes.fieldsViewRoute);
      return;
    }

    if (store.saveErrorModal === EFieldsImportModalName.NotAuthorized) {
      sessionStore.logout();
      return;
    }

    if (store.saveErrorModal) {
      modal.openModalByModalId(store.saveErrorModal);
      store.clearSaveErrorModal();
    }
  }, [store.saveErrorModal]);

  return (
    <React.Fragment>
      <Styled.ButtonWrapper>
        <Button
          type={'button'}
          color={'default'}
          onClick={handleClose}
          dataTestId={'fields-import-cancel'}
        >
          Отменить
        </Button>
      </Styled.ButtonWrapper>

      <Styled.ButtonWrapper>
        <ButtonLoader
          disabled={store.hasErrors}
          isLoading={store.isWaitingForEditRes}
          onClick={handleSave}
        >
          Сохранить
        </ButtonLoader>
      </Styled.ButtonWrapper>
    </React.Fragment>
  );
};

export default observer(FieldsImportListFooter);
