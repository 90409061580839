import { FC, memo, useMemo } from 'react';

import operationValuesSvg from '../../../../../../operations/components/TaskView/assets/operationValuesSvg.svg';
import { IOperationValue } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';
interface IProps {
  label: string;
  values: IOperationValue[];
  dataTestId: string;
}
const TasksDrawerTaskOperationType: FC<IProps> = ({ label, values, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__task-operation-value`);

  const taskOperationValuesInfo = useMemo(() => {
    if (!values) return;

    return values?.map(value => value.dictionaryValueName).join(', ');
  }, [values]);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label dataTestId={getDataTestId('label')['data-test-id']}>{label}</Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-svg')} src={operationValuesSvg} />

      <Styled.Info dataTestId={getDataTestId('info')['data-test-id']}>
        {taskOperationValuesInfo}
      </Styled.Info>
    </Styled.InfoBlock>
  );
};

TasksDrawerTaskOperationType.displayName = 'TasksDrawerTaskOperationValue';

export default memo(TasksDrawerTaskOperationType);
