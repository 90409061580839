import { observer } from 'mobx-react';
import { FC } from 'react';

import { useStore } from '../../../../../shared/utils/IoC';
import STOFiltersController from '../../containers/STOFiltersContainer/mobx/controllers/STOFiltersController/STOFilters.controller';
import { FiltersEntityCounter } from '../../../../../shared/features/UI/new/TasksCounter';
import { FiltersEntityCounterModes } from '../../../../../shared/features/UI/new/TasksCounter/FiltersEntityCounter';

interface IProps {
  dataTestId: string;
}

const STOFiltersEntityCounter: FC<IProps> = ({ dataTestId }) => {
  const controller = useStore(STOFiltersController);
  const currentCount = controller.currentSTOCount;
  const totalCount = controller.totalSTOCount;

  if (!totalCount) return null;

  return (
    <FiltersEntityCounter
      mode={FiltersEntityCounterModes.Sto}
      dataTestId={dataTestId}
      count={currentCount}
      totalCount={totalCount}
    />
  );
};

export default observer(STOFiltersEntityCounter);
