import { makeAutoObservable, runInAction } from 'mobx';

import { CultureZone, Field } from '../../../../../api/models/field.model';
import { provide } from '../../../utils/IoC';
import { EImportedFieldErrorType } from '../../../../../api/models/fields/getImportedField.model';
import { TypedEventEmitter } from '../../../utils/helpers/TypedEventEmitter';

/**
 * Данный стор хранит информацию о загруженных с бэка полях.
 */
@provide.singleton()
class FieldsStore {
  // Коллекция полей которые отображаются в правом списке
  private _fieldsCollection = new Map<string, Field>();

  private _isLoading = false;

  private _fieldsLoaded = false;

  private _emitter = new TypedEventEmitter();

  constructor() {
    makeAutoObservable(this);
  }

  get fieldsList() {
    return Array.from(this._fieldsCollection.values());
  }

  get fieldsCultureZonesList(): CultureZone[] {
    return this.fieldsList.flatMap(field => {
      return (field.cultureZones ?? []).map(zone => ({ ...zone, fieldId: field.id }));
    });
  }

  get fieldsCount() {
    return this._fieldsCollection.size;
  }

  get hasFields() {
    return Boolean(this.fieldsCount);
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  get fieldsLoaded() {
    return this._fieldsLoaded;
  }

  set fieldsLoaded(value) {
    this._fieldsLoaded = value;
  }

  get emitter() {
    return this._emitter;
  }

  getFieldById = (fieldId: string | number): Field | undefined => {
    return this._fieldsCollection.get(String(fieldId));
  };

  setErrorToFieldById = (
    id: string | number,
    errorData: { error: boolean; errorType: EImportedFieldErrorType }
  ) => {
    const field = { ...this.getFieldById(id), ...errorData };
    this.setField(field);
  };

  /**
   * Очищает коллекцию полей и сетает новую
   * Используется только внутри FieldsAPIService.
   * Вызов данного метода означает что кэш полей невалидный и поля будут обновлены новыми из аргумента.
   * Триггерит событие инвалидации кэша. В текущей версии за инвалидацией кэша следят: FieldsViewController
   * @param fields - новый список полей
   * @param skipInvalidationEvent - пропускает отправку event в emitter
   */
  setAllFields = (fields: Field[], skipInvalidationEvent = false) => {
    runInAction(() => {
      this.clearFieldsById();

      fields.forEach(field => {
        this._fieldsCollection.set(field.id, field);
      });

      if (!skipInvalidationEvent) {
        this.emitter.emit('invalidate_cache', this.fieldsList);
      }
    });
  };

  /**
   * Добавляет каждому полю id элемента карты.
   */
  attachMapElements = (elementsList: { element: Field; layerGroup: { id: number } | null }[]) => {
    runInAction(() => {
      elementsList.forEach(({ element, layerGroup }) => {
        element.polyId = layerGroup?.id;
        this.setField({ ...element, polyId: layerGroup?.id });
      });
    });
  };

  // Добавляет одно значение в коллекцию
  setField = (field: Field) => {
    this._fieldsCollection.set(field.id, field);
  };

  hasField = (fieldId: number | string) => {
    return this._fieldsCollection.has(String(fieldId));
  };

  deleteFieldById = (fieldId: string | number) => {
    this._fieldsCollection.delete(String(fieldId));
  };

  clearFieldsById = () => {
    this._fieldsCollection.clear();
  };
}

export default FieldsStore;
