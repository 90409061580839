import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

// TODO: переделать через @farmik
const DropdownButtonWrapper = styled('div')({
  width: '100%',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  padding: ESpaceSize.M,

  borderTop: `1px solid ${Colors.technicalBorderGrey}`,

  '& button > div:nth-child(1)': {
    height: '40px !important',
    borderTopLeftRadius: ESpaceSize.M,
    borderBottomLeftRadius: ESpaceSize.M,
  },
  '& button > div:nth-child(2)': {
    height: '40px !important',
    flexBasis: '40px',
    borderTopRightRadius: ESpaceSize.M,
    borderBottomRightRadius: ESpaceSize.M,
  },
});

const SeasonButtonWrapper = styled('div')({
  width: '100%',
  height: '64px',
  padding: ESpaceSize.M,
  borderTop: `1px solid ${Colors.technicalBorderGrey}`,

  '& > button': {
    height: '40px',
    cursor: 'pointer',
    borderRadius: ESpaceSize.M,
  },
});

const Styles = {
  SeasonButtonWrapper,
  DropdownButtonWrapper,
};

export default Styles;
