import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';

import Fullscreen from '../../containers/fullscreen';
import { OperationTaskTypeIcon } from '../../../../../shared/components/OperationTaskTypeIcon';
import { ETaskAction, ETaskType } from '../../../../../../api/models/as-fields/task/task.model';
import { useDataTestIdV2 } from '../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStatusPill } from '../../components/TaskStatusPill';
import { useTasksBackButtonData } from '../../hooks/useTasksBackButtonData';
import { useTasksParams } from '../../hooks';
import { ContentLoader } from '../../../../../shared/features/UI/loaders/ContentLoader';

import { TaskOperationViewStore as Store } from './mobx/stores';
import { TaskOperationViewController as Controller } from './mobx/controllers';
import Styled from './TaskOperatioView.styles';
import { TaskOperationViewCommonInfo } from './modules';
import operationDeletionModalConfig, {
  TASK_OPERATION_DELETION_MODAL,
} from './modals/DeletionModal/DeletionModal.config';

const TaskOperationView: FC = () => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const modalActions = useModal();
  const notificator = useNotificator();
  const backButtonData = useTasksBackButtonData();
  const params = useTasksParams();
  const getDataTestId = useDataTestIdV2('tasks__task-operation-view');

  modalActions.registerModalList([operationDeletionModalConfig], TASK_OPERATION_DELETION_MODAL);

  useEffect(() => {
    controller.initiatePage(params);

    return () => controller.clearStore();
  }, []);

  if (store.isPageLoading)
    return (
      <ContentLoader
        overlayStyles={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
      />
    );

  const title = store.selectedTask?.operationInfo.name;
  const isDeleteAllowed = store.selectedTask?.availableActions.some(
    el => el === ETaskAction.TaskDelete
  );

  const handleDeleteButtonClick = () => {
    modalActions.openModalByModalId(TASK_OPERATION_DELETION_MODAL, {}, () => {
      controller.deleteTask();

      notificator.setNotification({
        message: `Вы удалили регистрацию`,
        style: {
          type: ENotificationType.Warning,
          theme: ENotificatorTheme.Dark,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
        },
      });

      backButtonData.handler();
    });
  };

  return (
    <Fullscreen
      backButtonText={
        <Styled.TitleWrapper>
          <Styled.Title {...getDataTestId('title')}>{title}</Styled.Title>

          <OperationTaskTypeIcon
            taskType={ETaskType.Operation}
            dataTestId={getDataTestId()['data-test-id']}
            $iconSize={'24px'}
            $margin={'0 12px'}
          />

          <TaskStatusPill
            status={store.selectedTask?.status}
            eventList={store.statusPillEventList}
          />
        </Styled.TitleWrapper>
      }
      rightSideContent={
        <Styled.TitleButton
          onClick={handleDeleteButtonClick}
          disabled={!isDeleteAllowed}
          color={'secondary'}
          dataTestId={getDataTestId('title-button')['data-test-id']}
        >
          Удалить
        </Styled.TitleButton>
      }
      mode={'view'}
      backButtonHandler={backButtonData.handler}
      arrowText={backButtonData.arrowText}
    >
      <TaskOperationViewCommonInfo />
    </Fullscreen>
  );
};

TaskOperationView.displayName = 'TaskOperationView.';

export default observer(TaskOperationView);
