import { Badge, EBadgeSize, Icon } from '@farmlink/farmik-ui';
import { Listbox } from '@headlessui/react';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { EFieldTooltip } from '../../../../../../../dashboard/constants/FieldTooltip.enum';
import { ESelectType, SelectWithCustomButton } from '../../../../../../components';
import { Field } from '../../../../../../../../api/models/field.model';
import { FontSizesObj } from '../../../../../../constans/sizes';
import { useStore } from '../../../../../../utils/IoC';

import { LayerTooltipController as Controller, LayerTooltipStore as Store } from './mobx';
import Styled from './LayerTooltip.styles';

const OPTIONS = [
  { label: 'Площадь', value: EFieldTooltip.Area },
  { label: 'Название', value: EFieldTooltip.Name },
  { label: 'Культура', value: EFieldTooltip.Culture },
];

interface IProps {
  // Список всех полей к которым будут применены тултипы в случаях когда не передано конкретное поле через метод controller.setContent()
  allFields: Field[];
}

const LayerTooltipControl: FC<IProps> = props => {
  // Сторы
  const store = useStore(Store);

  // Контроллеры
  const controller = useStore(Controller);

  const setTooltipContent = (values: EFieldTooltip[]) => {
    controller.setContent(values);
  };

  useEffect(() => {
    store.setAllFields(props.allFields);
  }, [props.allFields]);

  return (
    <Styled.Wrapper>
      <SelectWithCustomButton
        type={ESelectType.MULTISELECT}
        label={'Подпись'}
        value={store.values}
        optionList={OPTIONS}
        renderButton={value => (
          <Badge
            content={value.length}
            size={EBadgeSize.x14}
            position={{ top: 3, right: 3 }}
            style={FontSizesObj.small}
            hideEmpty
          >
            <Listbox.Button as={Styled.SmallButton}>
              <Icon icon={'text'} size={'medium'} />
            </Listbox.Button>
          </Badge>
        )}
        onChange={setTooltipContent}
      />
    </Styled.Wrapper>
  );
};

export default observer(LayerTooltipControl);
