import { PathOptions } from 'leaflet';
import { cloneDeep } from 'lodash';

import { BasePolygon } from '../MapElements';

class LayerHoverHandler {
  private hoverStyle: PathOptions;
  private _beforeHoverOptions: PathOptions;

  public skipHandle = false;

  private onMouseOverHandler: () => void | null = null;
  private onMouseOutHandler: () => void | null = null;

  constructor(private layer: BasePolygon) {}

  public register(hoverStyle: PathOptions) {
    this.hoverStyle = hoverStyle;

    this.layer.off('mouseover', this.onMouseOver);
    this.layer.off('mouseout', this.onMouseOut);

    this.layer.on('mouseover', this.onMouseOver);
    this.layer.on('mouseout', this.onMouseOut);
  }

  public setHandlers(overFn: () => void, outFn: () => void) {
    if (typeof overFn === 'function') {
      this.onMouseOverHandler = overFn;
    }

    if (typeof overFn === 'function') {
      this.onMouseOutHandler = outFn;
    }
  }

  public get beforeHoverOptions() {
    return this._beforeHoverOptions;
  }

  private onMouseOver = () => {
    if (this.skipHandle) {
      return;
    }

    this._beforeHoverOptions = cloneDeep(this.layer.options);

    this.layer.bringToFront();
    this.layer.setStyle(this.hoverStyle);

    this.onMouseOverHandler?.();
  };

  private onMouseOut = () => {
    if (this.skipHandle) {
      return;
    }

    this.layer.setStyle(this._beforeHoverOptions);

    this._beforeHoverOptions = null;
    this.onMouseOutHandler?.();
  };
}

export default LayerHoverHandler;
