import { NewScrollbarV2 } from '@farmlink/farmik-ui';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { ELeftPanelType } from '../../../../interfaces/LeftPanelType.enum';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../../../mobx';
import LeftPanelHeader from '../Header/Header';

import FieldCultureFilterSection from './sections/FieldCultureFilter/FieldCultureFilter';
import FieldInExpFilterSection from './sections/FieldInExpFilter/FieldInExpFilter';
import FieldAreaFilterSection from './sections/FieldAreaFilter/FieldAreaFilter';
import FieldNameFilterSection from './sections/FieldNameFilter/FieldNameFilter';
import FiltersFooter from './components/Footer/Footer';
import SorterSection from './sections/Sort/Sort';
import Styled from './Filters.styles';

const FOOTER_HEIGHT = 108;
const HEADER_HEIGHT = 63;

const Filters: FC = () => {
  // Сторы
  const store = useStore(Store);

  const handleBackClick = () => {
    store.filterManager.tempEditor.discardChanges();
    store.leftPanelType = ELeftPanelType.FIELDS_LIST;
  };

  useEffect(() => {
    return () => {
      store.filterManager.tempEditor.discardChanges();
    };
  }, []);

  return (
    <Styled.Wrapper>
      <LeftPanelHeader backButton onBackClick={handleBackClick} />

      <NewScrollbarV2 height={`calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`} autoHide>
        <Styled.FiltersContainer>
          <SorterSection />
          <FieldCultureFilterSection />
          <FieldNameFilterSection />
          <FieldAreaFilterSection />
          <FieldInExpFilterSection />
        </Styled.FiltersContainer>
      </NewScrollbarV2>

      <FiltersFooter />
    </Styled.Wrapper>
  );
};

export default observer(Filters);
