import { toDouble } from '../../../mobx/stores/TaskStore/Task.store';
import { CultureZone } from '../../../../../../../api/models/field.model';

const useTaskFieldName = (cultureZone: CultureZone) => {
  const labelData = [cultureZone.fieldName, cultureZone.name, `${toDouble(cultureZone.area)} га`];

  return labelData.filter(Boolean).join(', ');
};

export default useTaskFieldName;
