import {
  ENotificationType,
  ModalFooter,
  TModalButton,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { ChangeEvent, useState } from 'react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoController, StoStore } from '../../../../mobx';
import {
  ENotificationScheme,
  getNotificationScheme,
} from '../../../../../../../../../shared/utils/helpers/getNotificationScheme';
import { STO_NOTIFICATION_MESSAGES } from '../../../../../../constants';

import Styled from './StoPublishModalFrom.styles';

const ERROR_TEXT = {
  tooLong: 'Максимальная длинна комментария 512 символов',
};

const StoPublishModalFrom = () => {
  const controller = useStore(StoController);

  const [isError, setIsError] = useState(false);
  const [comment, setComment] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const { getModalRuntimeHandlers, closeModal } = useModal();
  const { onCancel, onSuccess } = getModalRuntimeHandlers();
  const { setNotification } = useNotificator();

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setIsError(false);
    setComment(event.target.value.toString());
  };

  const isCommentValid = comment?.length > 0 && comment?.length <= 512;

  const onPublishSto = () => {
    setIsProcessing(true);

    controller
      .publishSto(comment?.trim?.() ?? null)
      .then(() => {
        setNotification(
          getNotificationScheme(
            ENotificationScheme.Dark,
            STO_NOTIFICATION_MESSAGES.publishStoSuccess,
            {
              type: ENotificationType.Success,
            }
          )
        );
      })
      .catch(() =>
        setNotification(
          getNotificationScheme(
            ENotificationScheme.Dark,
            STO_NOTIFICATION_MESSAGES.publishStoError,
            {
              type: ENotificationType.Warning,
            }
          )
        )
      )
      .finally(() => {
        setIsProcessing(false);
        closeModal();
      });
  };

  const successButtonConfig: TModalButton = {
    title: 'Опубликовать',
    handler: () => {
      onSuccess?.();
      onPublishSto();
    },
    disabled: !isCommentValid || isProcessing,
  };

  const denyButtonConfig: TModalButton = {
    title: 'Не публиковать',
    handler: () => {
      onCancel?.();
      closeModal();
    },
    disabled: isProcessing,
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>Комментарий</Styled.Title>
      <Styled.CommentLabel>Добавьте комментарий к публикуемой версии</Styled.CommentLabel>
      <Styled.Delimiter />
      <Styled.CommentWrapper $isError={isError}>
        <textarea
          value={comment}
          onChange={handleChange}
          rows={4}
          maxLength={512}
          placeholder={'Введите текст'}
          data-test-id={'publish-comment-textarea'}
        />
      </Styled.CommentWrapper>
      {isError ? <Styled.Error>{ERROR_TEXT}</Styled.Error> : null}

      <Styled.ButtonsWrapper>
        <ModalFooter
          dataTestId="publish-comment"
          successButton={successButtonConfig}
          denyButton={denyButtonConfig}
        />
        <Styled.TypographyButton onClick={onPublishSto} disabled={isProcessing}>
          Опубликовать без комментария
        </Styled.TypographyButton>
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
};

export default StoPublishModalFrom;
