import { NewScrollbar } from '@farmlink/farmik-ui';
import React, { FC, PropsWithChildren, useRef, useState } from 'react';

import { useDataTestIdV2 } from '../../features/utils/hooks/locators';

interface IContainerScrollbarOptions {
  trackMarginTop?: number;
  trackMarginBottom?: number;
  scrollbarRightPosition?: number;
  scrollbarPaddingOffset?: number;
  isAutoResize?: boolean;
  withVirtualized?: boolean;
}

const ScrollableContainer: FC<
  PropsWithChildren<{
    hoverDuration?: number;
    maxHeight?: string;
    isDisabled?: boolean;
    dataTestId?: string;
    scrollbarOptions?: IContainerScrollbarOptions;
  }>
> = ({
  hoverDuration = 500,
  dataTestId,
  maxHeight = 'auto',
  isDisabled,
  children,
  scrollbarOptions,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovering(true);
    }, hoverDuration);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    setIsHovering(false);
  };

  /**
   * Эта логика используется для отрабатывания кейса обновления страницы с курсором на элементе
   * В таком кейсе mouseEnter не отрабатывает, поэтому прокручиваем сразу.
   */
  const handleMouseWheel = () => {
    if (!hoverTimeoutRef.current && !isHovering) {
      setIsHovering(true);
    }
  };

  const isDisabledScrollbar = isDisabled || !isHovering;
  const getDataTestId = useDataTestIdV2(`${dataTestId}__scrollable-container`);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onWheel={handleMouseWheel}
      className={`scrollable-container ${!isDisabledScrollbar ? 'scrollable' : ''}`}
      style={{ maxHeight, height: maxHeight }}
      data-is-scrollable={!isDisabledScrollbar}
      {...getDataTestId()}
    >
      <NewScrollbar
        scrollbarRightPosition={0}
        scrollbarPaddingOffset={4}
        isDisabled={isDisabledScrollbar}
        dataTestId={getDataTestId()['data-test-id']}
        {...scrollbarOptions}
      >
        {children}
      </NewScrollbar>
    </div>
  );
};

export default ScrollableContainer;
