import styled from 'styled-components';
import { Colors, ESpaceSize } from '@farmlink/farmik-ui';

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  '& .react-loading-skeleton': {
    backgroundColor: Colors.technicalBgGrey,
    backgroundImage: 'none',
    borderRadius: 12,
  },
});

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const IconsWrapper = styled('div')({
  display: 'flex',
  gap: ESpaceSize.S,

  '& .react-loading-skeleton': {
    backgroundColor: Colors.technicalBgGrey,
    backgroundImage: 'none',
    borderRadius: 6,
  },
});

const Styles = {
  Wrapper,
  TextWrapper,
  IconsWrapper,
};

export default Styles;
