import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId,
} from '../../../models/checklist/instance/checklist.instance.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TGetChecklistInstanceListReq = {
  taskId: string;
  size?: number;
  types?: ECheckListInstanceType[];
  isActive?: boolean;
};

type TGetChecklistInstanceListRes = { content: IGetChecklistInstanceByTaskId[] };

const getChecklistInstanceList: TypeApiRoute & {
  request: TGetChecklistInstanceListReq;
  response: TGetChecklistInstanceListRes;
} = {
  url: ({ taskId }) => `/api/as-fields/checklist/instances/byTaskId/${taskId}`,
  method: 'GET',
  headers: {},
  request: {} as TGetChecklistInstanceListReq,
  response: {} as TGetChecklistInstanceListRes,
};

export type { TGetChecklistInstanceListReq, TGetChecklistInstanceListRes };

export { getChecklistInstanceList };
