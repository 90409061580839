import { FC } from 'react';
import { observer } from 'mobx-react';

import { TablePlugBuilder } from '../../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';
import { OperationsFiltersActionButton as ActionButton } from '../../../../../containers/listing/components/OperationsFiltersActionButton';

const OperationsTaskCreatePlug: FC = () => {
  const getDataTestId = useDataTestIdV2('operations__task-create-plug');

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'file' },
        content: {
          title: 'Создайте новую задачу',
          description: 'Планируйте работы и следите за ходом их выполнения',
        },
        footer: {
          renderButton: () => <ActionButton width={'240px'} placement={'top'} />,
        },
      }}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

OperationsTaskCreatePlug.displayName = 'OperationsTaskCreatePlug';

export default observer(OperationsTaskCreatePlug);
