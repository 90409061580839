import {
  Colors,
  DropdownButton,
  ENotificationType,
  ENotificatorTheme,
  Icon,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { FC } from 'react';

import { CultureModel } from '../../../../../../../../../../../../api/models/culture.model';
import { EFieldAccessAction } from '../../../../../../../../../../../shared/constants/access-rules-action';
import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { useFieldsPermissions } from '../../../../../../../../hooks';
import { MultiselectStore } from '../../../../../../mobx';
import FieldsMultiselectController from '../../../../../../mobx/controllers/FieldsMultiselect.controller';
import { EFieldsMultiselectModalName } from '../../../../../../modals/config';
import Styled from '../../Footer.styles';

interface IProps {
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const ActionButtons: FC<IProps> = props => {
  // Сторы
  const store = useStore(MultiselectStore);

  // Контроллеры
  const controller = useStore(FieldsMultiselectController);

  const modal = useModal();
  const notificator = useNotificator();
  const permissions = useFieldsPermissions();

  const { selectedFieldsCount } = store;
  const fullAccess =
    permissions.hasAccess(EFieldAccessAction.EditCultureZone) &&
    permissions.hasAccess(EFieldAccessAction.DeleteField);

  const assignCultures = () => {
    const modalId = EFieldsMultiselectModalName.assignCulture;
    const payload = { selectedFieldsCount };

    modal.openModalByModalId(modalId, payload, (selectedCulture: CultureModel) => {
      controller.changeCulturesLocally(selectedCulture);

      notificator.setNotification({
        message: `Культура: ${selectedCulture.name} назначена на поля: ${selectedFieldsCount}`,
        style: getNotificatorStyle(ENotificationType.Success),
      });
    });
  };

  const deleteFields = () => {
    const modalId = EFieldsMultiselectModalName.deleteField;
    const payload = { selectedCount: selectedFieldsCount };

    modal.openModalByModalId(modalId, payload, fromAllSeasons => {
      controller.deleteFieldsLocally(fromAllSeasons);

      notificator.setNotification({
        message: `Удалены поля: ${selectedFieldsCount}`,
        style: getNotificatorStyle(ENotificationType.Warning),
      });
    });
  };

  if (fullAccess) {
    return (
      <Styled.DropdownButtonWrapper>
        <DropdownButton
          title={'Действия'}
          multiActionList={[
            {
              title: 'Назначить культуру',
              icon: <Icon icon={'plus'} size={'medium'} />,
              onClick: assignCultures,
            },
            {
              title: 'Удалить',
              icon: <Icon icon={'delete'} size={'medium'} fill={Colors.red} />,
              textColor: Colors.red,
              onClick: deleteFields,
            },
          ]}
          dataTestId={props.getDataTestId('button')['data-test-id']}
        />
      </Styled.DropdownButtonWrapper>
    );
  }

  if (permissions.hasAccess(EFieldAccessAction.EditCultureZone)) {
    return (
      <Styled.CustomButton color={'primary'} onClick={assignCultures}>
        Добавить культуру
      </Styled.CustomButton>
    );
  }

  if (permissions.hasAccess(EFieldAccessAction.DeleteField)) {
    return (
      <Styled.CustomButton color={'secondary'} onClick={deleteFields}>
        Удалить
      </Styled.CustomButton>
    );
  }

  return null;
};

const getNotificatorStyle = (type: ENotificationType): any => ({
  type,
  theme: ENotificatorTheme.Dark,
  placement: 'bottom-left',
});

export default ActionButtons;
