import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown as Dropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import {
  IChecklistsFormulaResult as IFormulaResult,
  TChecklistsDictionaryAttrToDraw as TDictionaryAttrToDraw,
} from '../../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { ChecklistsStore } from '../../../../mobx/stores';
import {
  ChecklistInstancesStore,
  EChecklistMode,
} from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { FullscreenContext } from '../../../../../../containers/fullscreen';
import { IDependencyRecordForRequest } from '../../../../../../../../../../api/endpoints/da-dictionary/dictionary/getDictionaryEntityList';
import { useTasksParams } from '../../../../../../hooks';

interface IProps {
  attrToDraw: TDictionaryAttrToDraw;
}

const ChecklistsDictionaryAttr: FC<IProps> = ({ attrToDraw }) => {
  const { id, groupId, initialModel, value, isBlocked, dependentFileAttrId, options } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__dictionary-attribute');

  const bodyForceCloseOptions = useContext(FullscreenContext);
  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);
  const placeholder = useAttrPlaceholder(initialModel.attribute);
  const params = useTasksParams();

  const isDisplayParent = initialModel?.displayParent;

  const hasNativeAutocompleteSupport = initialModel?.dependency?.autoComplete;

  const hasDependency = useMemo(() => {
    return Boolean(initialModel?.dependency?.dependencies?.length);
  }, []);

  const optionWithParentList = useMemo(() => {
    return checklistsController.getListOfSelectedDictionaryOptionWithParent(groupId, id);
  }, [options.selectedSelectOptionList]);

  // Отвечает за получение списка опций, когда нет зависимостей.
  useEffect(() => {
    if (hasDependency) return;

    checklistsController.fetchDictionaryList(groupId, id, {
      latestVersion: true,
      remoteName: initialModel.attribute.dictionaryLink,
      fetchAttributes: true,
      originalOnly: true,
      taskId: params.taskId,
    });
  }, []);

  const dependencyValue = checklistsController.getDictionaryDependency(
    groupId,
    id
  ) as IFormulaResult<IDependencyRecordForRequest[]>;

  // Отвечает за получение списка опций, когда есть зависимости.
  useEffect(() => {
    if (!hasDependency || !dependencyValue?.value) return;
    if (checklistsStore.mode !== EChecklistMode.Edit) return;

    const hasDepVal = Boolean((dependencyValue.value as IDependencyRecordForRequest[]).length);
    const isNotEditedDepValue = dependencyValue.isNotEdited;
    const instanceHasAlreadyBeenSaved = Boolean(
      checklistInstancesStore.selectedInstance?.isComplete
    );

    // Чтобы не было пустых срабатываний.
    if (!hasDepVal && isNotEditedDepValue) return;

    if (!hasDepVal) {
      checklistsController.changeAttrValue(
        EAttrType.DictionaryLink,
        groupId,
        {
          ...value,
          dictionaryValueList: [],
        },
        {
          isWithoutChangeLogging: true,
        }
      );

      checklistsController.addSelectedSelectOptionList(EAttrType.DictionaryLink, groupId, id, []);
      checklistsController.addSelectOptionList(EAttrType.DictionaryLink, groupId, id, []);

      return;
    }

    const checkIfIsAllowAutocomplete = (): boolean => {
      if (!hasNativeAutocompleteSupport) return false;
      if (!instanceHasAlreadyBeenSaved) return true;
      if (isNotEditedDepValue) return false;
    };

    checklistsController.fetchDictionaryList(
      groupId,
      id,
      {
        latestVersion: true,
        remoteName: initialModel.attribute.dictionaryLink,
        dependencyRecords: dependencyValue.value as IDependencyRecordForRequest[],
        fetchAttributes: true,
        originalOnly: true,
        taskId: params.taskId,
      },
      {
        isNeedToRemoveSelectedValue: !isNotEditedDepValue,
        isAllowAutocomplete: checkIfIsAllowAutocomplete(),
      }
    );
  }, [JSON.stringify(dependencyValue)]);

  const handleChange = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (newValue, otherData) => {
      if (initialModel.attribute?.isMultiselect) {
        checklistsController.changeAttrValue(EAttrType.DictionaryLink, groupId, {
          ...value,
          dictionaryValueList: !otherData?.selectedValueList
            ? []
            : otherData.selectedValueList.map(o => o.value),
        });

        checklistsController.addSelectedSelectOptionList(
          EAttrType.DictionaryLink,
          groupId,
          id,
          !otherData?.selectedValueList ? [] : otherData.selectedValueList,
          { isDisplayParent, remoteName: initialModel.attribute.dictionaryLink }
        );

        return;
      }

      checklistsController.changeAttrValue(EAttrType.DictionaryLink, groupId, {
        ...value,
        dictionaryValueList: !newValue ? [] : [newValue],
      });

      checklistsController.addSelectedSelectOptionList(
        EAttrType.DictionaryLink,
        groupId,
        id,
        !newValue ? [] : [otherData.option],
        { isDisplayParent, remoteName: initialModel.attribute.dictionaryLink }
      );
    },
    [options.selectOptionList]
  );

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      isRequired: initialModel.isRequired,
      defaultValueList: optionWithParentList,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isFullTextSearch: true,
          },
        },
        multiselect: initialModel.attribute?.isMultiselect ? {} : null,
      },
      placeholder,
    },
    body: {
      optionList: options.selectOptionList,
    },
    visual: {
      label: initialModel.attribute?.name,
      isBlocked,
      tooltip: initialModel.toolTip,
    },
    validation: {
      error: {
        errorList,
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: getDataTestId('dropdown')['data-test-id'],
    },
  };

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <Dropdown bodyForceCloseOptions={bodyForceCloseOptions} config={dropdownConfig} />

          <CSSContainer
            display={'flex'}
            justifyContent={'space-between'}
            dataTestId={getDataTestId('additional-info')['data-test-id']}
          >
            <CSSContainer dataTestId={getDataTestId('error-wrapper')['data-test-id']}>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <FileAttrContainer
              groupId={groupId}
              attrId={dependentFileAttrId}
              isNeedAdjustableContainer
            />
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsDictionaryAttr.displayName = 'ChecklistsDictionaryAttr';

export default observer(ChecklistsDictionaryAttr);
