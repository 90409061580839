import styled, { keyframes } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const CounterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.secondaryDarkBGGrey};
  background-color: ${Colors.technicalBgGrey};
  border-radius: 8px;
  padding: 3px 6px;
  margin: 0 4px;
`;

const StyledFiltersEntityCounter = {
  CounterContent,
};

export default StyledFiltersEntityCounter;
