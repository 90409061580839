import { observer } from 'mobx-react';
import { FC } from 'react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { TableBuilderStore as Store } from '../../../../../../../shared/features/TableBuilder/mobx/stores';
import { FiltersEntityCounter } from '../../../../../../../shared/features/UI/new/TasksCounter';
import { FiltersEntityCounterModes } from '../../../../../../../shared/features/UI/new/TasksCounter/FiltersEntityCounter';

interface IProps {
  dataTestId: string;
}

const TasksFiltersEntityCounter: FC<IProps> = ({ dataTestId }) => {
  const store = useStore(Store);

  const totalCount = store.getTotalEntities('tasks');
  const currentCount = store.getNumberOfEntities('tasks');

  if (!totalCount) return null;

  return (
    <FiltersEntityCounter
      mode={FiltersEntityCounterModes.Tasks}
      dataTestId={dataTestId}
      count={currentCount}
      totalCount={totalCount}
    />
  );
};

export default observer(TasksFiltersEntityCounter);
