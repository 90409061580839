import { FC, useEffect, useMemo, useState } from 'react';
import { Scrollbar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { ITask } from '../../../../../../../../api/models/as-fields/task/task.model';
import { getTaskStatusData } from '../../../../../operations/components/TaskView/utils/getTaskStatusData';
import useTaskFieldName from '../../hooks/useTaskFieldName';
import { useTasksParams } from '../../../../hooks';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ProfileStore } from '../../../../../profile/stores/ProfileStore';
import useTaskAssigneeName from '../../hooks/useTaskAssigneeName';
import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId as IInstance,
} from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { TaskController } from '../../../../mobx/controllers';

import {
  TasksDrawerCultureName as CultureName,
  TasksDrawerFieldName as FieldName,
  TasksDrawerStatusBlock as StatusBlock,
  TasksDrawerTaskAssignee as TaskAssignee,
  TasksDrawerTaskExecutedDate as ExecutedDate,
  TasksDrawerTaskOperationType as OperationType,
  TasksDrawerTaskType as TaskType,
  TasksDrawerViewingPoints as ViewingPoints,
} from './../../common/components';
import { StyledTasksDrawerTask as Styled } from './../../common/styles';

interface IProps {
  task: ITask;
  instanceList: IInstance[];
  dataTestId: string;
}

const TasksDrawerTaskOperation: FC<IProps> = ({ task, instanceList, dataTestId }) => {
  const profileStore = useStore(ProfileStore);
  const taskController = useStore(TaskController);
  const params = useTasksParams();

  const getDataTestId = useDataTestIdV2(`${dataTestId}__task-operation`);

  const [operationTypeLabel, setOperationTypeLabel] = useState('');

  useEffect(() => {
    (async () => {
      const dictionaryValueId = task.operationInfo.operationTypeInfo.id;
      const taskOperationConfig = await taskController.fetchTaskOperationConfig(dictionaryValueId);

      if (!taskOperationConfig) return;

      setOperationTypeLabel(taskOperationConfig.descriptionUi);
    })();
  }, [task.operationValues[0]?.dictionaryValueId]);

  const isOperationTypeShown = task?.operationValues?.length && operationTypeLabel;

  const taskStatusData = useMemo(() => {
    return getTaskStatusData(task);
  }, [task]);

  const fieldName = useTaskFieldName(task?.cultureZone);

  const assigneeName = useTaskAssigneeName(
    task.assignee?.fullName,
    profileStore.fullName,
    params.orgId
  );

  return (
    <Scrollbar>
      <Styled.TopBlock {...getDataTestId('top-block')}>
        {Boolean(taskStatusData) && (
          <StatusBlock
            task={task}
            boldText={taskStatusData.boldText}
            normalText={taskStatusData.normalText}
            dataTestId={getDataTestId()['data-test-id']}
          />
        )}

        <Styled.TaskBody {...getDataTestId('task-body')}>
          <TaskType taskType={task?.type} dataTestId={getDataTestId()['data-test-id']} />

          {isOperationTypeShown ? (
            <OperationType
              label={operationTypeLabel}
              values={task?.operationValues}
              dataTestId={getDataTestId()['data-test-id']}
            />
          ) : null}

          <CultureName
            taskCultureName={task?.culture?.name}
            dataTestId={getDataTestId()['data-test-id']}
          />

          <FieldName fieldName={fieldName} dataTestId={getDataTestId()['data-test-id']} />

          {task?.executedDate && (
            <ExecutedDate
              taskExecutedDate={task?.executedDate}
              dataTestId={getDataTestId()['data-test-id']}
            />
          )}

          <TaskAssignee assigneeName={assigneeName} dataTestId={getDataTestId()['data-test-id']} />

          {Boolean(instanceList.length) && (
            <ViewingPoints
              checklistInstanceList={instanceList}
              dataTestId={getDataTestId()['data-test-id']}
            />
          )}
        </Styled.TaskBody>
      </Styled.TopBlock>
    </Scrollbar>
  );
};

TasksDrawerTaskOperation.displayName = 'TasksTaskOperation';
export default observer(TasksDrawerTaskOperation);
