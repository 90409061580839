import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Item = styled.div<{ $isLast?: boolean }>`
  width: 100%;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  gap: ${ESpaceSize.M}px;
  padding-top: ${ESpaceSize.M}px;
  padding-bottom: ${ESpaceSize.M}px;
  border-bottom: 1px solid ${({ $isLast }) => ($isLast ? 'transparent' : Colors.borderGray)};
`;

const FieldNameWrapper = styled.div`
  transform: translateY(2px);
`;

const Styled = {
  Item,
  FieldNameWrapper,
};

export default Styled;
