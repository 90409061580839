import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { TableFiltersBuilder } from '../../../../../shared/features/TableFiltersBuilder';
import { TasksController } from '../../controllers/tasks.controller';
import { OperationsStore } from '../../../operations/stores/operations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { TableFiltersBuilderController as FiltersBuilderController } from '../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import { ISelectOption } from '../../../../../../types/selectOption';
import { TasksListController } from '../TasksList/mobx/controllers';
import { useTasksParams } from '../../hooks';
import NO_CULTURE_SELECT_OPTION from '../../../../../shared/utils/constants/selectOptions/NoCultureSelectOption/NoCultureSelectOption';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';
import { EContextualLinkId } from '../../../../constants/configs';
import { useContextualLink } from '../../../../../shared/features/ContextualLink/hooks';
import { TTasksContextualLinkConfig } from '../../types';
import { TableBuilderController } from '../../../../../shared/features/TableBuilder/mobx/controllers';
import { ETableFiltersBuilderId } from '../../../../constants/configs/TableFiltersBuilderId';

import { TasksFiltersController } from './mobx/controllers';

type TLinkState = TTasksContextualLinkConfig['from']['state'];

const TasksFilters: FC = () => {
  const operationsStore = useStore(OperationsStore);
  const seasonsStore = useStore(SeasonsStore);
  const checkAccessStore = useStore(CheckAccessStore);
  const profileStore = useStore(ProfileStore);

  const tasksController = useStore(TasksController);
  const filtersController = useStore(TasksFiltersController);
  const tableFiltersBuilderController = useStore(FiltersBuilderController);
  const tableBuilderController = useStore(TableBuilderController);
  const tasksListController = useStore(TasksListController);

  const params = useTasksParams();
  const contextualLinkActions = useContextualLink();

  const [assigneeOptionList, setAssigneeOptionList] = useState<ISelectOption[]>([]);

  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.Tasks,
    ContextualPaths.TasksHeaderTitle
  );

  const hasWorkWithTasksPermission = checkAccessStore.getAccessRuleValue(
    SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS
  );

  const hasManageTasksPermission = checkAccessStore.getAccessRuleValue(
    SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS
  );

  const appliedFilters =
    tableFiltersBuilderController.getAppliedFilters(ETableFiltersBuilderId.Tasks) || {};

  useEffect(() => {
    (async () => {
      const createdOptionList = await filtersController.createAssigneeSelectOptionList(
        params.orgId
      );

      setAssigneeOptionList(createdOptionList);
    })();
  }, [params.orgId]);

  useEffect(() => {
    filtersController.initiateFilters(ContextualHelpIcon);

    filtersController.addStatusSelectOptionList();
    filtersController.addTypesSelectOptionList();
  }, []);

  useEffect(() => {
    if (!appliedFilters || isEmpty(appliedFilters)) {
      return;
    }

    const link = contextualLinkActions.getLink(EContextualLinkId.TasksTaskBackButton);

    if (!(link?.from?.state as TLinkState)?.taskId) {
      tableBuilderController.removeRowsHighlight('tasks');
      tasksListController.fetchTaskList(appliedFilters);
      return;
    }

    (async () => {
      const linkState = link.from.state as TLinkState;

      tableBuilderController.highlightRow('tasks', linkState.taskId);
      contextualLinkActions.removeLink(EContextualLinkId.TasksTaskBackButton);

      const requestList = Array(linkState.page + 1).fill(null);

      let count = 0;

      for await (const _r of requestList) {
        ++count;

        await tasksListController.fetchTaskList(appliedFilters, true);

        if (count === requestList.length) return;

        tableBuilderController.increaseCurrentPage('tasks');
      }
    })();
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    const cultureSelectOptionList = operationsStore.fieldsWithoutCulturesCount
      ? [NO_CULTURE_SELECT_OPTION, ...tasksController.culturesList]
      : tasksController.culturesList;

    tableFiltersBuilderController.addSelectOptionList(
      ETableFiltersBuilderId.Tasks,
      'cultureId',
      cultureSelectOptionList
    );
  }, [operationsStore.OperationCulturesInFields, operationsStore.fieldsWithoutCulturesCount]);

  useEffect(() => {
    tableFiltersBuilderController.addSelectOptionList(
      ETableFiltersBuilderId.Tasks,
      'fieldId',
      tasksController.fieldsList
    );
  }, [tasksController.fieldsList]);

  useEffect(() => {
    tableFiltersBuilderController.addSelectOptionList(
      ETableFiltersBuilderId.Tasks,
      'operationTypeId',
      tasksController.operationsTypesForFilter
    );
  }, [tasksController.operationsTypesForFilter]);

  useEffect(() => {
    const noAssigneeOption = { label: 'Не назначено', value: 'noAssignee' };

    if (hasManageTasksPermission) {
      const formattedOptionList: ISelectOption[] = [noAssigneeOption, ...assigneeOptionList];

      tableFiltersBuilderController.addSelectOptionList(
        ETableFiltersBuilderId.Tasks,
        'assigneeId',
        formattedOptionList
      );

      return;
    }

    if (hasWorkWithTasksPermission) {
      const formattedOptionList: ISelectOption[] = [
        noAssigneeOption,
        { label: profileStore.fullName, value: profileStore.user.id },
      ];

      tableFiltersBuilderController.addSelectOptionList(
        ETableFiltersBuilderId.Tasks,
        'assigneeId',
        formattedOptionList
      );
    }
  }, [assigneeOptionList, hasManageTasksPermission, hasWorkWithTasksPermission]);

  return <TableFiltersBuilder builderId={ETableFiltersBuilderId.Tasks} />;
};

TasksFilters.displayName = 'TasksFilters';

export default observer(TasksFilters);
