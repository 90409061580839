import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { TableBuilder } from '../../../../../shared/features/TableBuilder';
import { useStore } from '../../../../../shared/utils/IoC';
import { ITask } from '../../../../../../api/models/as-fields/task/task.model';
import { TasksDrawer } from '../TasksDrawer';
import { TableBuilderController } from '../../../../../shared/features/TableBuilder/mobx/controllers';
import { ITableBuilderRowConfig } from '../../../../../shared/features/TableBuilder/models/configs';
import { ETasksTableBuilderId } from '../../utils/constants';

import { TasksListController } from './mobx/controllers';
import {
  TasksListNoDataPlug as NoDataPlug,
  TaskListNoSeasonPlug,
  TaskListNoCreatedTasksPlug as NoCreatedTasksPlug,
} from './components/plugs';

const TasksList: FC = () => {
  const tasksListController = useStore(TasksListController);
  const tableBuilderController = useStore(TableBuilderController);

  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);

  const hasAppliedFilters = tasksListController.checkIfHasAppliedFilters();

  const handleDrawerClose = useCallback(() => {
    tableBuilderController.highlightRow('tasks', selectedTask.id);
    setSelectedTask(null);
  }, [selectedTask]);

  const handleRowClick = useCallback<ITableBuilderRowConfig<ITask>['onRowClick']>((row, event) => {
    event.stopPropagation();

    setSelectedTask(row);
  }, []);

  useEffect(() => {
    tasksListController.initiateTable(handleRowClick, () => {
      tableBuilderController.addPlugConfig(
        ETasksTableBuilderId.Tasks,
        'noSeason',
        <TaskListNoSeasonPlug />
      );
    });
  }, []);

  return (
    <>
      <TableBuilder
        builderId={ETasksTableBuilderId.Tasks}
        renderNoDataPlug={() => <>{hasAppliedFilters ? <NoDataPlug /> : <NoCreatedTasksPlug />}</>}
        showMoveTop
      />

      {selectedTask ? (
        <TasksDrawer fromModule={'tasks'} task={selectedTask} onClose={handleDrawerClose} />
      ) : null}

      <Outlet />
    </>
  );
};

TasksList.displayName = 'TasksList';

export default observer(TasksList);
